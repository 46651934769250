import React,{useState}  from 'react';
import {useNavigate} from "react-router-dom";
import axios from 'axios';
import NetworkInfoUpload from './NetworkInfoUpload';
import PreferredClassesSubjects from './PreferredClassesSubjects';
import TeacherBankDetails from './TeacherBankDetails';
import TeacherBasicInfo from './TeacherBasicInfo';
import TeacherQualification from './TeacherQualification';
const api = axios.create({
  //baseURL: '/userinfo'
  baseURL: process.env.REACT_APP_API_URL,
})

const TeacherRegister = () => {
    const [userinfoData,setuserinfoData] = new useState({
      FirstName: "",
      LastName: "",
      Email: "",
      Gender: "",
      Mobile: "",
      DOB:"",
      Country: "",
      State: "",
      City: "",
      ZipCode: "",
      Aadhar: "",
      PANNO: "",
      EmailConfirmed: false,
      AccountConfirmed : false,



      Code: "",
      Password:"",
      ConfPassword:"",
      Appronal: false,
      Date: new Date(),
      Status: true,
      TermsandConditions:false,
      Designation:"Teacher",
  });
  // const [countrymaster, setCountrymaster] = useState([]);
  const inputEvents = (event) =>{
    // const name = event.target.name;
    // const value = event.target.value;
    const {name,value} = event.target;
    //alert(value);
    setuserinfoData({...userinfoData, [name]:value});
   
   
}

const navigate = useNavigate();
const onSubmits = (event) =>{
    console.log(userinfoData)
    event.preventDefault();
      api.post('/userinfo',  userinfoData )
      .then(res =>{ 
          if(res.status == '201')
          navigate('/teacherragistrationList');
          else
          alert(res.status)
     })
     .catch((err) => {
        console.log(err.response);
        alert(err.response.status);
      });
    }
  //   useEffect( async () => {
    
  //     api.get('/countrymaster')
  //     .then(res => {
  //         const countries = res.data;
  //         setCountrymaster(countries);
  //         // console.log(countries)
  //         }) 
  // }, [])
    
  return (
    <>
        <div className="teacher_container">
            <div className="container-fluid">
            <form className="row g-3 needs-validation" novalidate  onSubmit={onSubmits}>
               <TeacherBasicInfo inputEvents={inputEvents} userinfoData={userinfoData} />
               <TeacherQualification  label1="Qualification" label2="University/Institution" label3="Year of Passing" label4="Grade/percentage"  />
               <PreferredClassesSubjects />
               <TeacherBankDetails/>
               <NetworkInfoUpload/>
               <div className="col-12">
                  <button className="btn btn-primary pull-right">Submit </button>
              </div>
            </form>
               
              <br/>
              <br/>
            </div>
        </div> 
    </>
  )
}

export default TeacherRegister
