import React from "react";
import { Link } from "react-router-dom";
import { AuthContext } from '../../context/context';
import '../../css/student/headerstyle.css'


const StudentHeader = () => {
    const { signOut,getusername,getuserrole } = React.useContext(AuthContext);  
    let url = '';

   
  return (
    <>
        <div className="student_dashboard_header_container">
              <div id="header" className="navbar navbar-default">
                  <div style={{width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                  <div className="menu-icon">
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                  </div>
                  <div className="logo">
                      <Link className="navbar-brand" to="/">
                         <img src="../assets/20.png" style={{height:"35px",width:"65px"}} alt="logo1" />
                      </Link>
                  </div>
                    <nav className="ipad_hide">
                        <ul className="nav navbar-nav navbar-left" style={{marginTop: "14px"}}>
                         

                            <li className="padding-right-0 padding-left-0">
                                <Link to="/" className="padding_navbar">
                                    <div className="r4_counter  wid-stats db_box">
                                        <div className="stats-data">
                                        <div className="stats nav1">
                                                <h3>Tog offered programs</h3>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </li>

                            <li className="padding-right-0 padding-left-0">
                                <Link to="/" className="padding_navbar">
                                    <div className="r4_counter wid-stats wid-stats_bg1 db_box wid-satus_violet" style={{background:'#428bca'}}>
                                        <div className="stats-data">
                                            <div className="stats nav5">
                                                <h3>Quiz</h3>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </li>

                            <li className="padding-right-0 padding-left-0 dropdown">
                                <a className="padding_navbar dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true" href="javascript:;">
                                    <div className="r4_counter wid-stats wid-stats_bg1 db_box wid-stats_red">
                                        <div className="stats-data">
                                            <div className="stats nav3">
                                                <h3>Enrolled Courses <i className="fa fa-angle-down pull-right" style={{marginTop:'0px;'}}></i></h3>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-default dropdown_student_sets">
                                    <li><Link to="/Student/EnrolledCourse" className="nav4_4"><i className="fa fa-fw fa-hand-o-right text-red"></i> Active Courses</Link></li>
                                    <li><Link to="/" className="nav4_4"><i className="fa fa-fw fa-hand-o-right text-red"></i> Course Schedule</Link></li>
                                </ul>
                        </li>
                         
                        <li className="padding-right-0 padding-left-0 dropdown">
  							<a className="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true" href="javascript:;">
                            <div className="r4_counter wid-stats wid-stats_bg2 db_box">
                                <div className="stats-data">
                                    <div className="stats nav4">
                                        <h3>Class Detail<i className="fa fa-angle-down pull-right" style={{marginTop:'0px;'}}></i></h3>
                                    </div>
                                </div>
                            </div>
  							</a>
                            <ul class="dropdown-menu dropdown-menu-default dropdown_student_sets">
                               <li><Link to="/Student/UpcomingClasses" className="nav4_1"><i className="icon-home"></i> Upcoming Sessions</Link></li>
                               <li><Link to="/Student/CompleateClasses" className="nav4_2"><i className="fa fa-edit"></i> Session Completed</Link></li>
                            </ul>
                        </li>

                        <li className="padding-right-0 padding-left-0">
  							<Link to="/" className="padding_navbar">
                            <div className="r4_counter wid-stats wid-stats_bg1 db_box wid-satus_violet" style={{background:'#428bca'}}>
                                <div className="stats-data">
                                    <div className="stats nav5">
                                        <h3>Account Status</h3>
                                    </div>
                                </div>
                            </div>
  							</Link>
                        </li>

                        <li className="padding-right-0 padding-left-0">
        					<Link to="/" className="padding_navbar">
                            <div className="r4_counter wid-stats wid-stats_bg db_box">
                                <div className="stats-data">
                                    <div className="stats nav2">
                                        <h3>Payment</h3>
                                    </div>
                                </div>
                            </div>
        					</Link>
                        </li>

                        <li className="padding-right-0 padding-left-0 ">
                            <div className="r4_counter wid-stats wid-stats_bg1 db_box wid-satus_violet " style={{background:'#428bca'}}>
                                <div className="stats-data" style={{cursor:'pointer;'}}>
                                    <Link to="/"><h3> Assignment </h3></Link>
                                </div>
                            </div>
                        </li>

                    </ul>
                    </nav>
                    <div className="tog_login_profile_sidebar">
                      <ul className="nav navbar-nav navbar-right header-drop-right">
                        <li className="dropdown dropdown-user">
                      <Link to="/" className="dropdown-toggle padding_mobs" data-toggle="dropdown" data-hover="dropdown"
                        data-close-others="true">
                        <img src="/assets/easy-admin/images/user-img.png"
                          className="img-circle" style={{width:"40px", height:'40px'}} alt="profile1" />
                        <span className="username username-hide-on-mobile">
                        {getusername()}
                          <small className="mb-5" style={{color:"#ffa500"}}>
                            <i className="fa fa-fw fa-calendar-times-o"></i>
                            <span className="city_get_1_2"></span>
                          </small>
                        </span>
                        {/* <i className="fa fa-angle-down"></i> */}
                      </Link>
                      <ul className="dropdown-menu dropdown-menu-default profile_uesr_1">
                        <li className="user-header">
                          <img src="/assets/easy-admin/images/user-img.png"
                            className="float-left rounded-circle" style={{width:"100px", height:"100px"}} alt="profileimg" />
                          <p>
                            TOGS00129 <small className="mb-5">Aaditya.kataria.rocks@gmail.com</small>
                            <small className="mb-5" style={{color:"#ffa500"}}><i
                                className="fa fa-fw fa-calendar-times-o"></i>Asia/Calcutta</small>

                          </p>
                        </li>
                        <li><Link to="/Student" className="home_1"><i
                              className="fa fa-home"></i> Home</Link></li>
                        <li><Link to="/Student/complete_profile"
                            className="edit_profile_1"><i className="fa fa-edit"></i> Edit profile</Link></li>
                        <li><Link to="/Student/profile"
                            className="view_profile_1"><i className="fa fa-fw fa-street-view"></i> View profile</Link></li>
                        <li><Link to="/Student/transaction"><i
                              className="fa fa-money"></i> Transaction</Link></li>
                        <li><Link to="/Student/program/request-status"><i
                              className="fa fa-quora"></i> Query &amp; Information</Link></li>
                        <li><Link to="/" data-target="#pwdModal"
                            data-toggle="modal" className="change_pass_1"><i className="fa fa-fw fa-exchange"></i> Change
                            Password</Link></li>
                        <li><Link to="/assets/uploads/app/decontog-appSetup_1.0.0.exe"
                            title="Tog App" style={{fontSize:"13px"}}><i className="fa fa-cloud-download "
                              style={{color:"blue"}}></i> Tog App</Link></li>
                        <li><Link to={url} onClick={signOut} className="logout_1"><i
                              className="fa fa-sign-out"></i> Log out</Link></li>
                      </ul>

                    </li>

                    </ul>

                    </div>
                  </div>
              </div>
          </div>

                {/* <div className="container-fluid" style={{backgroundColor:"gray", height:"540px"}}>

                </div> */}
     
    </>
  )
}

export default StudentHeader