import React from "react";
import { Link } from "react-router-dom";
import ShowTable from "../../components/admin/Table";

const CategoryMasterList = () => {
return(
    <>
     <div style={{textAlign:"right"}}><Link to="/categorymaster"> Add Category </Link></div>
    <ShowTable flag='category' Linkbtn={[{btnText:"",className:"fa fa-fw fa-edit text-green",flag:""}]} api='CategoryMaster' />
    </>
)
}

export default CategoryMasterList;

// const styles = StyleSheet.create({
//     container: {
//       flex: 1, 
//       alignItems: 'center', 
//       justifyContent: 'center'
//     },
   
//   });