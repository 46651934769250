import React,{useState,useEffect} from "react";
import {Link,useNavigate,useParams} from "react-router-dom";
import Moment from 'moment';
//  import {Link,useNavigate} from "react-router-dom";
import axios from 'axios';
import "./css_files/category_style.css"

const api = axios.create({
    // baseURL: '/ConfirmScheduleClass'
    baseURL: process.env.REACT_APP_API_URL,
})


const ConfirmScheduleClass = () => {
    const [data, setdata] = React.useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [CourseScheduleData, setCourseScheduleData] = React.useState([]);
    const [datetime, setdatetime] = React.useState(new Date());
    const [timee, settime] = React.useState("-");
useEffect( async () => {
   const date = Moment(datetime).format('MM-DD-YYYY');
    getCourseClassConfirm(date,"-")
}, [])

const getCourseClassConfirm =(date,time) => {
    //alert(time)
    api.get('/CourseClassConfirm/'+date+'/'+time).then(res =>
        {
            setIsLoading(false)
            setdata(res.data)
          // console.log(res.data)
    });
}
const courseScheduleEvents = (event,i) =>{
    debugger
    if(event.target.checked===true){
        const {name,value} = event.target;
        var val=value.split(',');
       // var valind=parseInt(val[9]);
//     course,Class,Subject,Faculty,ClassDate,ClassStart,ClassEnd,Day,Duration
   var data={
            CourseId:val[0],
            ClassId:val[1],
            SubjectId:val[2],
            FacultyId:val[3],
            ClassDate:val[4],
            ClassStart:val[5],
            ClassEnd:val[6],
            Day:val[7],
            Duration:val[8],
            Class:val[10],
            Subject:val[11],
            ClassDatee:val[12],
            FacultyEmail:val[13],
   }
//    console.log(data);
//    let markers = [ ...CourseScheduleData ];
// markers[0] = {...markers[0], CourseId: course};
setCourseScheduleData([{ ...data }]);

//    var arr=[];
//    arr.push(data);
//    setCourseScheduleData[0]([{...data}])
// setCourseScheduleData([{...CourseScheduleData, data}]);
//    if(CourseId)
    // setCourseScheduleData({...CourseScheduleData, CourseId:CourseId});

    // setCourseScheduleData();
    }
    else{
        debugger
        const {name,value} = event.target;
        // var myArr = [{id:'a'},{id:'myid'},{id:'c'}];
        var index = CourseScheduleData.findIndex(function(o){
             return o.CourseId === value.split(',')[0];
        })
        if (index !== -1) CourseScheduleData.splice(index, 1);
        
        }
    }
  const navigate = useNavigate();

    const createData = (topic,agenda,start_time,duration,alternative_hosts,index) => {
         // alert(start_time) ,alternative_hosts:"malikshahzad84@gmail.com", approval_type:0
        api.post(`/createmeeting/wkdCipAPQFiHatwY1LLi2w`,{"topic":topic,"agenda":agenda,"start_time":start_time,"duration":duration,"settings": {alternative_hosts:"pardeep.barak@deconglobalsolutions.com","alternative_hosts_email_notification": true,"alternative_host_update_polls": true, approval_type:0} }).then(res =>
                {
                    debugger
                    // console.log(alternative_hosts)
                    // console.log(res.data.join_url)
                             CourseScheduleData[index].meetingid=res.data.id;
                             CourseScheduleData[index].start_time=res.data.start_time;
                             CourseScheduleData[index].start_url=res.data.start_url;
                             CourseScheduleData[index].join_url=res.data.join_url;
                             api.post('/ConfirmScheduleClass',  CourseScheduleData)
                                .then(res =>{ 
                                    if(res.status=='201'){
                                    navigate('/ConfirmScheduleClassList');
                                    }
                                    else
                                    alert(res.status)
                                })
                                .catch((err) => {
                                    console.log(err.response);
                                    alert(err.response.status);
                                });
                //     api.get(`/meeting/${res.data.id}`).then(res =>
                //     {
                //         debugger
                //          console.log(res.data.id)
                //          console.log(res.data.start_url)
                //          console.log(res.data.join_url)
                //          CourseScheduleData[index].meetingid=res.data.id;
                //          CourseScheduleData[index].start_time=res.data.start_time;
                //          CourseScheduleData[index].start_url=res.data.start_url;
                //          CourseScheduleData[index].join_url=res.data.join_url;
                //          console.log(CourseScheduleData)
                       
                // });
            });
         
        }
        // Date.prototype.addHours = function(hours) {
        //     this.setHours(this.getHours() + hours);
        //     return this;
        //   };
  const onSubmits = (event) =>{
    event.preventDefault();
    // console.log(CourseScheduleData);
    CourseScheduleData.map((item,index) => {
    //  console.log({Class:item.Class,Subject:item.Subject,ClassDate:item.ClassDate,ClassStart:item.ClassStart,Duration:item.Duration})
    
    const d = new Date(item.ClassDatee);
    
    //alert(d.getFullYear()+'-'+(d.getMonth()+1)+'-'+d.getDate()+'T'+item.ClassStart)
   // const d = new Date();
//     var   d2 = new Date ( d.getFullYear()+'-'+(d.getMonth()+1)+'-'+d.getDate()+'T'+item.ClassStart);
//     var d3 = new Date (d2.setMinutes(d2.getMinutes()-330))
//    // var d3 = new Date (d2.setHoseturs(d2.getHours()-5))
//    alert(d3)
        createData(item.Class, item.Subject, d.getFullYear()+'-'+(d.getMonth()+1)+'-'+d.getDate()+'T'+item.ClassStart+':00', item.Duration,item.FacultyEmail,index);
    })

     
    // api.post('/ConfirmScheduleClass',  CourseScheduleData )
    //       .then(res =>{ 
    //           if(res.status=='201'){
    //            navigate('/ConfirmScheduleClassList');
    //           //alert(res.data);
    //         //   getCourseClassConfirm('04-21-2022');
    //           }
    //           else
    //           alert(res.status)
    //      })
    //      .catch((err) => {
    //         console.log(err.response);
    //         alert(err.response.status);
    //       });

  }

const EndTime = (StartTime,Duration) =>{
    const d = new Date("2015-03-25 "+StartTime);
    var   d2 = new Date ( d );
    d2.setMinutes ( d.getMinutes()+ parseInt(Duration));
    return Moment(d2.getTime()).format('hh:mm A') ;
}

const inputEvents = (event,index) =>{
      
    const {name,value} = event.target;
    setdatetime(value);
   const date = Moment(value).format('MM-DD-YYYY');
   
  // getCourseClassConfirm(date, time)
}
const onSearch = () =>{
   // alert(timee.toString())
   getCourseClassConfirm(datetime,timee.toString());
}
const onChanges = (value,index) => {
   // CourseClassData[index].StartTime=value.currentTarget.value;
    settime(value.currentTarget.value)
    //alert(value.currentTarget.value)
   // const date = Moment(value).format('MM-DD-YYYY');
 
   // getCourseClassConfirm(date, value.currentTarget.value);
 }
  return (
    <>
 
     <div className="container cat-display" style={{height:"545px","max-width": "1230px"}}>
        <div className="col-md-12 cat-main" >
            <div className="row"  style={{"max-width": "1230px","marginLeft":"5px","marginTop":"5px"}}>
            <div className="col-sm-12 col-md-2 col-lg-2">Class Date Time:</div>
            <div className="col-sm-12 col-md-2 col-lg-2"> <input type="date" name="StartDate"  onChange={inputEvents} value={Moment(datetime).format('YYYY-MM-DD')}  placeholder="Enter Class Date"/></div>
            <div className="col-sm-12 col-md-2 col-lg-2"><input defaultValue={timee.toString() || "-"} type="time" InputLabelProps={{shrink: true,}} onChange={(e,i) => onChanges(e,i)} /></div>
            <div className="col-sm-12 col-md-2 col-lg-2"><input type="submit" style={{"marginTop":"0px","margin-bottom":"0px"}} onClick={onSearch} value="Search..." className="btn"/></div>
        </div>
            <form control="" className="customformGroup customformGroupcourse" onSubmit={onSubmits}>
                <div className="tableContainer" style={{height:"440px"}}>
                    <div id="tableContainer2" className="container-tb" style={{height:"435px"}}>
                        <table  id="myTable">
                               <thead>
                                     <th>Sr.</th>  
                                     <th>Course</th>  
                                     <th>Class</th> 
                                     <th>Subject</th> 
                                     <th>Faculty</th> 
                                     <th>Class Date</th>  
                                     <th>Class Start</th> 
                                     <th>Class End</th> 
                                     <th>Day</th>   
                                     <th>Duration (Min)</th>                     
                                     <th>Action</th> 
                               </thead>
                             
                              <tbody>
                              {isLoading ? (
                                  <h1>Loading...</h1>
                              ) : (
                                                           
                                 data.map((item,index) => (
                                   
                                    <tr key={item.id+index}>
                                       
                                        <td> </td>
                                        <td>{item.Code}</td>
                                        <td>{item.Class.Class}</td>
                                        <td>{item.Subject.Subject}</td>
                                        <td>{item.Faculty.FulName}</td>
                                        <td>{item.ClassDate}</td>
                                        <td>{EndTime((timee!=='-') ? timee : item.CourseClass[item.day].StartTime,0)}</td>
                                        <td>{EndTime((timee!=='-') ? timee : item.CourseClass[item.day].StartTime, item.CourseClass[item.day].Duration)}</td>
                                        <td>{item.CourseClass[item.day].Day}</td>
                                        <td>{item.CourseClass[item.day].Duration}</td>
                                        <td>
                                        {/* <input type="hidden" name="class" value={item.Class._id}/>
                                        <input type="hidden" name="subject" value={item.Subject._id}/>
                                        <input type="hidden" name="faculty" value={item.Faculty._id}/>
                                        <input type="hidden" name="ClassDate" value={item.ClassDate} />
                                        <input type="hidden" name="StartTime" value={item.CourseClass[item.day].StartTime} />
                                        <input type="hidden" name="EndTime" value={EndTime(item.CourseClass[item.day].StartTime, item.CourseClass[item.day].Duration)} />
                                        <input type="hidden" name="Day" value={item.CourseClass[item.day].Day} />
                                        <input type="hidden" name="Duration" value={item.CourseClass[item.day].Duration} /> */}
                                        {/* <input type="checkbox" value={item._id} onChange={courseScheduleEvents(item._id,item.Class._id,item.Subject._id,item.Faculty._id,item.ClassDate,item.CourseClass[item.day].StartTime,EndTime(item.CourseClass[item.day].StartTime, item.CourseClass[item.day].Duration),item.CourseClass[item.day].Day,item.CourseClass[item.day].Duration)}/> */}
                                        <input type="checkbox" value={item._id+','+item.Class._id+','+item.Subject._id+','+item.Faculty._id+','+item.ClassDate+','+item.CourseClass[item.day].StartTime+','+Moment(item.CourseClass[item.day].StartTime, 'HH:mm:ss').add(item.CourseClass[item.day].Duration,'minutes').format('HH:mm')+','+item.CourseClass[item.day].Day+','+item.CourseClass[item.day].Duration +','+index.toString()+','+item.Class.Class+','+item.Subject.Subject+','+item.ClassDatee+','+item.Faculty.Email} onChange={courseScheduleEvents}/>
                                        </td>
                                    </tr>
                                    ))
                          ) }
                              </tbody>
                              
                          </table>
                      </div>
                  </div>
                  <div className="row"> 
                            <div className="col-sm-12 col-md-6 col-lg-6 pull-right">
                                <Link to="/"  className="btn pull-right"> Go Back </Link>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 pull-right">
                                <input type="submit" value="Submit" className="btn"/>
                            </div>
                        </div>
                  </form>
          </div>
   </div>

    </>
  );
}

export default ConfirmScheduleClass;
