import React,{useEffect} from "react";

 import { NavLink} from "react-router-dom";
import '../admin/css_files/sidebar_style.css';
// import { Tooltip } from "bootstrap";
import * as $ from 'jquery';
import { AuthContext } from '../../context/context';
// import ReactDOM from 'react-dom';
// import AdminHeader from "./AdminHeader";



const AdminSidebar = () => {
    const { getuserrole } = React.useContext(AuthContext);
   useEffect(() => {
       //alert()
    Dashboard.init();
   }, []);
    return (
      <>
      
      {/* <AdminHeader /> */}
         {/* <Link to="/">Home</Link>
                    <Link to="/category">Category</Link> */}
            <div className="l-sidebar" id="addSupModal">
                <div className="logo">
                <div className="logo__txt">Tog <span id="adminlogo"> Admin</span> </div>
                </div>
                <div className="l-sidebar__content">
                {/* <a href ="/">Home</a><br/>
                    <a href ="/category">Category</a> */}
               
                    {/* <NavLink exact to="/">Home</NavLink><br/>
                    <NavLink to="/category">Category</NavLink> */}
              
                <nav className="c-menu js-menu">
                    <ul className="u-list">
                    <li className="c-menu__item is-active" data-toggle="tooltip" title="Flights">
                    <NavLink to="/">
                            <div className="c-menu__item__inner"><i className="fa fa-plane"></i>
                                <div className="c-menu-item__title"><span>Home</span></div>
                            </div>
                    </NavLink>
                    </li>
                    {(getuserrole()=='Admin')?
                    <>
                    <li className="c-menu__item has-submenu" data-toggle="tooltip" title="Modules">
                        <div className="c-menu__item__inner"><i className="fa fa-puzzle-piece"></i>
                        <div className="c-menu-item__title"><span>Master</span></div>
                        <div className="c-menu-item__expand js-expand-submenu"><i className="fa fa-angle-down"></i></div>
                        </div>
                        <ul className="c-menu__submenu u-list">
                        <NavLink to="/rolemasterList"><li><i className="fa fa-hand-point-right" ></i> Role Master</li></NavLink>
                        <NavLink to="/countrymasterList"><li><i className="fa fa-hand-point-right" ></i> Country Master</li></NavLink>
                        <NavLink to="/statemasterList"><li><i className="fa fa-hand-point-right" ></i> State Master</li></NavLink>
                        <NavLink to="/citymasterList"><li><i className="fa fa-hand-point-right" ></i> City Master</li></NavLink>
                        <NavLink to="/categorymasterList"><li><i className="fa fa-hand-point-right" ></i> Category Master</li></NavLink>
                        <NavLink to="/boardmasterList"> <li><i className="fa fa-hand-point-right"></i> Board Master</li></NavLink>
                        <NavLink to="/classmasterList"><li><i className="fa fa-hand-point-right"></i> Class Master</li></NavLink>
                        <NavLink to="/subjectmasterList"><li><i className="fa fa-hand-point-right"></i> Subject Master</li></NavLink>
                        <NavLink to="/chaptermasterList"><li><i className="fa fa-hand-point-right"></i> Chapter Master</li></NavLink>
                        <NavLink to="/facultymasterList"><li><i className="fa fa-hand-point-right"></i> Faculty Master</li></NavLink>
                        <NavLink to="/studentragistrationList"><li><i className="fa fa-hand-point-right"></i> Student Ragistration</li></NavLink>
                        <NavLink to="/adminragistrationList"><li><i className="fa fa-hand-point-right"></i> Admin Ragistration</li></NavLink>
                        <NavLink to="/teacherregisterList"><li><i className="fa fa-hand-point-right"></i> Teacher Ragistration</li></NavLink>
                        <NavLink to="/DDaysList"><li><i className="fa fa-hand-point-right"></i>D-Days</li></NavLink>
                        <NavLink to="/BlogList"><li><i className="fa fa-hand-point-right"></i>Blog</li></NavLink>
                        <NavLink to="/DemoRequestList"><li><i className="fa fa-hand-point-right"></i>Demo Request List</li></NavLink>
                        <NavLink to="/ContactUsList"><li><i className="fa fa-hand-point-right"></i>Contact Us</li></NavLink>
                        </ul>
                    </li>
                    <li className="c-menu__item has-submenu" data-toggle="tooltip" title="Modules">
                        <div className="c-menu__item__inner"><i className="fa fa-puzzle-piece"></i>
                        <div className="c-menu-item__title"><span>Course Details</span></div>
                        <div className="c-menu-item__expand js-expand-submenu"><i className="fa fa-angle-down"></i></div>
                        </div>
                        <ul className="c-menu__submenu u-list">
                        <NavLink to="/courseList"><li><i className="fa fa-hand-point-right" ></i>Courses</li></NavLink>
                        <NavLink to="/ConfirmScheduleClass"><li><i className="fa fa-hand-point-right" ></i>Confirm Schedule Class</li></NavLink>
                        <NavLink to="/ConfirmScheduleClassList"><li><i className="fa fa-hand-point-right" ></i>Confirm Class List</li></NavLink>
                        </ul>
                    </li>


                    <li className="c-menu__item has-submenu" data-toggle="tooltip" title="Modules">
                        <div className="c-menu__item__inner"><i className="fa fa-puzzle-piece"></i>
                        <div className="c-menu-item__title"><span>Teacher</span></div>
                        <div className="c-menu-item__expand js-expand-submenu"><i className="fa fa-angle-down"></i></div>
                        </div>
                        <ul className="c-menu__submenu u-list">
                        <NavLink to="/TeachingSubjectList"><li><i className="fa fa-hand-point-right" ></i>Teaching Subject</li></NavLink>
                        <NavLink to="/EnrolledCourse"><li><i className="fa fa-hand-point-right" ></i>Complete & Ongoing Course</li></NavLink>
                        <NavLink to="/UpcomingClasses"><li><i className="fa fa-hand-point-right" ></i>Upcoming Sessions</li></NavLink>
                        <NavLink to="/CompleateClasses"><li><i className="fa fa-hand-point-right" ></i>Sessions Completed</li></NavLink>
                        </ul>
                    </li>
                    

                    <li className="c-menu__item has-submenu" data-toggle="tooltip" title="Modules">
                        <div className="c-menu__item__inner"><i className="fa fa-puzzle-piece"></i>
                        <div className="c-menu-item__title"><span>Student</span></div>
                        <div className="c-menu-item__expand js-expand-submenu"><i className="fa fa-angle-down"></i></div>
                        </div>
                        <ul className="c-menu__submenu u-list">
                        <NavLink to="/SEnrolledCourse"><li><i className="fa fa-hand-point-right" ></i>Active Courses</li></NavLink>
                        <NavLink to="/SUpcomingClasses"><li><i className="fa fa-hand-point-right" ></i>Upcoming Sessions</li></NavLink>
                        <NavLink to="/SCompleateClasses"><li><i className="fa fa-hand-point-right" ></i>Sessions Completed</li></NavLink>
                        </ul>
                    </li>
                       
                      

                    <li className="c-menu__item has-submenu" data-toggle="tooltip" title="Statistics">
                        <div className="c-menu__item__inner"><i class="fa fa-bar-chart" aria-hidden="true"></i>
                        <div className="c-menu-item__title"><span>Statistics</span></div>
                        </div>
                    </li>
                    {/* <li className="c-menu__item has-submenu" data-toggle="tooltip" title="Gifts">
                        <div className="c-menu__item__inner"><i className="fa fa-gift"></i>
                        <div className="c-menu-item__title"><span>Master</span></div>
                        </div>
                    </li> */}
                    <li className="c-menu__item has-submenu" data-toggle="tooltip" title="Settings">
                        <div className="c-menu__item__inner"><i className="fa fa-cogs"></i>
                        <div className="c-menu-item__title"><span>Settings</span></div>
                        </div>
                    </li>
                    </> :''  }
                    {(getuserrole()=='Teacher')?
                    <>
                     <li className="c-menu__item has-submenu" data-toggle="tooltip" title="Modules">
                        <div className="c-menu__item__inner"><i className="fa fa-puzzle-piece"></i>
                        <div className="c-menu-item__title"><span>Teacher</span></div>
                        <div className="c-menu-item__expand js-expand-submenu"><i className="fa fa-angle-down"></i></div>
                        </div>
                        <ul className="c-menu__submenu u-list">
                        <NavLink to="/TeachingSubjectList"><li><i className="fa fa-hand-point-right" ></i>Teaching Subject</li></NavLink>
                        <NavLink to="/EnrolledCourse"><li><i className="fa fa-hand-point-right" ></i>Complete & Ongoing Course</li></NavLink>
                        <NavLink to="/UpcomingClasses"><li><i className="fa fa-hand-point-right" ></i>Upcoming Sessions</li></NavLink>
                        <NavLink to="/CompleateClasses"><li><i className="fa fa-hand-point-right" ></i>Sessions Completed</li></NavLink>
                        </ul>
                    </li>
                    </>
                    :''
                     }
                      {(getuserrole()=='Student')?
                    <>
                     <li className="c-menu__item has-submenu" data-toggle="tooltip" title="Modules">
                        <div className="c-menu__item__inner"><i className="fa fa-puzzle-piece"></i>
                        <div className="c-menu-item__title"><span>Student</span></div>
                        <div className="c-menu-item__expand js-expand-submenu"><i className="fa fa-angle-down"></i></div>
                        </div>
                        <ul className="c-menu__submenu u-list">
                        <NavLink to="/SEnrolledCourse"><li><i className="fa fa-hand-point-right" ></i>Active Courses</li></NavLink>
                        <NavLink to="/SUpcomingClasses"><li><i className="fa fa-hand-point-right" ></i>Upcoming Sessions</li></NavLink>
                        <NavLink to="/SCompleateClasses"><li><i className="fa fa-hand-point-right" ></i>Sessions Completed</li></NavLink>
                        </ul>
                    </li>
                    </>
                    :''
                     }
                    </ul>
                </nav>
                </div>
            </div>
            
         </>
    );
}

let Dashboard = (() => {
	let global = {
		tooltipOptions: {
			placement: "right"
		},
		menuClass: ".c-menu"
	};

	let menuChangeActive = el => {
		let hasSubmenu = ($(el).hasClass("has-submenu"));
		$(global.menuClass + " .is-active").removeClass("is-active");
		$(el).addClass("is-active");
		
		if (hasSubmenu) {
			$(el).find("ul").toggle();
		}
	};

	let sidebarChangeWidth = () => {
		// let $menuItemsTitle = $("li .menu-item__title");

		$(".header-toggle-section").toggleClass("sidebar-is-reduced sidebar-is-expanded");
		$(".hamburger-toggle").toggleClass("is-opened");
		
		if ($(".header-toggle-section").hasClass("sidebar-is-expanded")) {
            setTimeout(function() {
                $('#adminlogo').show();
            }, 500);  
			// $('[data-toggle="tooltip"]').tooltip("destroy");
		} else {
            $('.has-submenu ul').hide();
            $('#adminlogo').hide();
			// $('[data-toggle="tooltip"]').tooltip(global.tooltipOptions);
		}
		
	};

	return {
        
            init: () => {
                
                //  document.addEventListener("DOMContentLoaded", function(event) {
                    
                    $(".js-hamburger").on("click", sidebarChangeWidth);
                   
                    $(".js-menu li").on("click", e => {
                        menuChangeActive(e.currentTarget);
                    });
                   
                        $('#adminlogo').hide();
                        // $('[data-toggle="tooltip"]').tooltip(global.tooltipOptions);
                    // }) 
            }
       
	};
})();

// Dashboard.init();


export default AdminSidebar;