import React,{useState,useEffect} from "react";
import {Link,useNavigate,useParams} from "react-router-dom";
import Moment from 'moment';
// import {Link,useNavigate} from "react-router-dom";
import axios from 'axios';
import "./css_files/category_style.css"

const api = axios.create({
    // baseURL: '/ConfirmScheduleClass'
    baseURL: process.env.REACT_APP_API_URL,
})

const ConfirmScheduleClassList =() => {
    const [data, setdata] = React.useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [CourseScheduleData, setCourseScheduleData] = React.useState([]);

useEffect( async () => {
    getCourseClassConfirm('05-15-2022')
}, [])

const getCourseClassConfirm =(date) => {
    api.get('/ConfirmScheduleClass').then(res =>
        {
            debugger
            setIsLoading(false)
            console.log(res.data)
            setdata(res.data)
          
    });
}

const courseScheduleEvents = (event,i) =>{
    debugger
    if(event.target.checked===true){
        const {name,value} = event.target;
        var val=value.split(',');
       // var valind=parseInt(val[9]);
//     course,Class,Subject,Faculty,ClassDate,ClassStart,ClassEnd,Day,Duration
   var data={
            CourseId:val[0],
            ClassId:val[1],
            SubjectId:val[2],
            FacultyId:val[3],
            ClassDate:val[4],
            ClassStart:val[5],
            ClassEnd:val[6],
            Day:val[7],
            Duration:val[8],
   }
//    console.log(data);
//    let markers = [ ...CourseScheduleData ];
// markers[0] = {...markers[0], CourseId: course};
setCourseScheduleData([{ ...data }]);

//    var arr=[];
//    arr.push(data);
//    setCourseScheduleData[0]([{...data}])
// setCourseScheduleData([{...CourseScheduleData, data}]);
//    if(CourseId)
    // setCourseScheduleData({...CourseScheduleData, CourseId:CourseId});

    // setCourseScheduleData();
    }
    else{
        debugger
        const {name,value} = event.target;
        // var myArr = [{id:'a'},{id:'myid'},{id:'c'}];
        var index = CourseScheduleData.findIndex(function(o){
             return o.CourseId === value.split(',')[0];
        })
        if (index !== -1) CourseScheduleData.splice(index, 1);
        
        }
    }

  const onSubmits = (event) =>{
    event.preventDefault();
    // console.log(CourseScheduleData);
    api.post('/ConfirmScheduleClass',  CourseScheduleData )
          .then(res =>{ 
              if(res.status=='201'){
            //   navigate('/chaptermasterList');
              alert(res.data);
              getCourseClassConfirm('05-15-2022');
              }
              else
              alert(res.status)
         })
         .catch((err) => {
            console.log(err.response);
            alert(err.response.status);
          });

  }

const EndTime = (StartTime,Duration) =>{
    const d = new Date("2015-03-25 "+StartTime);
    var   d2 = new Date ( d );
    d2.setMinutes ( d.getMinutes()+ parseInt(Duration));
    return Moment(d2.getTime()).format('hh:mm A') ;
}

  return (
    <>
     {/* <div className="container cat-display" style={{height:"545px","max-width": "1230px"}}>
                <div className="col-md-12 cat-main" >
                    <form control="" className="customformGroup customformGroupcourse" onSubmit={onSubmits}> */}
       <div className="tableContainer" style={{height:"474px"}}>
                       <div id="tableContainer2" className="container-tb" style={{height:"545px"}}>
                             <table  id="myTable">
                               <thead>
                                     <th>Sr.</th>  
                                     <th>Course</th>  
                                     <th>Class</th> 
                                     <th>Subject</th> 
                                     <th>Faculty</th> 
                                     <th>Class Date</th>  
                                     <th>Class Start</th> 
                                     <th>Class End</th> 
                                     <th>Day</th>   
                                     <th>Duration (Min)</th>                     
                                     <th>Action</th> 
                                     <th> Class Close</th> 	
                                     <th>Teacher Class Status</th> 
                                     <th>Teacher Remarks Topic</th> 
                                     <th>Teacher Remarks Description</th> 
                               </thead>
                             
                              <tbody>
                              {isLoading ? (
                                  <h1>Loading...</h1>
                              ) : (
                                                           
                                 data.map((item,index) => (
                                   
                                    <tr key={item._id+index}>
                                       
                                        <td> </td>
                                        <td>{item.CourseId.Code}</td>
                                        <td>{item.ClassId.Class}</td>
                                        <td>{item.SubjectId.Subject}</td>
                                        <td>{item.FacultyId.FulName}</td>
                                        <td>{item.ClassDate}</td>
                                        <td>{EndTime(item.ClassStart,0)}</td>
                                        <td>{EndTime(item.ClassEnd,0)}</td>
                                        <td>{item.Day}</td>
                                        <td>{item.Duration}</td>
                                        <td>
                                       
                                        {/* <input type="checkbox" value={item._id+','+item.Class._id+','+item.Subject._id+','+item.Faculty._id+','+item.ClassDate+','+item.CourseClass[item.day].StartTime+','+EndTime(item.CourseClass[item.day].StartTime,item.CourseClass[item.day].Duration)+','+item.CourseClass[item.day].Day+','+item.CourseClass[item.day].Duration +','+index.toString()} onChange={courseScheduleEvents}/> */}
                                        </td>
                                        <td></td>
                                        <td>{(item.Status==null || item.Status=="") ? <span style={{color:"red"}}>Pending</span> : ((item.Status===true) ? 'Done' : 'Cancel' )  }</td>
                                        <td>{item.teacherClsTopic}</td>
                                        <td>{item.teacherClsDescription}</td>
                                    </tr>
                                    ))
                          ) }
                              </tbody>
                              
                          </table>
                      </div>
                  </div>
                  {/* <div className="row"> 
                            <div className="col-sm-12 col-md-6 col-lg-6 pull-right">
                                <Link to="/"  className="btn pull-right"> Go Back </Link>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 pull-right">
                                <input type="submit" value="Submit" className="btn pull-right"/>
                            </div>
                        </div> 
                  </form>
          </div>
   </div>*/}

    </>
  );
}

export default ConfirmScheduleClassList;
