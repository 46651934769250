import React from 'react';
import ReactDOM from 'react-dom';
import { CKEditor } from 'ckeditor4-react';
import {Link,useNavigate} from "react-router-dom";
import axios from 'axios';
const { useState } = React;
const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})

const DDays = () => {
	const [DDaysData,setDDaysData] = new useState({
        Heading: "",
        Content: "",
        ImageUrl: "",
        ReleaseDate: new Date(),
        Date: new Date(),
        Status: true,
    });
	const [Content,setContent] = new useState('');

	const inputEvents = async (event) =>{
        debugger
	
		 	const {name,value} = event.target;

			if(name==='Image'){
			const file = event.target.files[0];
			const base64 = await convertToBase64(file);
			setDDaysData({...DDaysData, 'Image':base64});
			}
			else
			{
				
				setDDaysData({...DDaysData, [name]:value});
			}
		
    }
	const logEvent = ( evt ) => {
		const data = evt.editor.getData();
		//setDDaysData({...DDaysData, 'Content':data.toString()});
		setContent(data);
		// evt.timestamp = new Intl.DateTimeFormat( 'en', {
		// 	hour12: false,
		// 	hour: '2-digit',
		// 	minute: '2-digit',
		// 	second: '2-digit'
		// } ).format( new Date() );

		// setEvents( events =>  [ evt, ...events ] );
	}

	
	const convertToBase64 = (file) => {
		return new Promise((resolve, reject) => {
		  const fileReader = new FileReader();
		  fileReader.readAsDataURL(file);
		  fileReader.onload = () => {
			resolve(fileReader.result);
		  };
		  fileReader.onerror = (error) => {
			reject(error);
		  };
		});
	  };
	  const navigate = useNavigate();
    const onSubmits = (event) =>{
        event.preventDefault();
		debugger
    //    // var categorymaster = categoryData;
         // console.log(DDaysData);
		 // console.log(Content);

		  setDDaysData((d)=>{d.Content=Content})

		  console.log(DDaysData);

		 // setContent
          api.post('/DDays',  DDaysData )
          .then(res =>{ 
              if(res.status===201)
              {
                navigate('/DDaysList');  
              }
              alert(res.status)
         })
         .catch((err) => {
            console.log(err.response);
            alert(err.response.status);
          });
        
        
    
         // navigate('/categorymasterList');

    }

	const [ events, setEvents ] = useState( [] );



	const clearEvents = () => {
		setEvents( [] );
	}

	return (
		<>
			 <div className="container-fluid cat-display" style={{height:"518px"}}>
				
                <div className="col-md-11 cat-main" style={{padding:0}}>
                    <form control="" className="form-group customformGroup" onSubmit={onSubmits}>
					<div className="row" style={{padding:'12px'}}> 
                        <div className="col-sm-12 col-md-4">
                            <label htmlFor="Heading">D-Day Heading</label>
                            <input type="text" name="Heading" onChange={inputEvents}  className="form-control" placeholder="Enter Heading Here"/>
                        </div>
						<div className="col-sm-12 col-md-4">
                            <label htmlFor="Headingimage">D-Day image</label>
                            <input type="file" name="Image" onChange={inputEvents}  className="form-control" placeholder="Enter Heading image Here"/>
                        </div>
						<div className="col-sm-12 col-md-4">
                            <label htmlFor="relaseDate">D-Day Release Date</label>
                            <input type="date" name="ReleaseDate"  onChange={inputEvents} className="form-control" placeholder="Enter Release Date Here"/>
                        </div>
                       <div className="col-sm-12 col-md-12">
							<CKEditor
							name="content"
								initData="This is a CKEditor 4 WYSIWYG editor instance created by ️⚛️ React."
								config={{
									toolbar:'Full',
									
								}}
						
									onFocus={logEvent}
									onBlur={logEvent}
									onChange={logEvent}
									onSelectionChange={logEvent}
								/>
                            
					   </div>
					   <div className="row" > 
						<hr />
						</div>
                        <div className="" > 
						
                            <div className="col-sm-12 col-md-6 col-lg-6 pull-right">
                                <Link to="/boardmasterList"  className="btn pull-right"> Go Back </Link>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 pull-right">
                                <input type="submit" value="Submit" className="btn pull-right"/>
                            </div>
                        </div>
						</div>
                    </form>
                </div>
            </div>
			{/* <CKEditor
				initData="This is a CKEditor 4 WYSIWYG editor instance created by ️⚛️ React."
				config={{
					toolbar:'Full',
					// toolbar:[
					// {
					// 	name: 'insert',
					// 	items: ['Image', 'Table']
					//   },
					//   {
					// 	name: 'tools',
					// 	items: ['Maximize']
					//   },
					//   {
					// 	name: 'editing',
					// 	items: ['Scayt']
					//   }
					// ],
					// toolbar: [
					// 	{ name: 'document', items : [ 'Source','-','Save','NewPage','DocProps','Preview','Print','-','Templates' ] },
					// 	{ name: 'clipboard', items : [ 'Cut','Copy','Paste','PasteText','PasteFromWord','-','Undo','Redo' ] },
					// 	{ name: 'editing', items : [ 'Find','Replace','-','SelectAll','-','SpellChecker', 'Scayt' ] },
					// 	{ name: 'forms', items : [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 
					// 		'HiddenField' ] },
					// 	'/',
					// 	{ name: 'basicstyles', items : [ 'Bold','Italic','Underline','Strike','Subscript','Superscript','-','RemoveFormat' ] },
					// 	{ name: 'paragraph', items : [ 'NumberedList','BulletedList','-','Outdent','Indent','-','Blockquote','CreateDiv',
					// 	'-','JustifyLeft','JustifyCenter','JustifyRight','JustifyBlock','-','BidiLtr','BidiRtl' ] },
					// 	{ name: 'links', items : [ 'Link','Unlink','Anchor' ] },
					// 	{ name: 'insert', items : [ 'Image','Flash','Table','HorizontalRule','Smiley','SpecialChar','PageBreak','Iframe' ] },
					// 	'/',
					// 	{ name: 'styles', items : [ 'Styles','Format','Font','FontSize' ] },
					// 	{ name: 'colors', items : [ 'TextColor','BGColor' ] },
					// 	{ name: 'tools', items : [ 'Maximize', 'ShowBlocks','-','About' ] }

						
					// ],
					// extraPlugins: 'easyimage',
					// removePlugins: 'image',
					// cloudServices_uploadUrl: 'https://classes.theonlinegurukul.com/easyimage/',
					// cloudServices_tokenUrl:
					// 	'https://classes.theonlinegurukul.com/token/dev/ijrDsqFix838Gh3wGO3F77FSW94BwcLXprJ4APSp3XQ26xsUHTi0jcb1hoBt'
					//extraPlugins: 'uploadimage',
					//extraPlugins: 'image2',
					 // filebrowserBrowseUrl: '/apps/ckfinder/3.4.5/ckfinder.html',
					//  filebrowserImageBrowseUrl: '/apps/ckfinder/3.4.5/ckfinder.html?type=Images',
					// filebrowserUploadUrl: '/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files',
					// filebrowserImageUploadUrl: '/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Images',
			  
					// // Upload dropped or pasted images to the CKFinder connector (note that the response type is set to JSON).
					// uploadUrl: '/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json',
			  
					// // Reduce the list of block elements listed in the Format drop-down to the most commonly used.
					// format_tags: 'p;h1;h2;h3;pre',
					// // Simplify the Image and Link dialog windows. The "Advanced" tab is not needed in most cases.
					// removeDialogTabs: 'image:advanced;link:advanced',
			  
					// height: 450,
					// removeButtons: 'PasteFromWord'
					// height: 300,

					// // Configure your file manager integration. This example uses CKFinder 3 for PHP.
					// //filebrowserBrowseUrl: '/ckfinder/3.4.5/ckfinder.html',
					// filebrowserBrowseUrl: '/ckfinder/3.4.5/ckfinder.html',
					// filebrowserImageBrowseUrl: '../ckfinder/3.4.5/ckfinder.html?type=Images',
					// filebrowserUploadUrl: '/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files',
					// filebrowserImageUploadUrl: '/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Images',
					// removeButtons: 'PasteFromWord'
				}}
				
				onFocus={logEvent}
				onBlur={logEvent}
				onChange={logEvent}
				onSelectionChange={logEvent}
			/> */}
			{/* <h3>Events Log</h3>
			<small>To check additional details about every event, consult the console in the browser developer tools.</small>
			<EventLog stream={events} />
			<button onClick={clearEvents}>Clear events log</button> */}
		</>
	);
}

// const EventLog = ( { stream } ) => {
// 	return (
// 		<div className="event-log">
// 			<ul className="event-log__events">
// 				{
// 					stream.map( event => {
// 						return (
// 							<li className="event-log__event">
// 								<Event data={event} />
// 							</li>
// 						)
// 					} )
// 				}
// 			</ul>
// 		</div>
// 	);
// }

// const Event = ( { data: { name, timestamp } } ) => {
// 	return (
// 		<>
// 			{timestamp} – {name}
// 		</>
// 	);
// }

// ReactDOM.render( <ConfigEvents />, document.getElementById( 'app' ) );
export default DDays;



// import React, { useState, useEffect } from "react";
// // import "./styles.css";
// // import Editor from "./Editor";

// import { CKEditor } from 'ckeditor4-react';

// const DDays = () => {
//   const [editorLoaded, setEditorLoaded] = useState(false);
//   const [data, setData] = useState("");
// //   const { useState } = React;

 
//       const [ events, setEvents ] = useState( [] );
  
//       const logEvent = ( evt ) => {
//           evt.timestamp = new Intl.DateTimeFormat( 'en', {
//               hour12: false,
//               hour: '2-digit',
//               minute: '2-digit',
//               second: '2-digit'
//           } ).format( new Date() );
  
//           setEvents( events =>  [ evt, ...events ] );
//       }
  
//       const clearEvents = () => {
//           setEvents( [] );
//       }
// //   useEffect(() => {
// //     setEditorLoaded(true);
// //   }, []);
// // export default class aa extends React.Component {
// //     render() {
//   return (
//     <div className="App">
//             <h2>Using CKEditor 4 in React</h2>
//             <CKEditor
//                 initData={<p>Hello from CKEditor 4!</p>}
//                 config={{
// 					toolbar: [
// 						[ 'Source' ],
// 						[ 'Styles', 'Format', 'Font', 'FontSize' ],
// 						[ 'Bold', 'Italic' ],
// 						[ 'Undo', 'Redo' ],
// 						[ 'EasyImageUpload' ],
// 						[ 'About' ]
// 					],
// 					extraPlugins: 'easyimage',
// 					removePlugins: 'image',
// 					cloudServices_uploadUrl: 'https://33333.cke-cs.com/easyimage/upload/',
// 					cloudServices_tokenUrl:
// 						'https://33333.cke-cs.com/token/dev/ijrDsqFix838Gh3wGO3F77FSW94BwcLXprJ4APSp3XQ26xsUHTi0jcb1hoBt'
                        
// 				}}
//                 onInstanceReady={ () => {
//                     alert( 'Editor is ready!' );
//                 } }
//                 onFocus={logEvent}
// 				onBlur={logEvent}
// 				onChange={logEvent}
// 				onSelectionChange={logEvent}
//             />
//         </div>
//   );
// }
// // }

// export default DDays;







// import React, { useState, useEffect } from "react";
// // import "./styles.css";
// // import Editor from "./Editor";

// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// const DDays = () => {
//   const [editorLoaded, setEditorLoaded] = useState(false);
//   const [data, setData] = useState("");

// //   useEffect(() => {
// //     setEditorLoaded(true);
// //   }, []);
// // export default class aa extends React.Component {
// //     render() {
//   return (
//     <div className="App">
//                 <h2>Using CKEditor 5 build in React</h2>
//                 <CKEditor
//                     editor={ ClassicEditor }
//                     config={{
//                         // simpleUpload: {
//                         //   uploadUrl: 'https://myserver.herokuapp.com/image-upload'
//                         // },
//                         // removePlugins: [ 'Heading', 'Link', 'CKFinder' ],
//                     //     toolbar:{
//                     //         items : ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'imageUpload', 'insertTable',
//                     //       'tableColumn', 'tableRow', 'mergeTableCells', 'mediaEmbed', '|', 'undo', 'redo' , '|',	
//                     //       'Styles'],
//                     //       shouldNotGroupWhenFull: true
//                     // }
//                     toolbar: {
//                         items: [
//                             'heading', '|',
//                             'alignment', '|',
//                             'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
//                             'link', '|',
//                             'bulletedList', 'numberedList', 'todoList',
//                             '-', // break point
//                             'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor', '|',
//                             'code', 'codeBlock', '|',
//                             'insertTable', '|',
//                             'outdent', 'indent', '|',
//                             'uploadImage', 'blockQuote', '|',
//                             'undo', 'redo'
//                         ],
//                         shouldNotGroupWhenFull: true,
//                         //shouldNotGroupWhenFull: true
//                     }
//                       }}
//                     // data="<p>Hello from CKEditor 5!</p>"
//                     onReady={ editor => {
//                         // You can store the "editor" and use when it is needed.
//                          console.log( 'Editor is ready to use!', editor );
//                     } }
//                     onChange={ ( event, editor ) => {
//                         const data = editor.getData();
//                         console.log( { event, editor, data } );
//                     } }
//                     onBlur={ ( event, editor ) => {
//                         console.log( 'Blur.', editor );
//                     } }
//                     onFocus={ ( event, editor ) => {
//                         console.log( 'Focus.', editor );
//                     } }
//                 />
//             </div>
//   );
// }
// // }

// export default DDays;