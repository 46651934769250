import React,{useState,useEffect} from "react";
import {Link,useParams} from "react-router-dom";
import { Buffer } from 'buffer';
import { AuthContext } from '../../context/context';
import axios from 'axios';
const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  })
 
const ZoomMeetingurl= () => {
    const [data, setdata] = React.useState([]);
    const [startdata, setstartdata] = React.useState({});
    const [isLoading, setIsLoading] = useState(true);
    const { getuserid } = React.useContext(AuthContext);
    const {id} = useParams();
    useEffect( async () => {
            var userid = getuserid();
            // alert(id)
            getstarturlData(id)
        }, [])
        
      
        
        const getstarturlData =(identifier) => {
            api.get(`/meeting/${identifier}`).then(res =>
                {
                    debugger
                    // console.log(res.data)
                    setstartdata(res.data)
                    setIsLoading(false)
            });
        }
        
        return (
                <>
                  
                   <div className="tableContainer" style={{height:"474px"}}>
                       <div id="tableContainer2" className="container-tb" style={{height:"545px"}}>
                      {/* {console.log(startdata.topic)} */}
                      <div style={{margin:"20px"}}>
                        
                            <p>Title : {startdata.topic}</p>
                             <p>Agenda : {startdata.agenda}</p>
                            <p>Date : {startdata.start_time}</p>
                            <p>Duration : {startdata.duration}</p>
                            <p>Time Zone : {startdata.timezone}</p>
                            <p>Start Url : <a href={startdata.start_url} target="_blank">{startdata.start_url}</a></p>
                            <p>Join Url : <a href={startdata.join_url} target="_blank">{startdata.join_url}</a></p> 
                            
                       
                            </div>
                       {/*<div style="margin: 20px;">
                          { isLoading ? (
                                  <h1>Loading...</h1>
                              ) : (
                                  <>
                            <p>Title : {startdata.topic}</p>
                             <p>Agenda : {startdata.agenda}</p>
                            <p>Date : {startdata.start_time}</p>
                            <p>Duration : {startdata.duration}</p>
                            <p>Time Zone : {startdata.timezone}</p>
                            <p>Start Url : <a href={startdata.start_url} target="_blank">{startdata.start_url}</a></p>
                            <p>Join Url : <a href={startdata.join_url} target="_blank">{startdata.join_url}</a></p> 
                            </>
                          )
                          
                        }
                            </div>*/}
                      
                      </div>
                  </div>
                     
                </>
      )
  }

export default ZoomMeetingurl;
