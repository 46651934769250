import React, { useState,useEffect } from 'react'
import  {SelectOption, Formtitle, InputList } from './Forms';
import Moment from 'moment';
import axios from 'axios';
const api = axios.create({
    // baseURL: '/CategoryMaster'
    baseURL: process.env.REACT_APP_API_URL,
})
const CourseStudent = (props) => {
     //console.log(props.CourseStudentData)
    var converter = require('number-to-words');
    //  const [formValues, setFormValues] = useState([{SName:"", SFeesType: "", SFees : "", TFeesType: "", TFees: "", Joindate:"", Leavedate:"", Remarks:"", CourseId:""}]);
    // const [CourseStudentCount, setCourseStudentCount] = useState(1);
    const [studentoptions, setstudentoptions] = useState([]);
    // const [CourseStudentData, setCourseStudentData] = useState([]);

    useEffect( async () => {
        try
        {
            api.get('/userinfo/Student').then((res) =>{
            debugger
           // setstudentoptions(res.data)
           const filterDataarry = []
           res.data.forEach(element => {
            filterDataarry.push({value:element._id,name:element.Name});
           });
           setstudentoptions(filterDataarry)
        });
    } catch(err) {
        console.log(err.message);
      }
     //console.log(props.CourseStudentData)
        // setCourseStudentData(props.CourseStudentData);
        
    }, [])

    // let handleChange = (i, e) => {
    //     let newFormValues = [...formValues];
    //     newFormValues[i][e.target.name] = e.target.value;
    //     setFormValues(newFormValues);
    //   }
    
    // let addFormFields = () => {
    //     // event.stopPropagation();
    //     if(CourseStudentCount <= 10){
    //          //setFormValues([...formValues, {SName:"", SFeesType: "", SFees : "", TFeesType: "", TFees: "", Joindate:"", Leavedate:"", Remarks:"", CourseId:""}])
    //         props.setCourseStudentData([...props.CourseStudentData, {SName:"", SFeesType: "", SFees : "", TFeesType: "", TFees: "", Joindate:"", Leavedate:"", Remarks:"", CourseId:""}])
    //         setCourseStudentCount(CourseStudentCount+1);
    //     }else{
    //         alert("You can not add more Qualification");
    //     }
    //   }
    
    // let removeFormFields = (i) => {
    //     let newFormValues = [...formValues];
    //     newFormValues.splice(i, 1);
    //     setFormValues(newFormValues)
    //     // i.stopPropagation();
    // }
    
    // let handleSubmit = (event) => {
    //     console.log(JSON.stringify(formValues))
    //     event.preventDefault();
    //     event.stopPropagation();
    // }

    return (
        // console.log('aa')
        // <form  onSubmit={handleSubmit}>
            <section className="qualification-container">
                <div className="stFormGroup">
                    <Formtitle padding="pt-0" title="Add Student in Course" clickEvent={() => props.addFormFields()} display="d-block" />
                    {/* {console.log(props.CourseStudentData)} */}
                    {props.CourseStudentData.map((element, index) => (
                        <div className="row" key={index.toString()}> 

                            <SelectOption classnames="col-md-2 col-lg-2 col-sm-2" name="SName" lable="SName" option={studentoptions} value={element.SName._id || null} changeEvent={e => props.coursestudentEvents(e,index)} selectValue={ converter.toWordsOrdinal(index+1)+' Student Name'} />
                           
                             <SelectOption classnames="col-md-1 col-lg-1 col-sm-1" name="SFeesType" lable="S Fees Type" option={[{value:'Hourly',name:'Hourly'},{value:'Monthly',name:'Monthly'},{value:'FullCourse',name:'Full Course'}]} value={element.SFeesType || ""} changeEvent={e => props.coursestudentEvents(e,index)} selectValue={' Fees Type'} />
                            
                            <InputList classnames="col-md-1 col-lg-1 col-sm-1" name="SFees" lable="SFees" value={element.SFees || ""} changeEvent={e => props.coursestudentEvents(e,index)} placeholder="Student Fees" />
                            
                            <SelectOption classnames="col-md-1 col-lg-1 col-sm-1" name="TFeesType" lable="T Fees Type" option={[{value:'Hourly',name:'Hourly'},{value:'Monthly',name:'Monthly'},{value:'FullCourse',name:'Full Course'}]} value={element.TFeesType || ""} changeEvent={e => props.coursestudentEvents(e,index)} selectValue={' Fees Type'} />

                            <InputList classnames="col-md-1 col-lg-1 col-sm-1" name="TFees" lable="TFees" value={element.TFees || ""} changeEvent={e => props.coursestudentEvents(e,index)} placeholder="Teacher Fees" />

                            <InputList classnames="col-md-2 col-lg-2 col-sm-2" inputtype="date" name="Joindate" lable="Join Date" value={(element.Joindate!='')?Moment(element.Joindate).format('YYYY-MM-DD') : ""} changeEvent={e => props.coursestudentEvents(e,index)} placeholder="Join Date" />

                            <InputList classnames="col-md-2 col-lg-2 col-sm-2" inputtype="date" name="Leavedate" lable="Leave Date" value={(element.Leavedate!='')?Moment(element.Leavedate).format('YYYY-MM-DD')  : ""} changeEvent={e => props.coursestudentEvents(e,index)} placeholder="Leave Date" />

                            <InputList classnames="col-md-1 col-lg-1 col-sm-1" name="Remarks" lable="Remarks" value={element.Remarks || ""} changeEvent={e => props.coursestudentEvents(e,index)} placeholder="Remarks" />


                            <div className="col-1 pull-right">
                                <p  style={{visibility :"hidden"}} >Acn</p>
                                { index ?  
                                    <button type="button"  className="button remove" onClick={() => props.removeFormFields(index)}><i className="fa fa-window-close text-red" > </i></button>
                                : <i className="fa fa-check text-green" > </i>
                                }
                            </div>
                        </div>
                    ))}
                </div>
           </section>
    //   </form>
    )
}

export default CourseStudent
