import React,{useState} from 'react';
import { CKEditor } from 'ckeditor4-react';
import {Link,useNavigate} from "react-router-dom";
import axios from 'axios';
// const { useState } = React;
const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})

function Blog() {
    const [BlogData,setBlogData] = new useState({
        Heading: "",
        CustomisedTitle:"",
        Content: "",
        Image: "",
        ReleaseDate: new Date(),
        UpdateDate: new Date(),
        Date: new Date(),
        Status: true,
    });
    const [Content,setContent] = new useState('');

	const inputEvents = async (event) =>{
        debugger
	
		 	const {name,value} = event.target;

			if(name==='Image'){
			const file = event.target.files[0];
			const base64 = await convertToBase64(file);
			setBlogData({...BlogData, 'Image':base64});
			}
			else
			{
				
				setBlogData({...BlogData, [name]:value});
			}
		
    }
	const logEvent = ( evt ) => {
		const data = evt.editor.getData();
		//setBlogData({...BlogData, 'Content':data.toString()});
		setContent(data);
		// evt.timestamp = new Intl.DateTimeFormat( 'en', {
		// 	hour12: false,
		// 	hour: '2-digit',
		// 	minute: '2-digit',
		// 	second: '2-digit'
		// } ).format( new Date() );

		// setEvents( events =>  [ evt, ...events ] );
	}

	
	const convertToBase64 = (file) => {
		return new Promise((resolve, reject) => {
		  const fileReader = new FileReader();
		  fileReader.readAsDataURL(file);
		  fileReader.onload = () => {
			resolve(fileReader.result);
		  };
		  fileReader.onerror = (error) => {
			reject(error);
		  };
		});
	  };
	  const navigate = useNavigate();
    const onSubmits = (event) =>{
        event.preventDefault();
		debugger
    //    // var categorymaster = categoryData;
         // console.log(BlogData);
		 // console.log(Content);

		  setBlogData((d)=>{d.Content=Content})

		  console.log(BlogData);

		 // setContent
          api.post('/Blog',  BlogData )
          .then(res =>{ 
              if(res.status===201)
              {
                navigate('/BlogList');  
              }
              alert(res.status)
         })
         .catch((err) => {
            console.log(err.response);
            alert(err.response.status);
          });
        
        
    
         // navigate('/categorymasterList');

    }
  
    const [ events, setEvents ] = useState( [] );

	
	const clearEvents = () => {
		setEvents( [] );
	}
  return (
    <>
      <div className="container-fluid cat-display" style={{height:"518px"}}>
                <div className="col-md-11 cat-main" >
                    <form control="" className="form-group customformGroup" onSubmit={onSubmits}>
                        <div className="row" style={{padding : '12px'}}>
                            <div className="col-sm-12 col-md-4">
                                <label htmlFor="Heading">Blog Heading</label>
                                <textarea name="Heading" onChange={inputEvents} className="form-control" style={{height: "33px"}}  placeholder="Enter Heading Here"></textarea>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <label htmlFor="CustomisedTitle">Customised Title</label>
                                <input type="text" name="CustomisedTitle" onChange={inputEvents}  className="form-control" placeholder="Enter Customised Title Here"/>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <label htmlFor="Headingimage">Blog image</label>
                                <input type="file" name="Image" onChange={inputEvents}  className="form-control" placeholder="Enter Heading image Here"/>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <label htmlFor="multipartimage">Choose Multiple image for banner</label>
                                <input type="file" name="multipartimage[]" multiple="multiple" class="form-control"  required=""/>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <label htmlFor="relaseDate">Blog Release Date</label>
                                <input type="date" name="ReleaseDate" onChange={inputEvents}  className="form-control" placeholder="Enter Release Date Here"/>
                            </div>
                        </div>
                        
                       <div className="col-sm-12 col-md-12" style={{padding:"12px 0"}}>
							<CKEditor
								initData="This is a CKEditor 4 WYSIWYG editor instance created by ️⚛️ React."
								config={{
									toolbar:'Full',
									
								}}
						
									onFocus={logEvent}
									onBlur={logEvent}
									onChange={logEvent}
									onSelectionChange={logEvent}
								/>
                            <hr />
					   </div>
                        <div className="row"> 
                            <div className="col-sm-12 col-md-6 col-lg-6 pull-right">
                                <Link to="/boardmasterList"  className="btn pull-right"> Go Back </Link>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 pull-right">
                                <input type="submit" value="Submit" className="btn pull-right"/>
                            </div>
                        </div>
                    </form>
                </div>
               
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
                <br />
    </>
  );
}

export default Blog;
