import React, { useState } from 'react'
import SelectOption, { Formtitle, InputList } from './Forms';

const TeacherQualification = (props) => {
    var converter = require('number-to-words');
    const [formValues, setFormValues] = useState([{ qualification: "", universityIns : "", yoPassing: "", percentage: ""}]);
    const [qualificationCount, setQualificationCount] = useState(1);

    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
      }
    
    let addFormFields = () => {
        // event.stopPropagation();
        if(qualificationCount <= 10){
            setFormValues([...formValues, { qualification: "", universityIns : "", yoPassing: "", percentage: ""}])
            setQualificationCount(qualificationCount+1);
        }else{
            alert("You can not add more Qualification");
        }
      }
    
    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
        // i.stopPropagation();
    }
    
    // let handleSubmit = (event) => {
    //     console.log(JSON.stringify(formValues))
    //     event.preventDefault();
    //     event.stopPropagation();
    // }

    return (
        // <form  onSubmit={handleSubmit}>
            <section className="qualification-container">
                <div className="stFormGroup">
                    <Formtitle title="QUALIFICATION DETAILS" clickEvent={() => addFormFields()} display="d-block" />
                    {formValues.map((element, index) => (
                        <div className="row" key={index}> 
                            <SelectOption classnames="col-md-3 col-lg-3 col-sm-6" name="qualification" lable={props.label1} option={['z','b','c','d','e','f','g']} value={element.qualification || ""} changeEvent={e => handleChange(index, e)} selectValue={converter.toWordsOrdinal(index+1)+' Qualification'} />
                            
                            <InputList classnames="col-md-3 col-lg-3 col-sm-6" name="universityIns" lable={props.label2} value={element.universityIns || ""} changeEvent={e => handleChange(index, e)} placeholder={props.label2} />
                            
                            <InputList classnames="col-md-3 col-lg-3 col-sm-6" name="yoPassing" lable={props.label3} value={element.yoPassing || ""} changeEvent={e => handleChange(index, e)} placeholder={props.label3} />
                            
                            <InputList classnames="col-md-2 col-lg-2 col-sm-5" name="percentage" lable={props.label4} value={element.percentage || ""} changeEvent={e => handleChange(index, e)} placeholder={props.label4} />
                            <div className="col-1 pull-right">
                                <p  style={{visibility :"hidden"}} >Acn</p>
                                { index ?  
                                    <button type="button"  className="button remove" onClick={() => removeFormFields(index)}><i className="fa fa-window-close text-red" > </i></button>
                                : <i className="fa fa-check text-green" > </i>
                                }
                            </div>
                        </div>
                    ))}
                </div>
           </section>
    //   </form>
    )
}

export default TeacherQualification
