import React,{useState,useEffect} from "react";
import {Link,useNavigate} from "react-router-dom";
import axios from 'axios';
import "./css_files/category_style.css"

const api = axios.create({
    // baseURL: '/rolemaster'
    baseURL: process.env.REACT_APP_API_URL,
})

const RoleMaster = () => {
    const [roleData,setroleData] = new useState({
        Role: "",
        Date: new Date(),
        Status: true,
    });
    
    useEffect( async () => {
        api.get('/rolemaster').then(res => console.log(res.data));
    }, [])

    const inputEvents = (event) =>{
        // const name = event.target.name;
        // const value = event.target.value;
        const {name,value} = event.target;

        setroleData({...roleData, [name]:value});
              
    }
    const navigate = useNavigate();
    const onSubmits = (event) =>{
        event.preventDefault();
    
    //    // var RoleMaster = categoryData;
          //console.log(roleData);
          api.post('/rolemaster',  roleData )
          .then(res =>{ 
              if(res.status=='201')
              navigate('/rolemasterList');
              else
              alert(res.status)
         })
         .catch((err) => {
            console.log(err.response);
            alert(err.response.status);
          });
        
        ;
    
        

    }
  
  

    return(
        <>
            {/* {alert(props.name)} */}
            <div className="container-fluid cat-display" style={{height:"518px"}}>
                <div className="col-md-6 cat-main">
                    <form control="" className="form-group customformGroup" onSubmit={onSubmits}>
                        <div className="col-sm-12 col-md-12">
                            <label htmlFor="Role">Role</label>
                            <input type="text" name="Role"  onChange={inputEvents} value={roleData.Role} className="form-control" placeholder="Enter Role Here"/>
                        </div>
                        {/* <div className="row">  */}
                        <div className=""> 
                            <hr />
                            <div className="col-sm-12 col-md-6 col-lg-6 pull-right">
                                <Link to="/rolemasterList"  className="btn pull-right"> Go Back </Link>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 pull-right">
                                <input type="submit" value="Submit" className="btn pull-right"/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
export default RoleMaster;