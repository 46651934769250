import React from "react";
import {Link} from "react-router-dom";
import ShowTable from './Table';


const BoardMasterList= () => {

        return (
                <>
                   <div style={{textAlign:"right"}}><Link to="/boardmaster"> Add Board </Link></div>
                      <ShowTable flag='board' Linkbtn={[{btnText:"",className:"fa fa-fw fa-edit text-green",flag:""}]} api='boardmaster' />
                     
                </>
      )
  }

export default BoardMasterList;


// import React from 'react'

// const BoardMasterList = () => {
//   return (
//     <div>
      
//     </div>
//   )
// }

// export default BoardMasterList
