import React,{useState,useEffect} from "react";
import { AuthContext } from '../../context/context';
import {Link} from "react-router-dom";
import axios from 'axios';
import Moment from 'moment';
import '../../css/table.css';
const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})

const UpcomingClassesList= () => {
    const { getuserid } = React.useContext(AuthContext);
        const [data, setdata] = React.useState([]);
        const [isLoading, setIsLoading] = useState(true);
        
        useEffect( async () => {
            var userid = getuserid();
               getTeacherCourse(userid);
            }, [])
            
            const getTeacherCourse =(userid) => {
                api.get(`/TeacherUpcommingClass/${userid}`).then(res =>
                    {
                        debugger
                        setIsLoading(false)
                        console.log(res.data)
                        setdata(res.data)
                      
                });
            }
            const EndTime = (StartTime,Duration) =>{
                const d = new Date("2015-03-25 "+StartTime);
                var   d2 = new Date ( d );
                d2.setMinutes ( d.getMinutes()+ parseInt(Duration));
                return Moment(d2.getTime()).format('hh:mm A') ;
            }
        return (
                <>
                       <div className="tableFixHead">
                        <table className="table" style={{fontFamily: 'sans-serif',fontSize: '12px'}}>
                               <thead>					
                                     <th>Sr.</th>  
                                     <th>Class Id</th>  
                                     <th>Class Link</th> 
                                     <th>Class Date</th> 
                                     <th>Start</th> 
                                     <th>End</th> 
                                     <th>Class</th> 
                                     <th>Subject</th> 
                               </thead>
                             
                              <tbody>
                              {isLoading ? (
                                  <h1>Loading...</h1>
                              ) : (
                                                           
                                 data.map((item,index) => (
                                  // console.log(item._id)
                                     <tr key={item._id}>
                                         <td></td>
                                         <td>{item._id}</td>
                                         <td>
                                             {/* <NavLink to={`/course/${itemm.flag}/${val}`} ><i className={}>Start Class</i></NavLink> */}
                                             <a href={item.start_url} target="_blank">Start Class</a>
                                         </td>
                                         <td>{item.ClassDate}</td>
                                         <td>{EndTime(item.ClassStart,0)}</td>
                                         <td>{item.ClassEnd}</td>
                                         <td>{item.ClassId.Class}</td>
                                         <td>{item.SubjectId.Subject}</td>
                                     </tr>
                                    ))
                          ) }
                              </tbody>
                              
                          </table>
                      </div>
                  
                </>
      )
  }

export default UpcomingClassesList;
