import React from "react";
import {Link} from "react-router-dom";
import ShowTable from './Table';


const TeacherRagistrationList= () => {

        return (
                <>
                   <div style={{textAlign:"right"}}><Link to="/teacherragistration"> Teacher Ragistration </Link></div>
                      <ShowTable flag='teacher' Linkbtn={[{btnText:"",className:"fa fa-fw fa-edit text-green",flag:""}]} api='userinfo/Teacher' />
                     
                </>
      )
  }

export default TeacherRagistrationList;
