import React,{useState,useEffect} from "react";
import {Link,useNavigate} from "react-router-dom";
import axios from 'axios';
import "./css_files/category_style.css"

const api = axios.create({
    // baseURL: '/CategoryMaster'
    baseURL: process.env.REACT_APP_API_URL,
})

const CategoryMaster = () => {
    const [categoryData,setCategoryData] = new useState({
        Category: "",
        Code: "",
        Description: "",
        Date: new Date(),
        Status: true,
    });
    
    useEffect( async () => {
        api.get('/CategoryMaster').then(res => console.log(res.data));
    }, [])

    const inputEvents = (event) =>{
        // const name = event.target.name;
        // const value = event.target.value;
        const {name,value} = event.target;

        setCategoryData({...categoryData, [name]:value});
        
        //  setCategoryData((preValue) => {
        // return{
        //        ...preValue,
        //         [name]:value,
        //     }
        // });
       
    }
    const navigate = useNavigate();
    const onSubmits = (event) =>{
        event.preventDefault();
    //    // var categorymaster = categoryData;
    //      console.log(categoryData);
          api.post('/CategoryMaster',  categoryData )
          .then(res =>{ 
              if(res.status==201)
              {
                navigate('/categorymasterList');  
              }
              alert(res.status)
         })
         .catch((err) => {
            console.log(err.response);
            alert(err.response.status);
          });
        
        ;
    
         // navigate('/categorymasterList');

    }
  
  

    return(
        <>
            {/* {alert(props.name)} */}
            <div className="container-fluid cat-display" style={{height:"518px"}}>
                <div className="col-md-6 cat-main">
                    <form control="" className="form-group customformGroup" onSubmit={onSubmits}>
                        <div className="col-sm-12 col-md-12">
                            <label htmlFor="Category">Category</label>
                            <input type="text" name="Category"  onChange={inputEvents} value={categoryData.Category} className="form-control" placeholder="Enter Category Here"/>
                        </div>
                        <div className="col-sm-12 col-md-12">
                            <label htmlFor="Category_code">Category Code</label>
                            <input type="text" name="Code"  onChange={inputEvents} value={categoryData.Code}  className="form-control" placeholder="Enter Category Code Here"/>
                        </div>
                        <div className="col-sm-12 col-md-12">
                            <label htmlFor="Description">Description</label>
                            <textarea  rows={1} name="Description"  onChange={inputEvents} value={categoryData.Description} className="form-control" placeholder="Enter Description Here" > </textarea> 
                        </div>
                        {/* row */}
                        <div className=""> 
                            <hr />
                            <div className="col-sm-12 col-md-6 col-lg-6 pull-right">
                                <Link to="/categorymasterList"  className="btn pull-right"> Go Back </Link>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 pull-right">
                                <input type="submit" value="Submit" className="btn pull-right"/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
export default CategoryMaster;