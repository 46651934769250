import React,{useState,useEffect} from "react";
import {Link,useNavigate} from "react-router-dom";
import axios from 'axios';
import "./css_files/category_style.css"

const api = axios.create({
    //baseURL: '/classmaster'
    baseURL: process.env.REACT_APP_API_URL,
})

const ClassMaster = () => {
    // const options = [
    //     { label: 'Fruit', value: 'fruit' },
    //     { label: 'Vegetable', value: 'vegetable' },
    //     { label: 'Meat', value: 'meat' },
    //   ];
    const [classData,setclassData] = new useState({
        Class: "",
        Code: "",
        Description: "",
        Date: new Date(),
        Status: true,
        Category:"",
    });
    const [options, setoptions] = React.useState([]);
    // const [value, setValue] = React.useState('');

    useEffect( async () => {
        api.get('/categorymaster').then(res => setoptions(res.data));
    }, [])

    const inputEvents = (event) =>{
        // const name = event.target.name;
        // const value = event.target.value;
        const {name,value} = event.target;
        // console.log(name+'-'+value);
        setclassData({...classData, [name]:value});
        
        //  setCategoryData((preValue) => {
        // return{
        //        ...preValue,
        //         [name]:value,
        //     }
        // });
       
    }
    const navigate = useNavigate();
    const onSubmits = (event) =>{
        event.preventDefault();
    
    //    // var BoardMaster = categoryData;
          console.log(classData);
          api.post('/classmaster',  classData )
          .then(res =>{ 
              if(res.status=='201')
              navigate('/classmasterList');
              else
              alert(res.status)
         })
         .catch((err) => {
            console.log(err.response);
            alert(err.response.status);
          });
        
        ;
    
         // navigate('/boardmasterList');

    }
  
  

    return(
        <>
            {/* {alert(props.name)} */}
            <div className="container-fluid cat-display" style={{height:"518px"}}>
                <div className="col-md-6 cat-main">
                    <form control="" className="form-group customformGroup" onSubmit={onSubmits}>
                        <div className="col-sm-12 col-md-12">
                             <label htmlFor="Category">Category</label>
                            {/*<input type="text" name="Category"  onChange={inputEvents} value={classData.Category} className="form-control" placeholder="Enter Category Here"/> */}
                        
                            <select name="Category" value={classData.Category} onChange={inputEvents} className="form-control  form-select">
                            <option value=''>Select Category</option>
                            {options.map((option) => (
                                <option key={option._id} value={option._id}>{option.Code} | {option.Category}</option>
                            ))}
                            </select>
                      
                        </div>
                        <div className="col-sm-12 col-md-12">
                            <label htmlFor="Class">Class</label>
                            <input type="text" name="Class"  onChange={inputEvents} value={classData.Class} className="form-control" placeholder="Enter Class Here"/>
                        </div>
                        <div className="col-sm-12 col-md-12">
                            <label htmlFor="Code">Code</label>
                            <input type="text" name="Code"  onChange={inputEvents} value={classData.Code} className="form-control" placeholder="Enter Class Code Here"/>
                        </div>
                        <div className="col-sm-12 col-md-12">
                            <label htmlFor="Description">Description</label>
                            <input type="text" name="Description"  onChange={inputEvents} value={classData.Description} className="form-control" placeholder="Enter Description Here"/>
                        </div>
                        {/* row */}
                        <div className=""> 
                            <hr />
                            <div className="col-sm-12 col-md-6 col-lg-6 pull-right">
                                <Link to="/classmasterList"  className="btn pull-right"> Go Back </Link>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 pull-right">
                                <input type="submit" value="Submit" className="btn pull-right"/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
export default ClassMaster;