import React,{useEffect,useState} from 'react';
import { AuthContext } from '../src/context/context';
import './css/App.css';
// import {Routes,  Route, Navigate } from "react-router-dom";
import { Routes, Route,useNavigate,useLocation,Navigate } from "react-router-dom";
import Header from './components/header';
import Footer from './components/footer';
import Home from './views/home/Home';
import Error404 from './components/error404';
import StudentForm from './components/Forms/StudentForm';
import CourseFees from './views/course/courseFees';
import CrashCourse from './views/course/crashCourse';
import Ddays from './views/D_days/D_days';
import RegisterType from './components/Forms/RegisterType';
import Blog from './views/Web/Blog';


//student
import StudentHeader from './views/student/StudentHeader';
import StudentIndex from './views/student/StudentIndex';
import SEnrolledCourse from './views/student/SEnrolledCourse';
import SUpcomingClasses from './views/student/SUpcomingClasses';
import SCompleateClasses from './views/student/SCompleateClasses';
//teacher
import TeacherHeader from './views/teacher/TeacherHeader';
import TeacherIndex from './views/teacher/TeacherIndex';
import TeachingSubjectList from './views/teacher/TeachingSubjectList';
import TEnrolledCourse from './views/teacher/EnrolledCourse';
import TUpcomingClasses from './views/teacher/UpcomingClasses';
import TCompleateClasses from './views/teacher/CompleateClasses';
// import axios from 'axios';

// // import {AuthContext} from './context/context';
import PrivacyPolicy from './views/Web/PrivacyPolicy';
import TermAndConditions from './views/Web/TermAndConditions';
import Faqs from './views/Web/Faqs';
import Howitworks from './views/Web/Howitworks';
import OurTeachers from './views/Web/OurTeachers';
import ContactUs from './views/Web/ContactUs';
import OurClasses from './views/Web/OurClasses';
import DemoClasses from './views/Web/demoClasses';
import Syllabus from './views/Web/Syllabus';
import DemoRequest from './components/Forms/DemoRequest';
import Testimonials from './views/Web/testimonial';
import Gallery from './views/Web/Gallery';
import Coding from './views/Web/Coding';
import TeacherForm from './components/Forms/TeacherForm';
import Previousyearquestionpaper from './views/Web/Previous_year_question_paper';
import Blodetails from './views/Web/Blog_details';
import SignIn from './views/Web/SignIn';
import CourseFeesStructure from './views/course/courseFeesStructure';
import ProgrammingCourses from './views/Web/ProgrammingCourses';
import Dhashboard from './components/admin/Dhashboard';
//  import Dhashboard from './components/admin/Home';

 import AdminHeader from './components/admin/AdminHeader';
 import AdminSidebar from './components/admin/AdminSidebar';
 import CategoryMasterList from './components/admin/CategoryMasterList';
  import CategoryMaster from './components/admin/CategoryMaster';
  import BoardMasterList from './components/admin/BoardMasterList';
  import BoardMaster from './components/admin/BoardMaster';
 import ClassMasterList from './components/admin/ClassMasterList';
 import ClassMaster from './components/admin/ClassMaster';
  import SubjectMasterList from './components/admin/SubjectMasterList';
  import SubjectMaster from './components/admin/SubjectMaster';
 import ChapterMasterList from './components/admin/ChapterMasterList';
 import ChapterMaster from './components/admin/ChapterMaster';
 import RoleMasterList from './components/admin/RoleMasterList';
 import RoleMaster from './components/admin/RoleMaster';
  import StudentRagistrationList from './components/admin/StudentRagistrationList';
   import StudentRagistration from './components/admin/StudentRagistration';
   import AdminRagistrationList from './components/admin/AdminRagistrationList';
  import AdminRagistration from './components/admin/AdminRagistration';
  import TeacherRegisterList from './components/admin/TeacherRegisterList';
 import TeacherRegister from './components/admin/TeacherRegister';

 import CountryMasterList from './components/admin/CountryMasterList';
 import CountryMaster from './components/admin/CountryMaster';
 import StateMasterList from './components/admin/StateMasterList';
 import StateMaster from './components/admin/StateMaster';
 import CityMasterList from './components/admin/CityMasterList';
 import CityMaster from './components/admin/CityMaster';
 import FacultyMasterList from './components/admin/FacultyMasterList';
 import FacultyMaster from './components/admin/FacultyMaster';

 import CourseList from './components/admin/CourseList';
 import Course from './components/admin/Course';
 import ConfirmScheduleClass from './components/admin/ConfirmScheduleClass';
import ConfirmScheduleClassList from './components/admin/ConfirmScheduleClassList';
import DDaysList from './components/admin/DDaysList';
import DDays from './components/admin/DDays';
import ABlogList from './components/admin/BlogList';
import ABlog from './components/admin/blog';
import DemoRequestList from './components/admin/DemoRequestList';
import ContactUsList from './components/admin/ContactUsList';

import ZoomMeetingList from './components/admin/ZoomMeetingList';
import ZoomMeetingUrl from './components/admin/ZoomMeetingurl';

import EmailVerification from './components/EmailVerification';

const App = (props) => {
   const [checkauth, setcheckauth] = useState('');
  const spliturl= (window.location.href).split("/");
 // const checkauth = spliturl[3];
  //  console.log(checkauth)
  //let checkauth ="";
  const navigate = useNavigate();
  const location = useLocation();
        const from = location.state?.from?.pathname || "/";
  // const {auth,setAuth} = useAuth();
  const initialLoginState = {
    isLoading: true,
    userId: null,
    userToken: null,
    userRole:null
  };

 
  const loginReducer = (prevState, action) => {
    console.log(action)
    switch( action.type ) {
      case 'RETRIEVE_TOKEN': 
        return {
          ...prevState,
          userRole:action.userRole,
          userId: action.userId,
          userToken: action.userToken,
          isLoading: false,
        };
      
      case 'LOGIN': 
      debugger
        return {
          ...prevState,
          userId: action.userId,
          userToken: action.userToken,
          userRole:action.userRole,
          isLoading: false,
        };
      case 'LOGOUT': 
        return {
          ...prevState,
          userId: null,
          userToken: null,
          userRole:null,
          isLoading: false,
        };
      case 'REGISTER': 
        return {
          ...prevState,
          userId: action.userId,
          userToken: action.userToken,
          userRole:action.userRole,
          isLoading: false,
        };
    }
  };
 
   const [loginState, dispatch] = React.useReducer(loginReducer, initialLoginState);
  
// debugger
  const authContext = React.useMemo(() => ({
    
    signIn: async(foundUser) => {
     // console.log('user foundUser: ', foundUser);
      try {
        //debugger
        localStorage.setItem('userToken', JSON.stringify(foundUser));
        //checkauth=foundUser.userRole;
      } catch(e) {
        console.log(e);
      }
      // console.log('user token: ', userToken);
      dispatch({ type: 'LOGIN',userId:foundUser.userId,userToken:foundUser.userToken,userRole:foundUser.userRole });
      //setcheckauth(foundUser.userRole)
      
      (foundUser.userRole=='Teacher')? navigate("/Teacher", { replace: true })
      :(foundUser.userRole=='Student')? navigate("/Student", { replace: true })
      :(foundUser.userRole=='Admin')? navigate("/Admin", { replace: true })
       : navigate(from, { replace: true });

      //alert(from)
      
    },
    signOut: async() => {
      // setUserToken(null);
      // setIsLoading(false);
      try {
        localStorage.removeItem('userToken');
      } catch(e) {
        console.log(e);
      }
      dispatch({ type: 'LOGOUT' });
      navigate('/', { replace: true });
    },
    signUp: () => {
      // setUserToken('fgkj');
      // setIsLoading(false);
    },
    // toggleTheme: () => {
    //   setIsDarkTheme( isDarkTheme => !isDarkTheme );
    // },
    getusertoken: () => {
    //  console.warn('function call')
   var obj = JSON.parse(localStorage.getItem('userToken'));
   return obj.userToken;
   },
   getuserid: () => {
    //  console.warn('function call')
   var obj = JSON.parse(localStorage.getItem('userToken'));
   return obj.userId;
   },
   getuserrole: () => {
    //  console.warn('function call')
   var obj = JSON.parse(localStorage.getItem('userToken'));
   return obj.userRole;
   },
   getusername: () => {
    //  console.warn('function call')
   var obj = JSON.parse(localStorage.getItem('userToken'));
   return obj.username;
   },
  }), []);

  useEffect(() => {
    setTimeout(async() => {
      // setIsLoading(false);
      let userToken;
      userToken = null;
      // let userRole;
      // userRole = null;
      // let userId;
      // userId = null;
      try {
        //dispatch({ type: 'LOGOUT' });
        userToken = localStorage.getItem('userToken');
        console.log(JSON.parse(userToken))
        var obj = JSON.parse(userToken);
        
      } catch(e) {
        console.log(e);
      }
      // console.log('user token: ', userToken);
      dispatch({ type: 'RETRIEVE_TOKEN',userToken:obj.userToken,userId:obj.userId,userRole:obj.userRole });
    }, 1000);
  }, []);
  return (
    <>
      <div className="App">
      <AuthContext.Provider value={authContext}>
        {
          (loginState.userRole=='Student')?
          <>
          <StudentHeader />
          <Routes>
           <>
          <Route exact path="/" element={<StudentIndex />} >  </Route>
          <Route exact path="/index" element={<Navigate replace to="/Student" />}  >  </Route>
          <Route exact path="/Student" element={<StudentIndex />} ></Route>
          <Route path='/Student/EnrolledCourse' element={ <SEnrolledCourse /> } />
          <Route path='/Student/UpcomingClasses' element={ <SUpcomingClasses /> } />
          <Route path='/Student/CompleateClasses' element={ <SCompleateClasses /> } />
          <Route path='EmailVerification/:id' element={ <EmailVerification /> }> </Route>
          <Route  path="*" element={<Error404 />} ></Route>
          </>
           </Routes>
           </>
        
        :
        (loginState.userRole=='Teacher')?
        <>
            
        <TeacherHeader />
        <Routes>
         <>
          <Route exact path="/" element={<TeacherIndex />} >  </Route>
        <Route exact path="/index" element={<Navigate replace to="/Teacher" />}  >  </Route>
         <Route exact path="/Teacher" element={<TeacherIndex />} ></Route>
         <Route path='/Teacher/TeachingSubjectList' element={ <TeachingSubjectList /> } />
         <Route path='/Teacher/EnrolledCourse' element={ <TEnrolledCourse /> } />
         <Route path='/Teacher/UpcomingClasses' element={ <TUpcomingClasses /> } />
         <Route path='/Teacher/CompleateClasses' element={ <TCompleateClasses /> } />
         <Route path='EmailVerification/:id' element={ <EmailVerification /> }> </Route>
         <Route  path="*" element={<Error404 />} ></Route>
        </>
         </Routes>
      
         {/* <Footer /> */}
     </>
       
        :
        (loginState.userRole=='Admin')?
        <>
        
         <section className="header-toggle-section sidebar-is-reduced adminsection ">
           <main className="l-main">
             <div className="content-wrapper content-wrapper--with-bg" id="pagecontent">
          <AdminHeader />
          <AdminSidebar /> 
          <Routes>
            <>
             <Route exact path="/" element={<Dhashboard />} >  </Route>
           <Route exact path="/index" element={<Navigate replace to="/Admin" />}  >  </Route>
            <Route path="/Admin" element={<Dhashboard />} />
            <Route path="/Admin" element={<Dhashboard />} />
             <Route path="/categorymasterList" element={<CategoryMasterList />} />
           <Route path="/categorymaster" element={<CategoryMaster />} />
             <Route path="/BoardMasterList" element={<BoardMasterList />} />
            <Route path="/BoardMaster" element={<BoardMaster />} />
            <Route path="/ClassMasterList" element={<ClassMasterList />} />
            <Route path="/ClassMaster" element={<ClassMaster />} />
            <Route path="/SubjectMasterList" element={<SubjectMasterList />} />
            <Route path="/SubjectMaster" element={<SubjectMaster />} />
           <Route path="/ChapterMasterList" element={<ChapterMasterList />} />
            <Route path="/ChapterMaster" element={<ChapterMaster />} />
            <Route path="/RoleMasterList" element={<RoleMasterList />} />
            <Route path="/RoleMaster" element={<RoleMaster />} />
            <Route path="/StudentRagistrationList" element={<StudentRagistrationList />} />
            <Route path="/StudentRagistration" element={<StudentRagistration />} />
            <Route path="/AdminRagistrationList" element={<AdminRagistrationList />} />
            <Route path="/AdminRagistration" element={<AdminRagistration />} />
            <Route path="/TeacherRegisterList" element={<TeacherRegisterList />} />
            <Route path="/TeacherRagistration" element={<TeacherRegister />} />
            <Route path="/CountryMasterList" element={<CountryMasterList />} />
            <Route path="/CountryMaster" element={<CountryMaster />} />
            <Route path="/StateMasterList" element={<StateMasterList />} />
            <Route path="/StateMaster" element={<StateMaster />} />


            <Route path="/CityMasterList" element={<CityMasterList />} />
            <Route path="/CityMaster" element={<CityMaster />} />
            <Route path="/FacultyMasterList" element={<FacultyMasterList />} />
            <Route path="/FacultyMaster" element={<FacultyMaster />} />
            <Route path="/CourseList" element={<CourseList />} />
            <Route path="/Course" element={<Course />} />
            <Route path='course/:flag/:id' element={ <Course /> } />
            <Route path="/ConfirmScheduleClass" element={<ConfirmScheduleClass />} />
            <Route path="/ConfirmScheduleClassList" element={<ConfirmScheduleClassList />} />
            <Route path="/DDaysList" element={<DDaysList />} />
            <Route path="/DDays" element={<DDays />} />
            <Route path="/BlogList" element={<ABlogList />} />
            <Route path="/Blog" element={<ABlog />} />
            <Route path="/DemoRequestList" element={<DemoRequestList />} />
            <Route path="/ContactUsList" element={<ContactUsList />} />

            <Route path="/ZoomMeetingList" element={<ZoomMeetingList />} />
            <Route path="/ZoomMeetingUrl/:id" element={<ZoomMeetingUrl />} />

            <Route path='EmailVerification/:id' element={ <EmailVerification /> } />
            </>
            </Routes>
            </div>
          </main>  
          </section> 
         </>
        :
        <div className='container-full-home'>
              <Header />
              <Routes>
                  <Route exact path="/" element={<Home />} >  </Route>
                  <Route exact path="/index" element={<Navigate replace to="/Home" />}  >  </Route>
                  <Route exact path="/home" element={<Home />} >  </Route>
                  <Route exact path="/register-type" element={ <RegisterType/> }>  </Route>
                  <Route exact path="/login" element={ <SignIn/>  }>  </Route>
                  <Route exact path="/student-register" element={ <StudentForm/> }>  </Route>
                  <Route exact path="/crash-course" element={ <CrashCourse/>  }>  </Route>
                  <Route exact path="/courses" element={ <CourseFees/> }>  </Route>
                  <Route exact path="/courses-fees" element={ <CourseFees/> }>  </Route>
                  <Route exact path="/D-days" element={ <Ddays/> } >   </Route>
                  <Route exact path="/blog" element={ <Blog/> } >   </Route>
                  <Route exact path="/privacy-policy" element={ <PrivacyPolicy /> } >   </Route>
                  <Route exact path="/term-and-conditions" element={ <TermAndConditions /> } >   </Route>
                  <Route exact path="/Faq" element={ <Faqs /> } >   </Route>
                  <Route exact path="/how-it-works" element={ <Howitworks /> } >   </Route>
                  <Route exact path="/our-teachers" element={ <OurTeachers /> } >   </Route>
                  <Route exact path="/contact-us" element={ <ContactUs /> } >   </Route>
                  <Route exact path="/our-classes" element={ <OurClasses /> } >   </Route>
                  <Route exact path="/Demo-Classes" element={ <DemoClasses /> } >   </Route>
                  <Route exact path="/syllabus" element={ <Syllabus /> } >   </Route>
                  <Route exact path="/demo-Request" element={ <DemoRequest /> } >   </Route>
                  <Route exact path="/testimonial" element={ <Testimonials /> } >   </Route>
                  <Route exact path="/gallery" element={ <Gallery /> } >   </Route>
                  <Route exact path="/coding" element={ <Coding /> } >   </Route>
                  <Route exact path="/previous-year-question-paper" element={ <Previousyearquestionpaper /> } >   </Route>
                  <Route exact path="/blog-details" element={ <Blodetails /> } >   </Route>
                  <Route exact path="/teacher-register" element={ <TeacherForm /> } >   </Route>
                  <Route exact path="/courses-fees-structure" element={ <CourseFeesStructure /> } >   </Route>
                  <Route exact path="/Programming-Courses" element={ <ProgrammingCourses /> } >   </Route>
                  <Route exact path="/CBSE-question-paper" element={ <Previousyearquestionpaper /> } >   </Route>
                  <Route path='EmailVerification/:id' element={ <EmailVerification /> }> </Route>
                  {/* <Route exact path="/Admin" element={ <Dhashboard/> } >   </Route> */}
                  <Route  path="*" element={<Error404 />} ></Route>
                </Routes>
              <Footer />
            </div> 
        }
          </AuthContext.Provider>
      </div>
     </>
  );
}

export default App;














// <Routes>
//       <Route path="/" element={<Layout />}>
//         {/* public routes */}
//         <Route path="login" element={<Login />} />
//         <Route path="register" element={<Register />} />
//         <Route path="linkpage" element={<LinkPage />} />
//         <Route path="unauthorized" element={<Unauthorized />} />

//         {/* we want to protect these routes */}

//         <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
//           <Route path="/" element={<Home />} />
//         </Route>

//         <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
//           <Route path="editor" element={<Editor />} />
//         </Route>


//         <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
//           <Route path="admin" element={<Admin />} />
//         </Route>

//         <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.User]} />}>
//           <Route path="lounge" element={<Lounge />} />
//         </Route>

//         {/* catch all */}
//         <Route path="*" element={<Missing />} />
//       </Route>
//     </Routes>