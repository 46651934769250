import React,{useState,useEffect} from "react";
import { AuthContext } from '../../context/context';
import {Link} from "react-router-dom";
import '../../css/table.css';
import axios from 'axios';
const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  })

const TeachingSubjectList= () => {
        const [data, setdata] = React.useState([]);
        const [isLoading, setIsLoading] = useState(true);
        const { getuserid } = React.useContext(AuthContext);
        useEffect( async () => {
                var userid = getuserid();
                getTeachingSubject(userid)
            }, [])
            
            const getTeachingSubject =(userid) => {
                api.get(`/TeachingSubject/${userid}`).then(res =>
                    {
                        debugger
                        setIsLoading(false)
                        //console.log(res.data)
                        setdata(res.data)
                      
                });
            }
        return (
                <>
                 
                 <div className="tableFixHead">
                        <table className="table" style={{fontFamily: 'sans-serif',fontSize: '12px'}}>
                               <thead>
                                     <th>Sr.</th>  
                                     <th>Category</th>  
                                     <th>Class</th> 
                                     <th>Subject</th> 
                                     <th>Subject Code</th> 
                               </thead>
                             
                              <tbody>
                              {isLoading ? (
                                  <h1>Loading...</h1>
                              ) : (
                                                           
                                 data.map((item,index) => (
                                  // console.log(item._id)
                                     <tr key={item._id}>
                                       
                                         <td> </td>
                                         <td>{item.Category.Category}</td>
                                         <td>{item.Class.Class}</td>
                                         <td>{item.Subject.Subject}</td>
                                         <td>{item.Subject.Code}</td>
                                       
                                     </tr>
                                    ))
                          ) }
                              </tbody>
                              
                          </table>
                          </div>
                        
                          {/* </div> */}
                </>
      )
  }

export default TeachingSubjectList;
