import React from 'react'
import LoginForms from '../../components/Forms/LoginForm'

function SignIn() {
  return (
    <>
      <div className="sign_viewPage_container">
          <LoginForms/>
      </div>
    </>
  )
}

export default SignIn
