import React,{useState,useEffect} from 'react';
import {Link,useNavigate} from "react-router-dom";
import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';
 import "./css_files/studentForm_style.css"
const api = axios.create({
    // baseURL: '/userinfo'
    baseURL: process.env.REACT_APP_API_URL,
})

const StudentRagistration= () => {
    const [userinfoData,setuserinfoData] = new useState({
        FirstName: "",
        LastName: "",
        Code: "",
        Gender: "",
        Email: "",
        Mobile: "",
        Country: "",
        Password:"",
        ConfPassword:"",
        EmailConfirmed: false,
        Appronal: false,
        Date: new Date(),
        Status: true,
        TermsandConditions:false,
        Designation:"Student",
    });
    const [countrymaster, setCountrymaster] = useState([]);
    useEffect( async () => {
        api.get('/userinfo').then(res => console.log(res.data));
        api.get('/countrymaster')
        .then(res => {
            const countries = res.data;
            setCountrymaster(countries);
            // console.log(countries)
            }) 
    }, [])
    const inputEvents = (event) =>{
        // const name = event.target.name;
        // const value = event.target.value;
        const {name,value} = event.target;

        setuserinfoData({...userinfoData, [name]:value});
        
        //  setCategoryData((preValue) => {
        // return{
        //        ...preValue,
        //         [name]:value,
        //     }
        // });
       
    }
    const navigate = useNavigate();
    const onSubmits = (event) =>{
        console.log(userinfoData)
        event.preventDefault();
          api.post('/userinfo',  userinfoData )
          .then(res =>{ 
              if(res.status=='201')
                {
                        // var userdata={
                        //             "email":userinfoData.Email,
                        //             "password":userinfoData.Password,
                        //             "datetime":new Date(),
                        //             "role":userinfoData.Designation,
                        //             "Userinformation":res.data._id                 
                        //             }
                        //             console.log(res.data._id);
                        // axios.post('/user',  userdata )
                        // .then(res =>{ 
                        //     if(res.status=='201')
                        //     navigate('/studentragistrationList');
                        //     else
                        //     alert(res.status)
                        // })
                        // .catch((err) => {
                        //     console.log(err.response);
                        //     alert(err.response.status);
                        // });
                        navigate('/studentragistrationList');
                }
             // navigate('/studentragistrationList');
              else
              alert(res.status)
         })
         .catch((err) => {
            console.log(err.response);
            alert(err.response.status);
          });
        
        ;
    
         // navigate('/boardmasterList');

    }

  return (
      <>
            <div className="student_container">
            <div className="container">
            <section>

            <div className="col-md-6 col-lg-6 col-sm-6">
                <div className="stFormGroup">
                    <div className="col-12 text-center">
                        <h2 style={{color:"orange"}}>Student Registration</h2>
                    </div>
                    <hr />
                    <form className="row g-3 needs-validation" novalidate  onSubmit={onSubmits}>
                        <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                            <label htmlFor="FirstName" className="form-label">First name</label>
                            <input type="text" className="form-control"   name="FirstName"  onChange={inputEvents} value={userinfoData.FirstName} placeholder='Please Enter First Name' required />
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                            <label htmlFor="LastName" className="form-label">Last name</label>
                            <input type="text" className="form-control" name="LastName"  onChange={inputEvents} value={userinfoData.LastName} placeholder='Please Enter Last Name'  required />
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                            <label htmlFor="Email" className="form-label">Email</label>
                            <div className="input-group has-validation">
                            <span className="input-group-text" id="inputGroupPrepend">@</span>
                            <input type="text" className="form-control" name="Email"  onChange={inputEvents} value={userinfoData.Email} placeholder='example@gmail.com' required />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                            <label htmlFor="Mobile" className="form-label">Mobile Number</label>
                            <input type="text" className="form-control" name="Mobile"  onChange={inputEvents} value={userinfoData.Mobile} placeholder='0988765446'  required />
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                            <label htmlFor="Gender" className="form-label">Gender</label>
                            <select className="form-select form-control" name="Gender"  onChange={inputEvents} value={userinfoData.Gender} required>
                                <option selected disabled value="">Select Gender</option>
                                <option value="Male"> Male </option>
                                <option value="Female"> Female </option>
                                <option value="Others"> Others </option>
                            </select>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                            <label htmlFor="Country" className="form-label">Country</label>
                            <select className="form-select form-control" name="Country"  onChange={inputEvents} value={userinfoData.Country} required>
                            <option key="1011" value="">Select Country</option>
                            {countrymaster.map((cntry,index) => 
                                <option key={(index+'cnty').toString()} value={cntry._id}>{(cntry.Country.substring(0,2)) + ' | '+cntry.Country}</option>
                            ) }
                            </select>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                            <label htmlFor="Password" className="form-label">Password</label>
                            <input type="password" class="form-control" name="Password"  onChange={inputEvents} value={userinfoData.Password} placeholder='Please Enter password' required />
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                            <label htmlFor="ConfPassword" className="form-label">Confirm password</label>
                            <input type="password" class="form-control" name="ConfPassword"  onChange={inputEvents} value={userinfoData.ConfPassword} placeholder='Please Enter Confirm password' required />
                        </div>
                        <div className="col-12">
                            <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" name="TermsandConditions"  onChange={inputEvents} value={userinfoData.TermsandConditions} required />
                            <label className="form-check-label" htmlFor="invalidCheck">
                                Agree to terms and conditions
                            </label>
                            </div>
                        </div>
                        <div className="col-12 text-end">
                            <input type="submit" value="Submit" className="abtn "/>
                        </div>
                    </form>
                </div>
            </div>
    </section>
    </div>
    </div>
    </>
  )
}

export default StudentRagistration;
