import React,{useState,useEffect} from "react";
// import '../css_files/tableStyle.css';
import axios from 'axios';


const BindOption = (props) => {
// alert( '/'+props.api+'/'+props.id)
    const api = axios.create({
       // baseURL: '/'+props.api+'/'+props.id
       baseURL: process.env.REACT_APP_API_URL,
    })

           const [isLoading, setIsLoading] = useState(true);
           const [data, setData] = useState([]);

           const getdata = async() => {
           // alert('1')
            api.get(`/${props.api}/${props.id}`).then(res => {
              debugger
              (res.data.result) ? 
               setData(res.data.result)
               : 
              setData(res.data);
              setIsLoading(false)
            });
            
           }
           
          useEffect(() => {
           
            getdata();
            }, []);

        
        return (
                 
         <>
        {/* <label htmlFor={props.name} className="form-label">{props.lable}</label> */}
        <select className="form-select form-control"  >
            <option value="">Select {props.selectValue} </option>
            {data.map((element,index) => ( 
             // alert()
                 //console.log(element.SName._id)
                 <option  value={element.SName._id}> {element.SName.FirstName+' '+element.SName.LastName} </option>
            ) )}
        </select>
        </>  
                  
      )
  }

export default BindOption;
