import React from 'react';
import SelectOption, { Formtitle, InputList, TextArea } from './Forms';

const NetworkInfoUpload = () => {
  return (
    <>
        <section className="qualification-container">
            <div className="stFormGroup">
                <Formtitle title="NETWORK INFO | UPLOADS" display="d-none" />
                <div className="row pt-2"> 
                    
                    <SelectOption classnames="col-md-3 col-lg-3 col-sm-6 pb-2" name="days-availablity" lable="Days Availablity"  option={['monday','tuesday','wednesday','thrusday','friday','saturday','sunday']} selectValue="Days Availablity" />
                    
                    <SelectOption classnames="col-md-3 col-lg-3 col-sm-6 pb-2" name="hrs-daily-available" lable="Hours daily Available" option={['1 Hours','2 Hours','3 Hours','4 Hours','5 Hours','6 Hours','7 Hours','8 Hours']} selectValue="Hours daily Available" />
                    
                    <SelectOption classnames="col-md-3 col-lg-3 col-sm-6 pb-2" name="preferred-time-for-teachinge" lable="Preferred time for teachinge" option={['10-12 pm','11-12 pm','12-1 pm','02-12 pm','11-12 pm','11-12 pm','11-12 pm','8 Hours']} selectValue="Preferred time for teachinge" />
                    
                    <SelectOption classnames="col-md-3 col-lg-3 col-sm-6 pb-2" name="total-teaching-experience" lable="Total Teaching Experience" option={['Less than 1 year ',' Less than 2 year ',' Less than 3 year ',' Less than 4 year ',' More than 5 years ']} selectValue="Total Teaching Experience" />
                    
                    <SelectOption classnames="col-md-3 col-lg-3 col-sm-6 pb-2" name="type-of-connectione" lable="Type of Connection" option={['Broadband ',' Wifi ',' Dongle ',' Mobile ',' Cable ']} selectValue="Type of Connection" />
                    
                    <SelectOption classnames="col-md-3 col-lg-3 col-sm-6 pb-2" name="download-speed-of-internet-connection" lable="Download speed of Internet Connection" option={['512-kbps ',' 1-mbps ',' 2-mbps ',' 4-mbps ',' CaMore than 4 mbpsble ']} selectValue="Download speed of Internet Connection" />
                    
                    <SelectOption classnames="col-md-3 col-lg-3 col-sm-6 pb-2" name="upload-speed-of-internet-connection" lable="Upload speed of Internet Connection" option={['512-kbps ',' 1-mbps ',' 2-mbps ',' 4-mbps ',' CaMore than 4 mbpsble ']} selectValue="Upload speed of Internet Connection" />
                    
                    <InputList inputtype="file" classnames="col-md-3 col-lg-3 col-sm-6 pb-2"  name="internet-speed-scrrenshot" lable="Internet Speed Scrrenshot"  placeholder="Internet Speed Scrrenshot" />
                    
                    <InputList inputtype="file" classnames="col-md-3 col-lg-3 col-sm-6 pb-2" name="profile-pic-upload" lable="Profile Pic Upload"  placeholder="Profile Pic Upload" />
                    
                    <InputList inputtype="file" classnames="col-md-3 col-lg-3 col-sm-6 pb-2" name="cv-upload" lable="Cv Upload" placeholder="Cv Upload" />

                    <SelectOption classnames="col-md-3 col-lg-3 col-sm-6 pb-2" name="association-with-gurukul-is" lable="Association with gurukul is" option={['Primary source of Income ',' Additonal source of Income ',' Pursue your passion ']} selectValue="Association with gurukul is" />
                    
                    <TextArea classnames="col-md-3 col-lg-3 col-sm-6 pb-2"  name="referance-reason" lable="How you come to know with tog" placeholder="How you come to know with tog"/>
                </div>
            </div>
        </section>
    </>
  )
}

export default NetworkInfoUpload

