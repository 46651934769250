import React,{useState,useEffect} from "react";
import { AuthContext } from '../../context/context';
import Moment from 'moment';
// import {Link} from "react-router-dom";
// import ShowTable from '../Table';
import axios from 'axios';
const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  })
const DemoRequestList= () => {
        const [data, setdata] = React.useState([]);
        const [isLoading, setIsLoading] = useState(true);
        const { getuserid } = React.useContext(AuthContext);
        useEffect( async () => {
                var userid = getuserid();
                getTeachingSubject(userid)
            }, [])
            
            const getTeachingSubject =(userid) => {
                api.get(`/DemoClass`).then(res =>
                    {
                        debugger
                        setIsLoading(false)
                        //console.log(res.data)
                        setdata(res.data)
                      
                });
            }
        return (
                <>
                      <div className="tableContainer" style={{height:"474px"}}>
                       <div id="tableContainer2" className="container-tb" style={{height:"545px"}}>
                             <table  id="myTable">
                               <thead>
                                     <th>Sr.</th>  
                                     <th>Name</th> 
                                     <th>Gender</th> 
                                     <th>Email</th> 
                                     <th>Phone</th> 
                                     <th>Date</th>
                                     <th>Class</th> 
                                     <th>Subject</th>
                                     <th>Country</th>  
                               </thead>
                             
                              <tbody>
                              {isLoading ? (
                                  <h1>Loading...</h1>
                              ) : (
                                                           
                                 data.map((item,index) => (
                                  // console.log(item._id)
                                     <tr key={item._id}>
                                       
                                         <td> </td>
                                         <td>{item.Name}</td>
                                         <td>{item.Gender}</td>
                                         <td>{item.Email}</td>
                                         <td>{item.Phone}</td>
                                         <td>{Moment(item.Date).format('DD-MMM-YYYY')}</td>
                                         <td>{item.Class}</td>
                                         <td>{item.Subject}</td>
                                         <td>{(item.Country)?item.Country.Country:''}</td>
                                     </tr>
                                    ))
                          ) }
                              </tbody>
                              
                          </table>
                      </div>
                  </div>
                </>
      )
  }

export default DemoRequestList;
