import React,{useState,useEffect} from "react";
import {Link,NavLink} from "react-router-dom";
import { Buffer } from 'buffer';
import { AuthContext } from '../../context/context';
import axios from 'axios';
const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  })
 
const ZoomMeetingList= () => {
    const [data, setdata] = React.useState([]);
    const [startdata, setstartdatadata] = React.useState([]);
    const [token, settoken] = React.useState({"access_token":"eyJhbGciOiJIUzUxMiIsInYiOiIyLjAiLCJraWQiOiJkMGMyYjQ5NS04YmVhLTRiNGYtOTAxYi1jMWQ3NjRkMTU4YzYifQ.eyJ2ZXIiOjcsImF1aWQiOiI3NjA3YTk1MjQ5MmQ5ZDhmYmFhODAyMjczNzczZmMzZSIsImNvZGUiOiJoRmNTM01TRW93R1FqTHJzUEtaUmg2UkJ0RDR0Tm5HdlEiLCJpc3MiOiJ6bTpjaWQ6Y1RnQWlFQnFTSlc3NDloQVVpamlyZyIsImdubyI6MCwidHlwZSI6MCwidGlkIjowLCJhdWQiOiJodHRwczovL29hdXRoLnpvb20udXMiLCJ1aWQiOiJ3a2RDaXBBUFFGaUhhdHdZMUxMaTJ3IiwibmJmIjoxNjY0MzYxMzQxLCJleHAiOjE2NjQzNjQ5NDEsImlhdCI6MTY2NDM2MTM0MSwiYWlkIjoiV2RQa2JXQ1BTRlNZdUNZQmMwUTdDZyIsImp0aSI6ImQ2NGJjODNkLTBhYjYtNGRlMi05ZGMxLTliYTcxYmQ4YzRjZiJ9.kTeSYAYQNdP4wYxQTlLa2mzUoY2oxDGFUwbgLPa758CJXQgtz6C0FaIBf_N9SBP8LN5dMmNs43e5hAFR604OwQ","token_type":"bearer","refresh_token":"eyJhbGciOiJIUzUxMiIsInYiOiIyLjAiLCJraWQiOiJiMjU4YWUwNC1kZGVhLTRjYWQtODBjOS03YWE2ZTVmZTFmNjcifQ.eyJ2ZXIiOjcsImF1aWQiOiI3NjA3YTk1MjQ5MmQ5ZDhmYmFhODAyMjczNzczZmMzZSIsImNvZGUiOiJoRmNTM01TRW93R1FqTHJzUEtaUmg2UkJ0RDR0Tm5HdlEiLCJpc3MiOiJ6bTpjaWQ6Y1RnQWlFQnFTSlc3NDloQVVpamlyZyIsImdubyI6MCwidHlwZSI6MSwidGlkIjowLCJhdWQiOiJodHRwczovL29hdXRoLnpvb20udXMiLCJ1aWQiOiJ3a2RDaXBBUFFGaUhhdHdZMUxMaTJ3IiwibmJmIjoxNjY0MzYxMzQxLCJleHAiOjIxMzc0MDEzNDEsImlhdCI6MTY2NDM2MTM0MSwiYWlkIjoiV2RQa2JXQ1BTRlNZdUNZQmMwUTdDZyIsImp0aSI6ImM5OGUzMjk2LTAzYzQtNDE1Ni04MjY1LTY4YTU3OWY2YjFhNCJ9.Sj_nEPLdeGcoyCx9WL4qrdpXGht-XdBE0jh6s-iP2VZRTRPKwt7WLR25WiT9UZVAjcwiNIEACSx2s8tDjzU_xg","expires_in":3599,"scope":"meeting:write:admin user:write:admin"})
    const [isLoading, setIsLoading] = useState(true);
    const { getuserid } = React.useContext(AuthContext);
    useEffect( async () => {
            var userid = getuserid();
            getData()
        }, [])
        
        const getData =() => {
            api.get(`/meetinglist/barakpardeep12@gmail.com`).then(res =>
                {
                    debugger
                    setIsLoading(false)
                    console.log(res.data.meetings)
                    setdata(res.data.meetings)
                  
            });
        }
        // const createData =() => {
        //     api.post(`/createmeeting/barakpardeep12@gmail.com`,{"topic":"pardeep1","agenda":"barak1","start_time":"2022-09-30T15:00:00","duration":60}).then(res =>
        //         {
        //             debugger
        //             setIsLoading(false)
        //             console.log(res.data)
        //            // setdata(res)
        //             getData()
        //     });
         
        // } 
        
        const getstarturlData =(identifier) => {
            api.get(`/meeting/${identifier}`).then(res =>
                {
                    debugger
                    setIsLoading(false)
                    console.log(res.data)
                    setstartdatadata(res.data)
                  
            });
        }
        
        return (
                <>
                   <div style={{textAlign:"right"}}>
                       {/* <Link to={createData()}> Add Meeting </Link> */}
                   </div>
                   <div className="tableContainer" style={{height:"474px"}}>
                       <div id="tableContainer2" className="container-tb" style={{height:"545px"}}>
                       <div style={{textAlign:"right"}}>
                           {/* <Link to="/teacherragistration"> Teacher Ragistration </Link> */}
                           {/* {alert(process.env.REACT_APP_REDIRECT_URL)} */}
                          
                           {/* <br></br>
                           <Link to={RefreshToken()}>
                              Refresh Token
                           </Link>  */}
                           
                       </div>
                       <table  id="myTable">
                               <thead>
                                     <th>Sr.</th>  
                                     <th>Title</th> 
                                     <th>Agenda</th> 
                                     <th>Date</th> 
                                      <th>Action</th>
                               </thead>
                             
                              <tbody>
                              {isLoading ? (
                                  <h1>Loading...</h1>
                              ) : (
                                                           
                                 data.map((item,index) => (
                                     <tr key={item.id}>
                                         <td> </td>
                                         <td>{item.topic}</td>
                                         <td>{item.agenda}</td>
                                         <td>{item.start_time}</td>
                                         <td> <NavLink to={`/ZoomMeetingUrl/${item.id}`} >Meeting Url</NavLink></td>
                                     </tr>
                                    ))
                          ) }
                              </tbody>
                              
                          </table>
                      </div>
                  </div>
                     
                </>
      )
  }

export default ZoomMeetingList;
