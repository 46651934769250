import React,{useState,useEffect} from 'react'
import '../../css/admin/detailsTableStyles.css';
import Moment from 'moment';
import axios from 'axios';
const api = axios.create({
    // baseURL: '/CategoryMaster'
    baseURL: process.env.REACT_APP_API_URL,
})
const collapseviewBox1 = () => {
    document.querySelector('.smalldesc').classList.toggle('expand');
    document.querySelector('#changeBtn').classList.toggle('fa-plus');
}
const DetalsZoomInTable = (props) => {
    const [detalsZoomInTable,setdetalsZoomInTable] = new useState([]);
    useEffect( async () => {
        api.get(`/${props.api}`).then(res => setdetalsZoomInTable(res.data));
    }, [])
      
  return (
    <>
        <div className="DetalsZoomInTable_container">
            <div className="row">
                <div className="col-md-12">
                <div className="box">
                    <div className="box-header with-border">
                    <h3 className="box-title">Latest {props.flag}</h3>
                    <div className="box-tools pull-right">
                    <span className="label label-danger bg-red text-white">{detalsZoomInTable.length} New  {props.flag} {Moment(new Date()).format('MMM / YYYY')}</span>
                        <button className="btn btn-box-tool" onClick={collapseviewBox1} id="collapseviewBox1"><i id="changeBtn" className="fa fa-minus "></i></button>
                    </div>
                    </div> 
                    <div className="box-body">
                        <ul className="users-list clearfix smalldesc">
                        {detalsZoomInTable.map((data) => 
                            <li>
                                <a className="users-list-name" href={data.teacher_PresenterList}> {data.FulName } </a>
                                <span className="users-list-date">{data.Mobile}</span>
                                <span className="users-list-date">{Moment(data.Date).format('DD-MMM-YYYY')}</span>
                            </li>
                        )}
                        </ul>
                    
                                
                    </div> 

                </div> 
                </div> 
            </div> 
        </div>
    </>
  )
}

export default DetalsZoomInTable