import React,{useState,useEffect} from "react";
import { AuthContext } from '../../context/context';
import {Link} from "react-router-dom";
import axios from 'axios';
import Moment from 'moment';
import Opt from './BindOption';
import '../../css/table.css';
import { Button, Modal,Form } from "react-bootstrap";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

const CompleateClassesList= () => {
  const { getuserid,getuserrole } = React.useContext(AuthContext);
    const cnfbtn = {
        color: "white",
        backgroundColor: "orange",
        borderRadius: "0",
        marginTop: "0"
      };


    const [showTR, setShowTR] = useState(false);
    const [objTR, setobjTR] = useState({});
    const TRhandleClose = () => setShowTR(false);
    const TRhandleShow = (obj) =>{setobjTR(obj);  setShowTR(true); }


    const [showTS, setShowTS] = useState(false);
    const [objTS, setobjTS] = useState({});
    const TShandleClose = () => setShowTS(false);
    const TShandleShow = (obj) =>{setobjTS(obj);  setShowTS(true); }
    

        const [data, setdata] = React.useState([]);
        const [studentdata, setstudentdata] = React.useState([]);
        const [isLoading, setIsLoading] = useState(true);

        const inputEventsTR = (event) =>{
          const {name,value} = event.target;
          setobjTR({...objTR, [name]:value});
        
      }
      const onSubmitsTR = (event) =>{
        event.preventDefault();
    
    //    // var StateMaster = categoryData;
         // console.log(objTR);
          api.put('/ConfirmScheduleClassTR',  objTR )
          .then(res =>{ 
              if(res.status=='201')
              setShowTR(false);
              else
              alert(res.status)
         })
         .catch((err) => {
            console.log(err.response);
            alert(err.response.status);
          });
        
        
    }

    const inputEventsTS = (event) =>{
      const {name,value} = event.target;
      setobjTS({...objTS, [name]:value});
    
  }
    const onSubmitsTS = (event) =>{
      event.preventDefault();
  
  //    // var StateMaster = categoryData;
  api.put('/ConfirmScheduleClassTS',  objTS )
  .then(res =>{ 
      if(res.status=='201')
      setShowTS(false);
      else
      alert(res.status)
 })
 .catch((err) => {
    console.log(err.response);
    alert(err.response.status);
  });
     // setShowTS(false);
      
  }
        
        useEffect( async () => {
              var userid = getuserid();
               getTeacherCourse(userid);
            }, [])
            
            const getTeacherCourse =(userid) => {
              //alert(userid)
                api.get(`/TeachercompleateClass/${userid}`).then(res =>
                    {
                       debugger
                        setIsLoading(false)
                      
                        setdata(res.data)
                      
                });
            }

            const getStudentinCourse =(courseid) => {
                // alert(courseid)
                var sdata={};
                api.get(`/CourseStudent/${courseid}`).then(res =>
                    {
                        debugger
                        // setIsLoading(false)
                        
                         //setstudentdata(res.data)
                        res.data.forEach(element => {
                            sdata.value= element.SName._id;
                            sdata.text= element.SName.FirstName +' '+element.SName.LastName;
                        });
                      
                });
                return sdata;
            }
        return (
                  <>
                        <div className="tableFixHead">
                        <table className="table" style={{fontFamily: 'sans-serif',fontSize: '12px'}}>
                               <thead>					
                                     <th>Sr.</th>  
                                     <th>Student Name</th>
                                     <th>Course Name</th>
                                     <th>Class Id</th>  
                                     <th>Recording</th> 
                                     <th>Class Date</th> 
                                     <th>Start</th> 
                                     <th>End</th> 
                                     <th>Class</th> 
                                     <th>Subject</th> 
                                     <th> Teacher Remarks</th> 	
                                     <th>Class Status</th> 
                               </thead>
                             
                              <tbody>
                              {isLoading ? (
                                  <h1>Loading...</h1>
                              ) : (
                                                           
                                 data.map((item,index) => (
                                  // console.log(item._id)
                                     <tr key={item._id}>
                                         <td></td>
                                         <td>
                                             
                                           <Opt api="CourseStudent" id={item.CourseId._id } ></Opt>
                                            {/* <select name="student"  className="form-control  form-select">
                                             {[getStudentinCourse(item.CourseId._id)].forEach((opt,index) => {
                                            
                                             debugger
                                            console.log(Object.getPrototypeOf(opt))
                                            // <option key={option.value} value={index.toString()}>{alert(option.text)}</option>
                                             })}
                                        </select> */}
                                        </td>
                                         <td>{item.CourseId.Code}</td>
                                         <td>{item._id}</td>
                                         <td></td>
                                         <td>{item.ClassDate}</td>
                                         <td>{item.ClassStart}</td>
                                         <td>{item.ClassEnd}</td>
                                         <td>{item.ClassId.Class}</td>
                                         <td>{item.SubjectId.Subject}</td>
                                         <td>
                                       { (item.teacherClsDescription==null || item.teacherClsDescription=="") 
                                           ? 
                                           <span onClick={() => TRhandleShow({_id:item._id,Class:item.ClassId.Class,Subject:item.SubjectId.Subject})}  style={{color:'red',cursor:'pointer',fontWeight:"bold"}}>Give Remarks</span>
                                           : 
                                           <span style={{color:"green"}}>{item.teacherClsTopic} : {item.teacherClsDescription}</span> 
                                        }
                                         </td>
                                         <td> 
                                         {
                                         (item.Status==null || item.Status=="") ? 
                                          <button className="btn btn-danger confrom_class_deone" onClick={() => TShandleShow({_id:item._id})} style={cnfbtn} > CLASS CNF </button> 
                                      // alert('null')
                                        : 
                                         ((item.Status==true) ? 
                                         <button className="btn btn-danger confrom_class_deone" style={ {backgroundColor:"green",marginTop:0,borderRadius:0,color:'white'} } > <i className="fa fa-check"></i> Done</button> 
                                         : 
                                         <button className="btn btn-danger confrom_class_deone" style={ {backgroundColor:"red",marginTop:0,borderRadius:0,color:'white'} } > <i className="fa fa-window-close"></i> Cancel</button> )  }
                                           
                                           
                                           </td>
                                     </tr>
                                    ))
                          ) }
                              </tbody>
                              
                          </table>
                      </div>
                  

        <Modal show={showTR} onHide={TRhandleClose} animation={false} >
        <Modal.Header closeButton >
          <Modal.Title>{'['+objTR.Class+']'}{'['+objTR.Subject+']'}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "14px"}}>
          <div className="cltopic">
            <label htmlFor="clsTopic" style={{color:'#899197'}}>Class Topic</label>
            <input type="text" name="teacherClsTopic" onChange={inputEventsTR} className="form-control" />
          </div>
          <div className="clsdescription">
            <label htmlFor="clsDescription" style={{color:'#899197'}}>Class Description</label>
            <textarea type="text" name="teacherClsDescription" onChange={inputEventsTR}  className="form-control" > </textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={TRhandleClose} style={{ marginTop: "0"}}>
            Close
          </Button>
          <Button variant="primary" onClick={onSubmitsTR} style={{ marginTop: "0"}}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showTS} onHide={TShandleClose} animation={false} >
        <Modal.Header closeButton >
          <Modal.Title>CLASS CONFORMATION</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "14px"}}>
          <div className="ExtDuration">
            <label htmlFor="ExtDuration" style={{color:'#899197'}}>Class Duration (In Minutes)</label>
            <input type="number" name="ExtDuration" onChange={inputEventsTS} className="form-control" />
          </div>
          <div className="Status">
            <label htmlFor="Status" style={{color:'#899197'}}>Class Status</label>
            <select  name="Status" onChange={inputEventsTS}  className="form-control form-select" >
              <option value="">Select Class Status</option>
              <option value="1">Class Done</option>
              <option value="0">Class Cancel</option>
           </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={TShandleClose} style={{ marginTop: "0"}}>
            Close
          </Button>
          <Button variant="primary" onClick={onSubmitsTS} style={{ marginTop: "0"}}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>



                </>
      )
  }

export default CompleateClassesList;
