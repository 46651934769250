import React,{useState,useEffect} from "react";
import {Link} from "react-router-dom";
import { AuthContext } from '../../context/context';
import axios from 'axios';
const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  })

const BlogList= () => {
    const [data, setdata] = React.useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { getuserid } = React.useContext(AuthContext);
    useEffect( async () => {
            var userid = getuserid();
            getData(userid)
        }, [])
        
        const getData =(userid) => {
            api.get(`/Blog`).then(res =>
                {
                    debugger
                    setIsLoading(false)
                    //console.log(res.data)
                    setdata(res.data)
                  
            });
        }
        return (
                <>
                   <div style={{textAlign:"right"}}><Link to="/Blog"> Add Blog </Link></div>
                   <div className="tableContainer" style={{height:"474px"}}>
                       <div id="tableContainer2" className="container-tb" style={{height:"545px"}}>
                       <table  id="myTable">
                               <thead>
                                     <th>Sr.</th>  
                                     <th>Heading</th> 
                                     <th>Customised Title</th> 
                                     <th>Image</th> 
                                     <th>Content</th> 
                                     <th>Status</th> 
                                     <th>Action</th> 
                               </thead>
                             
                              <tbody>
                              {isLoading ? (
                                  <h1>Loading...</h1>
                              ) : (
                                                           
                                 data.map((item,index) => (
                                  // console.log(item._id)
                                     <tr key={item._id}>
                                       
                                         <td> </td>
                                         <td>{item.Heading}</td>
                                         <td>{item.CustomisedTitle}</td>
                                         <td><img height="100px" width="150px" src={item.Image} alt="aa" ></img></td>
                                         <td dangerouslySetInnerHTML={{__html:item.Content}}></td>
                                         <td>{item.Status}</td>
                                         <td></td>
                                     </tr>
                                    ))
                          ) }
                              </tbody>
                              
                          </table>
                      </div>
                  </div>
                     
                </>
      )
  }

export default BlogList;
