import React from "react";
import {Link} from "react-router-dom";
import ShowTable from './Table';


const FacultyMasterList= () => {

        return (
                <>
                   <div style={{textAlign:"right"}}><Link to="/facultymaster"> Add Faculty </Link></div>
                      <ShowTable flag='faculty' Linkbtn={[{btnText:"",className:"fa fa-fw fa-edit text-green",flag:""}]} api='facultymasterlist' />
                     
                </>
      )
  }

export default FacultyMasterList;
