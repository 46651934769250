import React,{useState,useEffect} from "react";
import {Link,useNavigate} from "react-router-dom";
import axios from 'axios';
import "./css_files/category_style.css"

const api = axios.create({
    //baseURL: '/subjectmaster'
    baseURL: process.env.REACT_APP_API_URL,
})

const SubjectMaster = () => {
    // const options = [
    //     { label: 'Fruit', value: 'fruit' },
    //     { label: 'Vegetable', value: 'vegetable' },
    //     { label: 'Meat', value: 'meat' },
    //   ];
    const [SubjectData,setSubjectData] = new useState({
        Class: "",
        Code: "",
        Description: "",
        Date: new Date(),
        Status: true,
        Category:"",
    });
    const [data, setdata] = React.useState([]);
    const [catoptions, setcatoptions] = React.useState([]);
    const [clsoptions, setclsoptions] = React.useState([]);

    useEffect( async () => {
        api.get('/classmaster').then(res =>{
            const newArrayy = Object.values(res.data.map(obj => ({...obj.Category})).reduce((acc,cur)=>Object.assign(acc,{[cur._id]:cur}),{}));
            setcatoptions(newArrayy)
            setdata(res.data)
            console.log(res.data)
        });
    }, [])

    const inputEvents = (event) =>{
        // const name = event.target.name;
        // const value = event.target.value;
        const {name,value} = event.target;
        if(name==='Category'){
            var newArray = data.filter(function(item)
                            {
                              debugger
                            return  item.Category._id==value;
                           // return conditional_statement;
                            });
                            const newArrayy = Object.values(newArray.map(obj => ({...obj})).reduce((acc,cur)=>Object.assign(acc,{[cur._id]:cur}),{}));
                              setclsoptions(newArrayy)
                            // newArray.map((item) =>(
                            //     setclsoptions(...clsoptions,[item.Class])
                            // )) 
           // console.log(catoptions.find({Class:1}));
            // const catoptionsval = catoptions.filter({Category:})
            // alert(value)
        }
        
        // console.log(name+'-'+value);
        setSubjectData({...SubjectData, [name]:value});
        
        //  setCategoryData((preValue) => {
        // return{
        //        ...preValue,
        //         [name]:value,
        //     }
        // });
       
    }

  

    const navigate = useNavigate();
    const onSubmits = (event) =>{
        event.preventDefault();
    //alert()
    //    // var BoardMaster = categoryData;
          console.log(SubjectData);
          api.post('/subjectmaster',  SubjectData )
          .then(res =>{ 
              if(res.status=='201')
              navigate('/subjectmasterList');
              else
              alert(res.status)
         })
         .catch((err) => {
            console.log(err.response);
            alert(err.response.status);
          });
        
        ;
    
         // navigate('/boardmasterList');

    }
  
  

    return(
        <>
            {/* {alert(props.name)} */}
            <div className="container-fluid cat-display" style={{height:"518px"}}>
                <div className="col-md-6 cat-main">
                    <form control="" className="form-group customformGroup" onSubmit={onSubmits}>
                        <div className="col-sm-12 col-md-12">
                             <label htmlFor="Category">Category</label>
                            <select name="Category" value={SubjectData.Category} onChange={inputEvents} className="form-control  form-select">
                            <option value=''>Select Category</option>
                            {catoptions.map((option) => (
                               // console.log(option.Category)
                                 <option key={option._id} value={option._id}>{option.Code} | {option.Category}</option>
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-12 col-md-12">
                             <label htmlFor="Class">Class</label>
                            <select name="Class" value={SubjectData.Class} onChange={inputEvents} className="form-control  form-select">
                            <option value=''>Select Class</option>
                            {clsoptions.map((option) => (
                               // console.log(option)
                                 <option key={option._id} value={option._id}>{option.Code} | {option.Class}</option>
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-12 col-md-12">
                            <label htmlFor="Class">Subject</label>
                            <input type="text" name="Subject"  onChange={inputEvents} value={SubjectData.Subject} className="form-control" placeholder="Enter Subject Here"/>
                        </div>
                        <div className="col-sm-12 col-md-12">
                            <label htmlFor="Code">Code</label>
                            <input type="text" name="Code"  onChange={inputEvents} value={SubjectData.Code} className="form-control" placeholder="Enter Subject Code Here"/>
                        </div>
                        <div className="col-sm-12 col-md-12">
                            <label htmlFor="Description">Description</label>
                            <input type="text" name="Description"  onChange={inputEvents} value={SubjectData.Description} className="form-control" placeholder="Enter Description Here"/>
                        </div>
                        {/* row */}
                        <div className=""> 
                            <hr />
                            <div className="col-sm-12 col-md-6 col-lg-6 pull-right">
                                <Link to="/subjectmasterList"  className="btn pull-right"> Go Back </Link>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 pull-right">
                                <input type="submit" value="Submit" className="btn pull-right"/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
export default SubjectMaster;