import React from "react";
import {Link} from "react-router-dom";
import ShowTable from './Table';


const StateMasterList= () => {

        return (
                <>
                   <div style={{textAlign:"right"}}><Link to="/statemaster"> Add State </Link></div>
                      <ShowTable flag='state' Linkbtn={[{btnText:"",className:"fa fa-fw fa-edit text-green",flag:""}]} api='statemaster' />
                     
                </>
      )
  }

export default StateMasterList;
