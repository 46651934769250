import React,{useState,useEffect} from "react";
 import './css_files/tableStyle.css';
import axios from 'axios';
import { Link, NavLink} from "react-router-dom";
import Moment from 'moment';

// import Course from "./Course";
// import { Button } from 'react-bootstrap';
// import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
//,Button

// function button(){
//   return(
//     <Button className="btn btn-info">submit</Button>
//   )
// }

const Table = (props) => {

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    })


           const [isLoading, setIsLoading] = useState(true);
           const [data, setData] = useState([]);

           const getdata = async() => {
            api.get(`/${props.api}`).then(res => {
              debugger
              try {
              (!res.data.result) ? 
               setData(res.data)
               : 
              setData(res.data.result);
              setIsLoading(false)
            } catch (error) {
              console.log(res.data)
              setData(res.data)
              setIsLoading(false)
            } finally {
              setIsLoading(false)
            }
            });
            
           }
           
          useEffect(() => {
            getdata();
            }, []);

          // const getEditData = (val) => {
          //   //e.preventDefault();
          

          //  }

        return (
                      <>
                       <div className="tableContainer">
                       <div id="tableContainer2" className="container-tb">
                             <table  id="myTable">
                               <thead>
                               {data.map((item,i) => (
                                   (i===0)?
                                  <tr className="header" key={item.id}>
                                    <th>#</th>
                                    <th colSpan={ props.Linkbtn.length}>Action</th>
                                    {
                                    Object.keys(item).map((val,i) => (
                                      
                                    <th>{val}</th>
                                    ))
                                    }
                                </tr>:''
                               ))}
                                  
                               </thead>
                             
                              <tbody>
                              {isLoading ? (
                                  <h1>Loading...</h1>
                              ) : (
                                                           
                                 data.map((item,i) => (
                                 
                                    <tr key={item.id}>
                                       
                                        <td>  </td>
                                        
                                        { Object.values(item).map((val,index) => (
                                       
                                       (typeof val === 'object')?
                                      null
                                       :
                                       (typeof val === 'object')?null
                                        :
                                            (index===0)? 
                                                  props.Linkbtn.map((itemm) => (
                                                    <td>
                                                    <NavLink to={`/course/${itemm.flag}/${val}`} ><i className={itemm.className} ></i></NavLink>
                                                    </td>
                                                  ))
                                            
                                                  :''
                                              

                                              ))}
                                       
                                                                           
                                        {
                                         Object.values(item).map((val,i) => (
                                         (typeof val === 'object')?
                                         
                                          (val!=null) ? Object.values(val).map((val1,i) => (
                                              
                                              (typeof val1 === 'object')?
                                                Object.values(val1).map((val2,i) => (
                                                  <td>{val2}</td> 
                                                ))
                                              :
                                             
                                               (i<=0)?'':
                                                  <td>{(Object.keys(item)[i]==='Date' || Object.keys(item)[i]==='date') ? Moment(val1).format('DD-MMM-YYYY') : val1}</td> 
                                                                                     
                                             ))
                                             :
                                             <td></td>
                                        :
                                        
                                         <td>{(Object.keys(item)[i]==='Date' || Object.keys(item)[i]==='date') ? Moment(val).format('DD-MMM-YYYY') : val}</td>
                                         ))
                                         }
                                        
                                    </tr>
                                    ))
                          ) }
                              </tbody>
                              
                          </table>
                      </div>
                  </div>
                  </>
      )
  }

export default Table;
