import React,{useState,useEffect} from 'react'
import DetailTable1 from './DetailTable1'
import DetalsZoomInTable from './DetalsZoomInTable'
import HighCharts from './highcharts';
import './css_files/dashboard.css';
import axios from 'axios';
const api = axios.create({
    // baseURL: '/CategoryMaster'
    baseURL: process.env.REACT_APP_API_URL,
})
const Dhashboard = () => {
  

    // const detailsArray = [{'Total':'532'},{'Email_Confirm':'6464'},{'Enrollled_In_Courses':'85858'},{'Active_student':'84747'}];
    const detalsZoomInTable = [
        {'name':'Najiya Thasneem',
        'teacher_PresenterList':'https://www.theonlinegurukul.com/admin/master/Presenter/view/1711',
        'phone':'9810313314',
        'date':'8-May-2022'},
        {'name':'Najiya Thasneem',
        'teacher_PresenterList':'https://www.theonlinegurukul.com/admin/master/Presenter/view/1711',
        'phone':'9810313314',
        'date':'8-May-2022'},
        {'name':'Najiya Thasneem',
        'teacher_PresenterList':'https://www.theonlinegurukul.com/admin/master/Presenter/view/1711',
        'phone':'9810313314',
        'date':'8-May-2022'},
        {'name':'Najiya Thasneem',
        'teacher_PresenterList':'https://www.theonlinegurukul.com/admin/master/Presenter/view/1711',
        'phone':'9810313314',
        'date':'8-May-2022'},
        {'name':'Najiya Thasneem',
        'teacher_PresenterList':'https://www.theonlinegurukul.com/admin/master/Presenter/view/1711',
        'phone':'9810313314',
        'date':'8-May-2022'},
        {'name':'Najiya Thasneem',
        'teacher_PresenterList':'https://www.theonlinegurukul.com/admin/master/Presenter/view/1711',
        'phone':'9810313314',
        'date':'8-May-2022'},
    ]
  return (
    <>  
    <br />
    <br />
        <div className="adminDashboardMain_continer">
            <div className="container">
                <div className="detail_tables_container">
                    <div className="row">
                        <DetailTable1 heading="Student" api="userinfocount/Student"  fontawesomeClassName="fa fa-users student_ios text-white"  backcolor="info-box-icon bg-red"/> 
                        <DetailTable1 heading="Teacher" api="userinfocountTeacher/Teacher"  fontawesomeClassName="fa fa-users student_ios text-white"  backcolor="info-box-icon bg-green"/> 
                    </div>
                </div>
                <HighCharts/>
                <DetalsZoomInTable detalsZoomInTable={detalsZoomInTable} api="GetUserinfoCurrentMonth/Teacher" flag="Teachers"/>
                <DetalsZoomInTable detalsZoomInTable={detalsZoomInTable} api="GetUserinfoCurrentMonth/Student" flag="Students"/>
                <DetalsZoomInTable detalsZoomInTable={detalsZoomInTable} api="GetDemoRequestCurrentMonth" flag="Demo Request"/>
            </div>
        </div>  
        <br />
    <br /> 
    </>
  )
}

export default Dhashboard


// const styles = StyleSheet.create({

//     item: {
//         width: '100px',
//         padding: '10px',
//         display: 'inline-block',
//         verticalalign: 'middle'
//       }
// })