import React from "react";
import {Link} from "react-router-dom";
import ShowTable from './Table';


const CountryMasterList= () => {

        return (
                <>
                   <div style={{textAlign:"right"}}><Link to="/countrymaster"> Add Country </Link></div>
                      <ShowTable api='countrymaster' flag='country' Linkbtn={[{btnText:"",className:"fa fa-fw fa-edit text-green",flag:""}]}/>
                     
                </>
      )
  }

export default CountryMasterList;
