import React,{useState,useEffect} from "react";
import {Link,useNavigate,useParams} from "react-router-dom";
import axios from 'axios';
import "./admin/css_files/category_style.css";
// import "./css_files/category_style.css"

const api = axios.create({
    // baseURL: '/ConfirmScheduleClass'
    baseURL: process.env.REACT_APP_API_URL,
})



 


const EmailVerification = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    useEffect( async () => {
        
         EmailVerificationcode(id)
      }, [])
      const EmailVerificationcode = (idd) => {
        //alert(time)
        api.get('/userinfo/a/b/'+idd).then(res =>
            {
                //console.log(res.data)
                //console.log(res.data._id)
               
                if(res.data.EmailConfirmed===false){
                    res.data.EmailConfirmed=true;
                    api.patch('/userinfo/'+idd,res.data)
                        .then(res =>{ 
                            if(res.status=='200'){
                            alert("Email Verification Done");
                            navigate('/login');
                            }else
                            alert(res.status)
                       })
                }
                else{
                    alert("Email Verification Already Done");
                    navigate('/login');
                }
               //  setIsLoading(false)
               // setdata(res.data)
              // console.log(res.data)
        });
    }

    return (
        <>
            <div></div>
        </>
    );


}
 

export default EmailVerification;
