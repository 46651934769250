import React from 'react'
import  {SelectOption, InputList } from './Forms';

const TeacherBasicInfo = (props) => {
   
  return (
    <>
        <section>
            <div className="stFormGroup">
                <div className="col-12 text-center">
                    <h2 style={{color:"orange"}}> Registration For Teacher </h2>
                </div>
                <hr />
                    <div className="row">
                        <InputList classnames="col-md-3 col-lg-3 col-sm-6 "  name="FirstName"  changeEvent={props.inputEvents} value={props.userinfoData.FirstName} lable="First name" placeholder="Please Enter First Name" />
                    
                        <InputList classnames="col-md-3 col-lg-3 col-sm-6 " name="LastName"  changeEvent={props.inputEvents} value={props.userinfoData.LastName}  lable="Last name" placeholder="Please Enter Last Name"  />
                        
                        <InputList classnames="col-md-3 col-lg-3 col-sm-6 " name="Email"  changeEvent={props.inputEvents} value={props.userinfoData.Email}  lable="Email" placeholder="example@gmail.com"  />

                        <SelectOption classnames="col-md-3 col-lg-3 col-sm-6 " name="Gender"  changeEvent={props.inputEvents} value={props.userinfoData.Gender}  lable="Gender" option={[{name:'Male',value:'male'},{name:'Female',value:'female'},{name:'Other',value:'other'}]} selectValue="Gender" />
                        
                        <InputList classnames="col-md-3 col-lg-3 col-sm-6 "  name="Mobile"  changeEvent={props.inputEvents} value={props.userinfoData.Mobile}  lable="Mobile" inputtype="number" placeholder="Please Enter Mobile" />

                        <InputList classnames="col-md-3 col-lg-3 col-sm-6 "  name="DOB"  changeEvent={props.inputEvents} value={props.userinfoData.DOB}  lable="Date of Birth" inputtype="date" placeholder="Please Select DOB" />

                        <SelectOption classnames="col-md-3 col-lg-3 col-sm-6 "  name="Country"  changeEvent={props.inputEvents} value={props.userinfoData.Country}  lable="Country" option={[{name:'AL | ALBANIA',value:'AL | ALBANIA'},{name:'AF | india',value:'AF | india'},{name:'AL | AFGHANISTAN',value:'AL | AFGHANISTAN'}]} selectValue="Country" />
                        
                        <SelectOption classnames="col-md-3 col-lg-3 col-sm-6 "  name="State"  changeEvent={props.inputEvents} value={props.userinfoData.State}  lable="State" option={[{name:'Assam',value:'Assam'},{name:'Assam',value:'Assam'},{name:'Bihar',value:'Bihar'}]} selectValue="State" />
                        
                        <SelectOption classnames="col-md-3 col-lg-3 col-sm-6 " name="City"  changeEvent={props.inputEvents} value={props.userinfoData.City}  lable="City" option={[{name:'Assam',value:'Assam'},{name:'Assam',value:'Assam'},{name:'Bihar',value:'Bihar'}]} selectValue="City" />

                        <InputList classnames="col-md-3 col-lg-3 col-sm-6 "  name="ZipCode"  changeEvent={props.inputEvents} value={props.userinfoData.ZipCode}  lable="ZipCode" placeholder="Please Enter ZipCode" />
                        
                        <InputList classnames="col-md-3 col-lg-3 col-sm-6 " name="Aadhar" changeEvent={props.inputEvents} value={props.userinfoData.Aadhar}  lable="Aadhar id" placeholder="Please Enter Aadhar id" />
                        
                        <InputList classnames="col-md-3 col-lg-3 col-sm-6 " name="PANNO" changeEvent={props.inputEvents} value={props.userinfoData.PANNO}  lable="PAN NO." placeholder="Please Enter Pan no." />

                        <SelectOption classnames="col-md-3 col-lg-3 col-sm-6 " name="EmailConfirmed" changeEvent={props.inputEvents} value={props.userinfoData.EmailConfirmed}  lable="Email Verfied" option={[{name:'Verfied',value:true},{name:'Unverified',value:false}]} selectValue="false" />
                        
                        <SelectOption classnames="col-md-3 col-lg-3 col-sm-6 " name="AccountConfirmed" changeEvent={props.inputEvents} value={props.userinfoData.AccountConfirmed}  lable="Account Verfied" option={[{name:'Verfied',value:true},{name:'Unverified',value:false}]} selectValue="false" />
                        
                    </div>
            </div>
        </section>
    </>
  )
}

export default TeacherBasicInfo;
