import React from "react";
import {Link} from "react-router-dom";
import ShowTable from './Table';


const StudentRagistrationList= () => {

        return (
                <>
                   <div style={{textAlign:"right"}}><Link to="/studentragistration"> Student Ragistration </Link></div>
                      <ShowTable flag='student' Linkbtn={[{btnText:"",className:"fa fa-fw fa-edit text-green",flag:""}]} api='userinfo/Student' />
                     
                </>
      )
  }

export default StudentRagistrationList;
