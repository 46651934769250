import React,{useState,useEffect} from "react";
import {Link,useNavigate} from "react-router-dom";
import axios from 'axios';
import "./css_files/category_style.css"

const api = axios.create({
    //baseURL: '/statemaster'
    baseURL: process.env.REACT_APP_API_URL,
})



const StateMaster = () => {
    const [stateData,setstateData] = new useState({
        Country: "",
        State: "",
        Date: new Date(),
        Status: true,
    });
    const [options, setoptions] = React.useState([]);

        useEffect( async () => {
            api.get('/countrymaster').then(res => setoptions(res.data));
        }, [])

    
    // useEffect( async () => {
    //     api.get('/').then(res => console.log(res.data));
    // }, [])

    const inputEvents = (event) =>{
        // const name = event.target.name;
        // const value = event.target.value;
        const {name,value} = event.target;

        setstateData({...stateData, [name]:value});
        
        //  setCategoryData((preValue) => {
        // return{
        //        ...preValue,
        //         [name]:value,
        //     }
        // });
       
    }
    const navigate = useNavigate();
    const onSubmits = (event) =>{
        event.preventDefault();
    
    //    // var StateMaster = categoryData;
          console.log(stateData);
          api.post('/statemaster',  stateData )
          .then(res =>{ 
              if(res.status=='201')
              navigate('/statemasterList');
              else
              alert(res.status)
         })
         .catch((err) => {
            console.log(err.response);
            alert(err.response.status);
          });
        
        ;
    
         // navigate('/boardmasterList');

    }
  
  

    return(
        <>
            {/* {alert(props.name)} */}
            <div className="container-fluid cat-display" style={{height:"518px"}}>
                <div className="col-md-6 cat-main">
                <div className="col-sm-12 col-md-12">
                             <label htmlFor="Country">Country</label>
                            <select name="Country" value={stateData.Country} onChange={inputEvents} className="form-control  form-select">
                            <option value=''>Select Country</option>
                            {options.map((option) => (
                                <option key={option._id} value={option._id}>{option.Country}</option>
                            ))}
                            </select>
                      
                        </div>

                    <form control="" className="form-group customformGroup" onSubmit={onSubmits}>
                        <div className="col-sm-12 col-md-12">
                            <label htmlFor="State">State</label>
                            <input type="text" name="State"  onChange={inputEvents} value={stateData.State} className="form-control" placeholder="Enter State Here"/>
                        </div>
                        {/* row */}
                        <div className=""> 
                            <hr />
                            <div className="col-sm-12 col-md-6 col-lg-6 pull-right">
                                <Link to="/statemasterList"  className="btn pull-right"> Go Back </Link>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 pull-right">
                                <input type="submit" value="Submit" className="btn pull-right"/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
export default StateMaster;