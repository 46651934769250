import React from "react";
import {Link} from "react-router-dom";
import ShowTable from './Table';


const SubjectMasterList= () => {

        return (
                <>
                   <div style={{textAlign:"right"}}><Link to="/subjectmaster"> Add Subject </Link></div>
                      <ShowTable flag='subject' Linkbtn={[{btnText:"",className:"fa fa-fw fa-edit text-green",flag:""}]} api='subjectmasterlist' />
                     
                </>
      )
  }

export default SubjectMasterList;
