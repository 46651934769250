import React, { useState } from 'react';
import SelectOption, { Formtitle, InputList } from './Forms';

const PreferredClassesSubjects = (props)  => {
    var converter = require('number-to-words');
    const [formValues, setFormValues] = useState([{ qualification: "", universityIns : "", yoPassing: "", percentage: ""}]);
    const [qualificationCount, setQualificationCount] = useState(1);

    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
      }
    
    let addFormFields = () => {
        // event.stopPropagation();
        if(qualificationCount <= 10){
            setFormValues([...formValues, { qualification: "", universityIns : "", yoPassing: "", percentage: ""}])
            setQualificationCount(qualificationCount+1);
        }else{
            alert("You can not add more Qualification");
        }
      }
    
    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
        // i.stopPropagation();
    }
    
    
    return (
        <>
            <section className="qualification-container">
                <div className="stFormGroup">
                    <Formtitle title="PREFERRED CLASSES & SUBJECTS" clickEvent={() => addFormFields()} display="d-block" />
                    {formValues.map((element, index) => (
                        <div className="row pt-2" key={index}> 
                            <SelectOption classnames="col-md-3 col-lg-3 col-sm-6 pb-2" name="Class" lable="Class" option={['z','b','c','d2','e','f','g']} value={element.qualification || ""} changeEvent={e => handleChange(index, e)} selectValue={converter.toWordsOrdinal(index+1)+' Qualification'} />
                            <SelectOption classnames="col-md-3 col-lg-3 col-sm-6 pb-2" name="primary-subject" lable="Primary Subject" option={['zw','be','c','de','ex','f','gw']} value={element.qualification || ""} changeEvent={e => handleChange(index, e)} selectValue="Primary Subject" />
                            <SelectOption classnames="col-md-3 col-lg-3 col-sm-6 pb-2" name="secondary-subject" lable="Secondary Subject" option={['zd','be','c','d','e','fw','gs']} value={element.qualification || ""} changeEvent={e => handleChange(index, e)} selectValue="Secondary Subject" />
                            <InputList classnames="col-md-2 col-lg-2 col-sm-5 pb-2" name="other" lable="Others"  value={element.percentage || ""} changeEvent={e => handleChange(index, e)} placeholder="Others" />

                            <div className="col-1 pull-right">
                                <p  style={{visibility :"hidden"}} >Acn</p>
                                { index ?  
                                    <button type="button"  className="button remove" onClick={() => removeFormFields(index)}><i className="fa fa-window-close text-red" > </i></button>
                                : <i className="fa fa-check text-green" > </i>
                                }
                            </div>
                        </div>
                    ))}
                </div>
           </section>
        </>
    )

}
    
export default PreferredClassesSubjects
