import React from "react";
import {Link} from "react-router-dom";
import ShowTable from './Table';


const CourseList= () => {

        return (
                <>
                   <div style={{textAlign:"right"}}><Link to="/course"> Add Course </Link></div>
                      <ShowTable  api='coursemaster' flag='courseList'  Linkbtn={[{btnText:"",className:"fa fa-fw fa-calendar text-green",flag:"addschedular"},{btnText:"Add Student in Course",className:"fa fa-fw fa-user-plus text-orange",flag:"addstudent"}]}  />
                     
                </>
      )
  }

export default CourseList;
