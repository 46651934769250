import React from 'react';
import PropTypes from 'prop-types';

const Formtitle = (props) => {
  return (
    <>  
      <div className= {"col-12 p-2" + props.padding} style={{backgroundImage:"linear-gradient(to bottom, #00c0ef, #3c8dbc)"}}>
        <div className="row">
            <div className="col-9">
            <h2 className="text-white">{props.title}</h2>
            </div>
            <div className="col-3 ">
            <button title="Add Row" type="button"  className={props.display +" add pull-right"} onClick={props.clickEvent}> <i className="fa fa-plus-square"></i></button>
            </div>
        </div>
      </div> 
    </>
  )
}

const Formtitlewb = (props) => {
  return (
    <>  
      <div className= {"col-12 p-2" + props.padding} style={{backgroundImage:"linear-gradient(to bottom, #00c0ef, #3c8dbc)"}}>
        <div className="row">
            <div className="col-9">
            <h2 className="text-white">{props.title}</h2>
            </div>
            {/* <div className="col-3 ">
            <button title="Add Row" type="button"  className={props.display +" add pull-right"} onClick={props.clickEvent}> <i className="fa fa-plus-square"></i></button>
            </div> */}
        </div>
      </div> 
    </>
  )
}

const SelectOption = (props) => {
  return (
    <>  
    {/* {console.log(props)} */}
      <div className={props.classnames}>
        <label htmlFor={props.name} className="form-label">{props.lable}</label>
        
        <select className="form-select form-control" name={props.name} value={props.value} onChange={props.changeEvent} id={props.name} >
            <option value="">Select {props.selectValue} </option>
            {props.option.map((element,index) => ( 
                <option  value={element.value}> {element.name} </option>
            ) )}
        </select>
        </div>
    </>
  )
}
const InputList = (props) => {
    return (
      <>  
          <div className={props.classnames}>
            <label htmlFor={props.name} className="form-label">{props.lable}</label>
            <input type={props.inputtype} className="form-control" name={props.name} id={props.name} defaultValue={props.value} onChange={props.changeEvent} placeholder={props.placeholder}  />
          </div>
      </>
    )
  }

  const TextArea = (props) => {
    return (
      <>  
          <div className={props.classnames}>
            <label htmlFor={props.name} className="form-label">{props.lable}</label>
            <textarea className="form-control" name={props.name} id={props.name} rows="2" placeholder={props.placeholder}></textarea>
          </div>
      </>
    )
  }
  
  InputList.propTypes = {
    inputtype: PropTypes.string
  }
  InputList.defaultProps = {
    inputtype: 'text'
  };

export default 'SelectOption'
export  {SelectOption}
export {InputList}
export {Formtitle}
export {TextArea}
export {Formtitlewb}