import React,{useState,useEffect} from "react";
import {Link,useNavigate} from "react-router-dom";
import axios from 'axios';
import "./css_files/category_style.css"

const api = axios.create({
    // baseURL: '/countrymaster'
    baseURL: process.env.REACT_APP_API_URL,
})

const CountryMaster = () => {
    const [countryData,setcountryData] = new useState({
        Country: "",
        Date: new Date(),
        Status: true,
    });
    
    useEffect( async () => {
        api.get('/countrymaster').then(res => console.log(res.data));
    }, [])

    const inputEvents = (event) =>{
        // const name = event.target.name;
        // const value = event.target.value;
        const {name,value} = event.target;

        setcountryData({...countryData, [name]:value});
        
        //  setCategoryData((preValue) => {
        // return{
        //        ...preValue,
        //         [name]:value,
        //     }
        // });
       
    }
    const navigate = useNavigate();
    const onSubmits = (event) =>{
        event.preventDefault();
    
    //    // var CountryMaster = categoryData;
          //console.log(countryData);
          api.post('/countrymaster',  countryData )
          .then(res =>{ 
              if(res.status=='201')
              navigate('/countrymasterList');
              else
              alert(res.status)
         })
         .catch((err) => {
            console.log(err.response);
            alert(err.response.status);
          });
        
        ;
    
         // navigate('/boardmasterList');

    }
  
  

    return(
        <>
            {/* {alert(props.name)} */}
            <div className="container-fluid cat-display" style={{height:"518px"}}>
                <div className="col-md-6 cat-main">
                    <form control="" className="form-group customformGroup" onSubmit={onSubmits}>
                        <div className="col-sm-12 col-md-12">
                            <label htmlFor="Country">Country</label>
                            <input type="text" name="Country"  onChange={inputEvents} value={countryData.Country} className="form-control" placeholder="Enter Country Here"/>
                        </div>
                        {/* row */}
                        <div className=""> 
                            <hr />
                            <div className="col-sm-12 col-md-6 col-lg-6 pull-right">
                                <Link to="/countrymasterList"  className="btn pull-right"> Go Back </Link>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 pull-right">
                                <input type="submit" value="Submit" className="btn pull-right"/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
export default CountryMaster;