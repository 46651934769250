import 'rc-time-picker/assets/index.css';
import React,{useState,useEffect} from "react";
import {Link,useNavigate,useParams} from "react-router-dom";
import TimePicker from  'rc-time-picker';
import axios from 'axios';
import Moment from 'moment';
import "./css_files/category_style.css"
import CourseStudent from "./CourseStudent";
import CourseClass from "./CourseClass";
import  {SelectOption, Formtitle, InputList } from './Forms';
import TextField from '@material-ui/core/TextField';
const api = axios.create({
    //baseURL: '/coursemaster'
    baseURL: process.env.REACT_APP_API_URL,
})

// const apiCourseStudent = axios.create({
//     //baseURL: '/CourseStudent'
//     baseURL: process.env.REACT_APP_API_URL,
// })

const Course = () => {
    const navigate = useNavigate();
    const {id,flag} = useParams();
  // alert(flag)
  const [durationoptions, setdurationoptions] = useState([
    { value:"15", name: "15 Min"},
    { value:"30", name: "30 Min"},
    { value:"45", name: "45 Min"},
    { value:"60", name: "1 Hour"},
    { value:"90", name: "1 Hour 15 Min"},
    { value:"75", name: "1 Hour 30 Min"},
    { value:"105", name: "1 Hour 45 Min"},
    { value:"120", name: "2 Hour"},
    { value:"135", name: "2 Hour 15 Min"},
    { value:"150", name: "2 Hour 30 Min"},
    { value:"165", name: "2 Hour 45 Min"},
   
  ]);
  const [durationselectvalue, setdurationselectvalue] = useState(["","","","","","",""]);
    const [CourseData,setCourseData] = new useState({
        Code: "",
        Duration: "",
        Type: "",
        FeesPerHour: "",
        FeesPerMonth: "",
        FeesFullCourse: "",
        StartDate: new Date(),
        EndDate: new Date(),
        Date: new Date(),
        Description: "",
        Status:false,
        Board: "",
        Category: "",
        Class: "",
        Subject: "",
        Faculty: "",
        CourseClass:[{Day: 'Sunday',checked:false,StartTime:"",Duration:"",Extendduration:"",Remarks:""},
        {Day: 'Monday',checked:false,StartTime:"",Duration:"",Extendduration:"",Remarks:""},
        {Day: 'Tuesday',checked:false,StartTime:"",Duration:"",Extendduration:"",Remarks:""},
        {Day: 'Wednesday',checked:false,StartTime:"",Duration:"",Extendduration:"",Remarks:""},
        {Day: 'Thursday',checked:false,StartTime:"",Duration:"",Extendduration:"",Remarks:""},
        {Day: 'Friday',checked:false,StartTime:"",Duration:"",Extendduration:"",Remarks:""},
        {Day: 'Saturday',checked:false,StartTime:"",Duration:"",Extendduration:"",Remarks:""},]
      
    });

    const [CourseStudentData,setCourseStudentData] = new useState([{
        SName: "",
        SFeesType: "",
        SFees: "",
        TFeesType: "",
        TFees: "",
        Joindate: "",
        Leavedate: "",
        Remarks: "",
        CourseId:  "",
    }]);
    const [subjectcode, setsubjectcode] = React.useState('');
    const [data, setdata] = React.useState([]);
    const [catoptions, setcatoptions] = React.useState([]);
    const [clsoptions, setclsoptions] = React.useState([]);
    const [suboptions, setsuboptions] = React.useState([]);
    const [feestype, setfeestype] = React.useState("Per Hour");
    const [teacheroptions, setteacheroptions] = React.useState([]);
    const [boardoptions, setboardoptions] = React.useState([]);
     const [CourseId, setCourseId] = React.useState(id);
     const [flagg, setflag] = React.useState(flag);
    const [CourseStudentCount, setCourseStudentCount] = useState(1);
    const [CourseClassData, setCourseClassData] = useState([
        {Day: 'Sunday',checked:false,StartTime:"",Duration:"",Extendduration:"",Remarks:""},
        {Day: 'Monday',checked:false,StartTime:"",Duration:"",Extendduration:"",Remarks:""},
        {Day: 'Tuesday',checked:false,StartTime:"",Duration:"",Extendduration:"",Remarks:""},
        {Day: 'Wednesday',checked:false,StartTime:"",Duration:"",Extendduration:"",Remarks:""},
        {Day: 'Thursday',checked:false,StartTime:"",Duration:"",Extendduration:"",Remarks:""},
        {Day: 'Friday',checked:false,StartTime:"",Duration:"",Extendduration:"",Remarks:""},
        {Day: 'Saturday',checked:false,StartTime:"",Duration:"",Extendduration:"",Remarks:""},
     ]);
     const [extenddurationdata, setextenddurationdata] = useState(["","","","","","",""]);
     const format = 'hh:mm a';
     const onChanges = (value,index) => {
         debugger
        //  console.log(value.currentTarget.value)
        // CourseClassData[index].StartTime=value.format(format);
        CourseClassData[index].StartTime=value.currentTarget.value;
        // console.log(value.format(format));
        setCourseClassData(CourseClassData)
      }

      const resduleclassEvents = (event,index) =>{
        
        CourseData.CourseClass=[];
        setCourseClassData([{Day: 'Sunday',checked:false,StartTime:"",Duration:"",Extendduration:"",Remarks:""},
        {Day: 'Monday',checked:false,StartTime:"",Duration:"",Extendduration:"",Remarks:""},
        {Day: 'Tuesday',checked:false,StartTime:"",Duration:"",Extendduration:"",Remarks:""},
        {Day: 'Wednesday',checked:false,StartTime:"",Duration:"",Extendduration:"",Remarks:""},
        {Day: 'Thursday',checked:false,StartTime:"",Duration:"",Extendduration:"",Remarks:""},
        {Day: 'Friday',checked:false,StartTime:"",Duration:"",Extendduration:"",Remarks:""},
        {Day: 'Saturday',checked:false,StartTime:"",Duration:"",Extendduration:"",Remarks:""},])
        console.log(CourseData)
        console.log(CourseClassData)
    //     api.put('/coursemaster', CourseData)
    //     .then(res =>{ 
    //         if(res.status=='201'){
        
    //      }
    //         else
    //         alert(res.status)
    //    })

      }
      const courseclassEvents = (event,index) =>{
       
        const {name,value} = event.target;
    //     // var narr=checkeddata;
    //     // narr[index]=true;
    //     // setcheckeddata(narr)
    //    // console.log(checkeddata)
    //   // alert(value)
    //     if(name==='Monday' || name==='Tuesday' || name==='Wednesday' || name==='Thursday' || name==='Friday' || name==='Saturday' || name==='Sunday'){
    //     if(event.target.checked===true)
    //          CourseClassData[index].checked=true
    //     else
    //          CourseClassData[index].checked=false
         
    //     }

    //          if(name==='Duration')
    //          {
    //            CourseClassData[index].Duration=value;
    //          }
    //         //  else{
    //         //     CourseClassData[index].Duration="";
    //         //  }

    //          if(name==='Extendduration')
    //          {
    //            CourseClassData[index].Extendduration=value;
    //          }
    //          if(name==='Remarks')
    //          {
    //            CourseClassData[index].Remarks=value;
    //          }

    //          setCourseClassData(CourseClassData);
debugger
if(name==='Monday' || name==='Tuesday' || name==='Wednesday' || name==='Thursday' || name==='Friday' || name==='Saturday' || name==='Sunday'){
    if(event.target.checked===true)
         CourseClassData[index].checked=true
    else
         CourseClassData[index].checked=false
}
        let newFormValues = [...CourseClassData];
        newFormValues[index][name] = value;
       // newFormValues[index].CourseId=CourseData._id;
        setCourseClassData(newFormValues);
      
       
   }

    useEffect( async () => {
        // setCourseId(props.CourceId);
        // alert(props.CourceId)
        api.get('/facultymaster').then(res =>{
            // res.data.map((item) =>(
            //     //console.log(item.Category)
            //     setcatoptions(...catoptions,[item.Category])
            // )) 
            const newArrayy = Object.values(res.data.map(obj => ({...obj.Category})).reduce((acc,cur)=>Object.assign(acc,{[cur._id]:cur}),{}));
            setcatoptions(newArrayy)
            // console.log(res.data)
            setdata(res.data)
           // setcatoptions(res.data);
            editCourse(res.data);
        });
        //  axios.get('/userinfo/Student').then(res =>setstudentoptions(res.data));
        api.get('/boardmaster').then(res =>setboardoptions(res.data));

       

    }, [])

    const editCourse = (catoptionss) => {
       debugger
        api.get(`/CourseMaster/${CourseId}`).then(res =>
            {
                // console.log( Moment(res.data.StartDate).format('YYYY-MM-DD'))
                
                setCourseData(res.data);
                //.slice(0,9)
             
             try {
                var newClass = catoptionss.filter(function(item)
                {
                return  item.Category._id==res.data.Category;
                });
                const newArrayy = Object.values(newClass.map(obj => ({...obj.Class})).reduce((acc,cur)=>Object.assign(acc,{[cur._id]:cur}),{}));
                setclsoptions(newArrayy)
                 //setclsoptions([newClass])
                  }
                 catch(err) {
                    console.log(err.message);
                  }
                var newSubject = catoptionss.filter(function(item)
                {
                return  item.Class._id==res.data.Class;
                });
                const newArrayyy = Object.values(newSubject.map(obj => ({...obj.Subject})).reduce((acc,cur)=>Object.assign(acc,{[cur._id]:cur}),{}));
                setsuboptions(newArrayyy)
                //setsuboptions(newSubject)

                var newteacher = catoptionss.filter(function(item)
                        {
                        return  item.Subject._id==res.data.Subject;
                        });
                        const newArrayyyy = Object.values(newteacher.map(obj => ({...obj.Teacher})).reduce((acc,cur)=>Object.assign(acc,{[cur._id]:cur}),{}));
                        setteacheroptions(newArrayyyy)
                        //setteacheroptions(newteacher)
                        //alert(res.data.CourseClass.length);
                        if(flag==="addschedular" && res.data.CourseClass.length>0)
                        {
                           debugger
                            setCourseClassData(...[res.data.CourseClass]);
                            // var narr=[];
                            // res.data.CourseClass.forEach((element,index) => {
                            //     narr[index]=true;
                            // });
                           
                            // setcheckeddata(narr)
                        // console.log(CourseClassData)
                        }

                        if(flag==="addstudent" && res.data.CourseStudent.length>0)
                        {
                           debugger
                           //alert(res.data._id)
                            api.get(`/CourseStudent/${res.data._id}`).then(res =>
                            {
                                setCourseStudentData([...res.data]);
                                // console.log(...[res.data]);
                            });
                        }

                        

            });
    }


    const coursestudentEvents = (event,index) =>{
        const {name,value} = event.target;
        let newFormValues = [...CourseStudentData];
            newFormValues[index][name] = value;
            newFormValues[index].CourseId=CourseData._id;
            setCourseStudentData(newFormValues);
            //console.log(CourseStudentData)

    }
    let addFormFields = () => {
        // event.stopPropagation();
        if(CourseStudentCount <= 10){
            // setFormValues([...formValues, {SName:"", SFeesType: "", SFees : "", TFeesType: "", TFees: "", Joindate:"", Leavedate:"", Remarks:"", CourseId:""}])
            setCourseStudentData([...CourseStudentData, {SName:"", SFeesType: "", SFees : "", TFeesType: "", TFees: "", Joindate:"", Leavedate:"", Remarks:"", CourseId:""}])
            setCourseStudentCount(CourseStudentCount+1);
        }else{
            alert("You can not add more Qualification");
        }
      }
    
    let removeFormFields = (i) => {
        let newFormValues = [...CourseStudentData];
        newFormValues.splice(i, 1);
        setCourseStudentData(newFormValues)
        // i.stopPropagation();
    }
    const inputEvents = (event,index) =>{
       
        // const name = event.target.name;
        // const value = event.target.value;
       
        const {name,value} = event.target;
       
       
        if(name==='Category'){
            var newArray = data.filter(function(item)
                            {
                             // debugger
                            return  item.Category._id==value;
                           // return conditional_statement;
                            });
                            const newArrayy = Object.values(newArray.map(obj => ({...obj.Class})).reduce((acc,cur)=>Object.assign(acc,{[cur._id]:cur}),{}));
                            setclsoptions(newArrayy)
                            //console.log(Object.values(newArray.map(obj => ({...obj.Class})).reduce((acc,cur)=>Object.assign(acc,{[cur._id]:cur}),{})))
                           //console.log(newArrayy)
                            //setclsoptions([...new Set(newArrayy)])
                            // newArray.map((item) =>(
                            //     setclsoptions(...clsoptions,[item.Class])
                            // )) 
          
        }

        if(name==='Class'){
                var newArray = data.filter(function(item)
                            {
                              
                            return  item.Class._id==value;
                           // return conditional_statement;
                            });
                           // var newArrayy = arr.filter(obj => !uniq[obj.id] && (uniq[obj.id] = true));
                             const newArrayy = Object.values(newArray.map(obj => ({...obj.Subject})).reduce((acc,cur)=>Object.assign(acc,{[cur._id]:cur}),{}));
                            setsuboptions(newArrayy);
                            //console.log(newArrayy)
                            //debugger
                           // setsuboptions(newArray)
                        //    const newArrayy = newArray.map(obj => ({...obj.Subject}));
                        //    setsuboptions(newArrayy)
                           
        }
        if(name==='Subject'){
            var newArray = data.filter(function(item)
                        {
                          
                        return  item.Subject._id==value;
                       // return conditional_statement;
                        });
                        const newArrayy = Object.values(newArray.map(obj => ({...obj.Teacher})).reduce((acc,cur)=>Object.assign(acc,{[cur._id]:cur}),{}));
                        setteacheroptions(newArrayy)
                      
                        var index = event.nativeEvent.target.selectedIndex;
                         var text = event.nativeEvent.target[index].text.split('|')[0].trim()
                         setsubjectcode(text)
                            //console.log(text)
                     
    }

        if(name==='Type'){
           // alert(value)
           if(value==="FullCourse")
           setfeestype("Full Course")
           else
            setfeestype("Per "+value)
        }
        
        // console.log(name+'-'+value);
        setCourseData({...CourseData, [name]:value});
       
    }

    // const genratecourse =async () => {

    //     var userid =0;
    //     var finaluserid='';
    //   axios.get('/getcourse').then(res =>
    //         {
    //             debugger
    //             console.log(res.data)
    //              userid =(res.data.length>0) ? parseInt(res.data[0].Code.slice(-5))+1 : 1;
    //              const  d = new Date();
    //     const y = d.getMonth() < 3 ? Moment(d).format('YY') - 1 : Moment(d).format('YY');
    //     var a = Moment(d).format('MMM').substring(0,3);
    //     //alert(subjectcode+a+y+(parseInt(y) + 1))
         
    //      finaluserid=subjectcode+a+y+(parseInt(y) + 1);
    //     if(userid.toString().length==1){finaluserid=finaluserid+"0000"+userid.toString();}
    //     if(userid.toString().length==2){finaluserid=finaluserid+"000"+userid.toString();}
    //     if(userid.toString().length==3){finaluserid=finaluserid+"00"+userid.toString();}
    //     if(userid.toString().length==4){finaluserid=finaluserid+"0"+userid.toString();}
    //     if(userid.toString().length==5){finaluserid=finaluserid+userid.toString();}
    //     return finaluserid;
    //     });
       
    // }
  

   
    const onSubmits = (event) =>{
        event.preventDefault();

        debugger
  
      

          if(flag==="addstudent")
          {
            api.post('/CourseStudent', ...CourseStudentData)
            .then(res =>{ 
                if(res.status=='201')
                navigate('/courseList');
                else
                alert(res.status)
           })
        }
           else if(flag==="addschedular")
           {
            //    if (CourseData.CourseClass.length<=0){
                CourseData.CourseClass=[...CourseClassData];
            //    }
            //    else{
             
            //     CourseData.CourseClass=[...CourseClassData]);
            //    }
          
            console.log(CourseData)
          
            api.put('/coursemaster', CourseData)
             .then(res =>{ 
                 if(res.status=='201')
                 navigate('/courseList');
                 else
                 alert(res.status)
            })
           
            
        }else{
           

            
            api.get('/getcourse').then(res =>
           {
               debugger
                 
                    var  userid =(res.data.length>0) ? parseInt(res.data[0].Code.slice(-5))+1 : 1;
                     const  d = new Date();
            const y = d.getMonth() < 3 ? Moment(d).format('YY') - 1 : Moment(d).format('YY');
            var a = Moment(d).format('MMM').substring(0,3);
            
            var finaluserid=subjectcode+a+y+(parseInt(y) + 1);
            if(userid.toString().length==1){finaluserid=finaluserid+"0000"+userid.toString();}
            if(userid.toString().length==2){finaluserid=finaluserid+"000"+userid.toString();}
            if(userid.toString().length==3){finaluserid=finaluserid+"00"+userid.toString();}
            if(userid.toString().length==4){finaluserid=finaluserid+"0"+userid.toString();}
            if(userid.toString().length==5){finaluserid=finaluserid+userid.toString();}
            setCourseData({...CourseData, 'Code':finaluserid});
            CourseData.Code=finaluserid;
            
            if(userid.toString().length>0){
            api.post('/coursemaster',  CourseData )
            .then(res =>{ 
                if(res.status=='201')
                navigate('/courseList');
                else
                alert(res.status)
           })
           .catch((err) => {
              console.log(err.response);
              alert(err.response.status);
            });
        }

            });
            
       
          }
      
        
        
    
         // navigate('/boardmasterList');

    }
  
    const timeformat = (value) => {
        
        if (value){
             const hour = (value.split(' ')[0].split(':')[0].toString().length>1) ?  value.split(' ')[0].split(':')[0].substring(2,1) :  value.split(' ')[0].split(':')[0];
             const mints =  value.split(' ')[0].split(':')[1];
            return Moment().hour(hour).minute(mints);
           }
        }

    return(
        <>
        
            <div className="container cat-display" style={{height:"568px"}}>
                <div className="col-md-12 cat-main">
                    <form control="" className="customformGroup customformGroupcourse" onSubmit={onSubmits}>
                    <div className="row" style={{padding:'12px'}}> 
                    <div className="col-sm-12 col-md-4">
                             <label htmlFor="Board">Board</label>
                            <select name="Board" value={CourseData.Board} onChange={inputEvents} className="form-control  form-select">
                            <option value=''>Select Board</option>
                            {boardoptions.map((option) => (
                                 <option key={option._id} value={option._id}>{option.Board}</option>
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-12 col-md-4">
                             <label htmlFor="Category">Category</label>
                            <select name="Category" value={CourseData.Category} onChange={inputEvents} className="form-control  form-select">
                            <option value=''>Select Category</option>
                            {catoptions.map((option) => (
                                 <option key={option._id} value={option._id}>{option.Code} | {option.Category}</option>
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-12 col-md-4">
                            {/* {console.log('CourseData.Class= '+CourseData.Class)} */}
                             <label htmlFor="Class">Class</label>
                            <select name="Class" value={CourseData.Class} onChange={inputEvents} className="form-control  form-select">
                            <option value=''>Select Class</option>
                            {clsoptions.map((option) => (
                               // console.log(option.Class)
                                    // Object.values(option.Class).map((optionn,i) => (
                                    //    // console.log(optionn)
                                        <option key={option._id} value={option._id}>{option.Code} | {option.Class}</option>
                                    // ))
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-12 col-md-4">
                             <label htmlFor="Subject">Subject</label>
                            <select name="Subject" value={CourseData.Subject} onChange={inputEvents} className="form-control  form-select">
                            <option value=''>Select Subject</option>
                          
                            {suboptions.map((option) => (
                               // console.log(option)
                                <option key={option._id} value={option._id}>{option.Code} | {option.Subject}</option>
                                    // Object.values(option.Class).map((optionn,i) => (
                                        
                                    //    console.log(optionn)
                                    //   <option key={optionn._id} value={optionn._id}>{optionn.Subject}</option>
                                    // ))
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-12 col-md-4">
                             <label htmlFor="Teacher">Faculty </label>
                            <select name="Faculty" value={CourseData.Faculty } onChange={inputEvents} className="form-control  form-select">
                            <option value=''>Select Faculty </option>
                            {teacheroptions.map((option) => (
                               // console.log(option.Teacher)
                                 <option key={option._id} value={option._id}>{option.Name}</option>
                                  ))}
                            </select>
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <label htmlFor="Duration">Course Duration (Hours)</label>
                            <div className="input-group has-validation pt-0">
                                <span className="input-group-text" style={{color:'red'}} id="inputGroupPrepend"><i className="fa fa-clock "></i></span>
                            <input type="text" name="Duration"  onChange={inputEvents} value={CourseData.Duration} className="form-control" placeholder="Enter Duration Here"/>
                            </div>
                        </div>
                        {/* <div className="col-sm-12 col-md-6">
                             <label htmlFor="Type">Course Type </label>
                            <select name="Type" value={CourseData.Type } onChange={inputEvents} className="form-control">
                            <option  value=''>Select Course Type </option>
                            <option  value='Hourly'>Hourly</option>
                            <option  value='Monthly'>Monthly</option>
                            <option  value='FullCourse'>Full Course</option>
                            </select>
                        </div> */}
                        <div className="col-sm-12 col-md-4">
                            <label htmlFor="FeesPerHour">Fees Per Hour</label>
                            <div className="input-group has-validation pt-0">
                                <span className="input-group-text" style={{color:'red'}} id="inputGroupPrepend"><i className="fa fa-rupee-sign "></i></span>
                                <input type="text" name="FeesPerHour"  onChange={inputEvents} value={CourseData.FeesPerHour} className="form-control" placeholder="Enter Fees Per Hour Here"/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <label htmlFor="FeesPerMonth">Fees Per Mounth</label>
                            <div className="input-group has-validation pt-0">
                                <span className="input-group-text" style={{color:'red'}} id="inputGroupPrepend"><i className="fa fa-rupee-sign "></i></span>
                                <input type="text" name="FeesPerMonth"  onChange={inputEvents} value={CourseData.FeesPerMonth} className="form-control" placeholder="Enter Fees Per Month Here"/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <label htmlFor="FeesFullCourse">Fees Full Course</label>
                            <div className="input-group has-validation pt-0">
                                <span className="input-group-text" style={{color:'red'}} id="inputGroupPrepend"><i className="fa fa-rupee-sign "></i></span>
                                <input type="text" name="FeesFullCourse"  onChange={inputEvents} value={CourseData.FeesFullCourse} className="form-control" placeholder="Enter Fees Full Course Here"/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <label htmlFor="StartDate">Start Date</label>
                            <input type="date" name="StartDate"  onChange={inputEvents} value={Moment(CourseData.StartDate).format('YYYY-MM-DD')} className="form-control" placeholder="Enter Start Date Here"/>
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <label htmlFor="EndDate">End Date</label>
                            <input type="date" name="EndDate"  onChange={inputEvents} value={Moment(CourseData.EndDate).format('YYYY-MM-DD')} className="form-control" placeholder="Enter End Date Here"/>
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <label htmlFor="Description">Remarks</label>
                            <div className="input-group has-validation pt-0">
                                <span className="input-group-text" style={{color:'red'}} id="inputGroupPrepend"><i className="fa fa-comment-alt"></i></span>
                                <input type="text" name="Description"  onChange={inputEvents} value={CourseData.Description} className="form-control" placeholder="Enter Remarks Here"/>
                            </div>
                        </div>
                        
                        {(CourseId && flagg==='addstudent') ?   
                        //alert(CourseStudentData[0]._id)   && CourseStudentData[0]._id!=""     
                        <div className="col-sm-12 col-md-12">
                           {/* {console.log(CourseStudentData)} */}
                            <CourseStudent  addFormFields={addFormFields} removeFormFields={removeFormFields} coursestudentEvents={coursestudentEvents} CourseStudentData={CourseStudentData} />
                        </div>
                        :''
                        }

                       {(CourseId && flagg==='addschedular') ?            
                        <div className="col-sm-12 col-md-12">
                            {/* <CourseClass  addFormFields={addFormFields}  courseclassEvents={courseclassEvents} onChanges={onChanges} CourseClassData={CourseClassData} /> */}
                            <div className='row'>
                        <div className="container-tb">
                        <table id="myTable">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Day</th>
                                    <th>Class Start Time</th>
                                    <th>Class duration</th>
                                    <th>Extend duration</th>
                                    <th>Remarks   <a href='#' onClick={resduleclassEvents}>Clear</a></th>
                                </tr>
                            </thead>
                            {/* <tbody> */}
                                    {
                                    CourseClassData.map((item,i) => (
                                        <>
                                          
                                                <tr key={i.toString()}>
                                                    <td></td>
                                                    <td>
                                                        
                                                    <label>
                                                   
                                                    <input type="checkbox" name={item.Day}
                                                    checked={(item.checked)==false ? false : true}
                                                    onChange={(e) => courseclassEvents(e,i)}
                                                    />
                                                   
                                                    {item.Day} 
                                                    {/* {(item.checked)==true ? "true" : "false"} */}
                                                    </label>
                                                    {/* <input onChange={(e) => courseclassEvents(e,i)} type="checkbox" name={item.Day}  defaultChecked={item.checked  || null} />{item.Day} */}
                                                        {/* {
                                                        (item.checked===true || item.checked==="true" )?
                                                        <>
                                                        <input onChange={(e) => courseclassEvents(e,i)} type="checkbox" name={item.Day} value={item.checked  || null}  defaultChecked={item.checked  || null} />{item.Day}
                                                        </>
                                                        :
                                                        <>
                                                         <input onChange={(e) => courseclassEvents(e,i)} type="checkbox" name={item.Day} value={item.checked  || null} defaultChecked={item.checked  || null} />{item.Day}
                                                        </>
                                                          } */}
                                                    </td>
                                                    <td>
                                                    {/* <div style={{
                                                        margin: 'auto',
                                                        display: 'block',
                                                        width: 'fit-content'
                                                        }}>
                                                        <h3>How to create Time Picker in ReactJS?</h3> */}
                                                       {/* {alert(item.StartTime)} */}
                                                       
                                                        <input
                                                            // label="Choose Time"
                                                            defaultValue={item.StartTime.toString() || ""}//{item.StartTime || ""}
                                                            type="time"
                                                            InputLabelProps={{
                                                            shrink: true,
                                                            }}
                                                            // 5 minutes
                                                            // inputProps={{
                                                            // step: 300,
                                                            // }}
                                                            
                                                            onChange={(e) => onChanges(e,i)}
                                                        />
                                                        {/* {item.StartTime} */}
                                                        {/* </div> */}
                                                        {/* {item.StartTime} */}
                                                       {/* <TimePicker  showSecond={false} value={timeformat(item.StartTime)||""} className="xxx"  onChange={(e) => onChanges(e,i)}  format={format}  use12Hours  inputReadOnly/> */}
                                                       {/* {
                                                        (item.StartTime.length>0)?
                                                        <>
                                                        <TimePicker  showSecond={false} value={timeformat(item.StartTime)||""} className="xxx"  onChange={(e) => onChanges(e,i)}  format={format}  use12Hours  inputReadOnly />
                                                         </>
                                                        :
                                                        <>
                                                        <TimePicker  showSecond={false}  className="xxx"  onChange={(e) => onChanges(e,i)}  format={format}  use12Hours  inputReadOnly />
                                                         </>
                                                        } */}
                                                    </td>
                                                    <td>
                                                 {/* {alert(CourseClassData[i].Duration)} */}
                                                    {/* <select name="Duration" key={"val"+i.toString()}  value={item.Duration} onChange={(e) => courseclassEvents(e,i)} className="form-select">
                                                    <option value=''>Select Duration</option>
                                                    {durationoptions.map((option) => (
                                                        <option key={option.value} value={option.value}>{option.name}</option>
                                                    ))}
                                                    </select>
                                                */}
                                                        <select className="form-control form-select" name="Duration" onChange={(e) => courseclassEvents(e,i)}  value={item.Duration || null} >
                                                            <option key="1" value="">select Duration</option>
                                                            <option key="2" value="15">15 Min</option>
                                                            <option key="3" value="30">30 Min</option>
                                                            <option key="4" value="45">45 Min</option>
                                                            <option key="5" value="60">1 Hour</option>
                                                            <option key="6" value="75">1 Hour 15 Min</option>
                                                            <option key="7" value="90">1 Hour 30 Min</option>
                                                            <option key="8" value="105">1 Hour 45 Min</option>
                                                            <option key="9" value="120">2 Hour</option>
                                                            <option key="10" value="135">2 Hour 15 Min</option>
                                                            <option key="11" value="150">2 Hour 30 Min</option>
                                                            <option key="12" value="165">2 Hour 45 Min</option>
                                                        </select>
                                               
                                                        </td>
                                                    <td>
                                                  
                                                        <select className="form-control  form-select" name="Extendduration" onChange={(e) => courseclassEvents(e,i)} value={item.Extendduration || null} >
                                                        <option value="">select Extend Duration</option>
                                                        <option value="5">5 Min</option>
                                                        <option value="10">10 Min</option>
                                                        <option value="15">15 Min</option>
                                                        <option value="20">20 Min</option>
                                                        <option value="25">25 Min</option>
                                                        <option value="30">30 Min</option>
                                                        </select>
                                                       
                                                        </td>
                                                        
                                                    <td>
                                                  
                                                        <input className="form-control" type="text" name='Remarks'  onChange={(e) => courseclassEvents(e,i)} value={item.Remarks || null}/>
                                                      
                                                    </td>
                                                </tr>
                                                
                                            
                                        </>
                                    ))
                                    }
                            {/* <tbody/> */}
                        </table>
                    </div>
                </div>
                        </div>
                        :''
                        }

                        <div className="row"> 
                            <div className="col-sm-12 col-md-6 col-lg-6 pull-right">
                                <Link to="/courseList"  className="btn pull-right"> Go Back </Link>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 pull-right">
                                <input type="submit" value="Submit" className="btn pull-right"/>
                            </div>
                        </div>
                        </div>
                    </form>
                </div>
            </div>
           
        </>
    );
}
export default Course;