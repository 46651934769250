import React,{useEffect} from "react";
import {Link} from "react-router-dom";
import { AuthContext } from '../../context/context';
// import Sidebar from "../Components/sidebar"
import  "../admin/css_files/header_style.css";
//  import  profile_image from './profile_image.jpg';
import AdminSidebar from "./AdminSidebar";
 
const AdminHeader = () => {  
  const { signOut,getusername,getuserrole } = React.useContext(AuthContext);  
    let url = '';
    return (
        <>
          
             <header className="l-header">
                <div className="l-header__inner clearfix">
                <div className="c-header-icon js-hamburger">
                    <div className="hamburger-toggle"><span className="bar-top"></span><span className="bar-mid"></span><span className="bar-bot"></span></div>
                </div>
                
                <div className="c-search">
                </div>
                <div className="c-header-icon has-dropdown"><span className="c-badge c-badge--red c-badge--header-icon animated swing">13</span><i className="fa fa-bell"></i>
                    <div className="c-dropdown c-dropdown--notifications">
                    <div className="c-dropdown__header"></div>
                    <div className="c-dropdown__content"></div>
                    </div>
                </div>
                <div className="header-icons-group">
                    <div className="c-header-icon profile_container" style={{width: "170px"}} >
                      <span  title = {getuserrole()}>{getusername()} &nbsp;</span>
                      <div className="dropdown">
                      <img src="../../assets/images/profile_image.jpg" className="profile_image dropbtn" alt="profile img" />
                      <div className="dropdown-content">
                          <a href={url}> <span> Profile </span> </a>
                          <a href={url} onClick={signOut}> <span> Logout </span> </a>
                          {/* <Link onClick={signOut}><span> Logout </span></Link> */}
                          <a href={url}> <span> Account Setting </span> </a>
                      </div>
                      </div>
                    </div>
                </div>
                </div>
            </header>
            {/* <AdminSidebar /> */}
             
            
        </>
        
    );
  }
   



export default AdminHeader;