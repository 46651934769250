import React,{useState,useEffect} from "react";
import { AuthContext } from '../../context/context';
import {Link} from "react-router-dom";
import axios from 'axios';
import Moment from 'moment';
import '../../css/table.css';
const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  })

const SEnrolledCourseList= () => {
    const { getuserid } = React.useContext(AuthContext);
        const [data, setdata] = React.useState([]);
        const [isLoading, setIsLoading] = useState(true);
        
        useEffect( async () => {
            var userid = getuserid();
               getTeacherCourse(userid);
            }, [])
            
            const getTeacherCourse = (userid) => {
                api.get(`/StudentCourse/${userid}`).then(res =>
                    {
                       // alert(userid)
                        setIsLoading(false)
                        //console.log(res.data)
                        setdata(res.data)
                      
                });
            }
        return (
                <>

                       
                        <div className="tableFixHead">
                        <table className="table" style={{fontFamily: 'sans-serif',fontSize: '12px'}}>
                               <thead>					
                                     <th>Sr.</th>  
                                     <th>Course</th>  
                                     <th>Status</th> 
                                    
                                     <th>Class</th> 
                                     <th>Subject</th> 
                                     <th>Duration</th> 
                               </thead>
                             
                              <tbody>
                              {isLoading ? (
                                  <h1>Loading...</h1>
                              ) : (
                                                           
                                 data.map((item,index) => (
                                  
                                     <tr key={item._id}>
                                         <td></td>
                                         <td>{item.Code}</td>
                                         <td>{item.Status}</td>
                                        
                                         <td>{item.Class.Class}</td>
                                         <td>{item.Subject.Subject}</td>
                                         <td>{item.Duration}</td>
                                     </tr>
                                    ))
                          ) }
                              </tbody>
                              
                          </table>
                      </div>
              

{/* <div id="wrapper"> */}

{/* <div id="body-overlay"></div> */}

{/* <div id="wrap">
    <div className="container dashboard">
      
<div className="wrapper-dashboard">
        <div className="row">
        
<div className="col-md-12  padding_sidebar">
<div className="row" style={{RightMargin:'0px',LeftMargin:'0px;'}}>

<div className="card card-topline-purple">
<div className="card-head">
</div> */}



{/* <div className="table-responsive">
<div id="tableContainer2" className="container-tb" style={{display: 'block'}}>
 <table className="table" style={{fontFamily: 'sans-serif',fontSize: '12px'}}>
    <thead>
        <tr>
            <th className="fth-header"># </th>
            <th className="fth-header">Course</th>
            <th className="fth-header">Status</th>
            <th className="fth-header">Class</th>
            <th className="fth-header">Subject</th>
            <th className="fth-header">Course Duration</th>
        </tr>
    </thead>
   <tbody>
                   <tr>
            <td>1</td>
            <td><a className="label label-mini" href="https://www.theonlinegurukul.com/Student/course_enrolled_detail/1" style={{color:'#ffffff',cursor:'pointer'}}>ACC12THMTHJAN011800001</a>
            </td><td>
                                           
      <span style={{color:'red'}}>   Completed </span>
                    </td>
            <td>12TH-STANDARD</td>
            <td>MATHEMATICS</td>
            <td> 200 Mins</td>

        </tr>
                        <tr>
            <td>2</td>
            <td><a className="label label-mini" href="https://www.theonlinegurukul.com/Student/course_enrolled_detail/2" style={{color:'#ffffff',cursor:'pointer'}}>ACC12THCHEJAN011800002</a>
            </td><td>
                                            
      <span style={{color:'red'}}>   Completed </span>
                    </td>
            <td>12TH-STANDARD</td>
            <td>CHEMISTRY</td>
            <td> 200 Mins</td>

        </tr>
                        <tr>
            <td className="fth-footer">3</td>
            <td className="fth-footer"><a className="label label-mini" href="https://www.theonlinegurukul.com/Student/course_enrolled_detail/3" style={{color:'#ffffff',cursor:'pointer'}}>ACCMEETGSFEB011900003</a>
            </td><td className="fth-footer">
                                           
      <span style={{color:'red'}}>   Completed </span>
                    </td>
            <td className="fth-footer">MEETING</td>
            <td className="fth-footer">GENERAL-DISCUSSION</td>
            <td className="fth-footer"> 100 Mins</td>

        </tr>
                          </tbody>
</table>
</div>
</div> */}
{/* </div>
</div>
</div>
</div>
</div>
</div>
</div> */}
{/* </div> */}
       
                </>
      )
  }

export default SEnrolledCourseList;


