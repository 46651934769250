import React from "react";
import {Link} from "react-router-dom";
import ShowTable from './Table';

const RoleMasterList= () => {

        return (
                <>
                   <div style={{textAlign:"right"}}><Link to="/rolemaster"> Add Role </Link></div>
                      <ShowTable flag='role' Linkbtn={[{btnText:"",className:"fa fa-fw fa-edit text-green",flag:""}]} api='rolemaster' />
                     
                </>
      )
  }

export default RoleMasterList;
