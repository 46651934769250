import React from 'react';
import  { Formtitle, InputList } from './Forms';

const TeacherBankDetails = (props) => {
  return (
    <>
        <section className="qualification-container">
                <div className="stFormGroup">
                    <Formtitle title="BANK DETAILS" display="d-none" />
                    <div className="row pt-2"> 
                        <InputList classnames="col-md-4 col-lg-4 col-sm-6 pb-3" name="bank-name" lable="Bank Name"  placeholder="Enter Bank Name here " />
                        <InputList classnames="col-md-4 col-lg-4 col-sm-6 pb-3" name="cc-name" lable="Branch Name"  placeholder="Enter Branch  Name here "  />
                        <InputList classnames="col-md-4 col-lg-4 col-sm-6 pb-3" name="ac-Holder-name" lable="Account Holder Name"  placeholder="Enter Account Holder Name here " />
                        <InputList classnames="col-md-4 col-lg-4 col-sm-6 pb-3" name="account-no" lable="Account NO"  placeholder="Enter Account NO here " />
                        <InputList classnames="col-md-4 col-lg-4 col-sm-6 pb-3" name="ifsc-code" lable="IFSC Code"  placeholder="Enter IFSC Code here " />
                        <InputList classnames="col-md-4 col-lg-4 col-sm-6 pb-3" name="micr-code" lable="Micr Code"  placeholder="Enter Micr Code here " />
                    </div>
                </div>
           </section>
    </>
  )
}

export default TeacherBankDetails
