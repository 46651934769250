import React,{useState,useEffect} from 'react';
import Moment from 'moment';
import '../../css/admin/detailsTableStyles.css';
import axios from 'axios';
const api = axios.create({
    // baseURL: '/CategoryMaster'
    baseURL: process.env.REACT_APP_API_URL,
})
const DetailTable1 = (props) => {
 // const detailsArray = [{'Total':'532'},{'Email_Confirm':'6464'},{'Enrollled_In_Courses':'85858'},{'Active_student':'84747'}];
  const [detailsArray,setdetailsArray] = new useState([]);
  useEffect( async () => {
    debugger
    // const d = new Date();
    //  var   d2 = new Date ( d.getFullYear()+'-'+(d.getMonth()+1)+'-'+d.getDate()+' '+'14:00' );
    //  var d3 = new Date (d2.setMinutes(d2.getMinutes()-330))
    // // var d3 = new Date (d2.setHoseturs(d2.getHours()-5))
    // alert(d3)
  
      api.get(`/${props.api}`).then(res => setdetailsArray(res.data));
  }, [])
  return (
    <>
      {/* <div className="detail_tables_container">
          <div className="row"> */}
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="info-box">
                    <div  className={props.backcolor}>  {/*className="info-box-icon bg-tog"*/}
                        <i className={props.fontawesomeClassName} aria-hidden="true"></i>
                        <span className="info-box-number text-white">{props.heading}</span>
                    </div>
                    <div className="info-box-content">
                      {/* {console.log(detailsArray[0].Total)} */}
                        {detailsArray.map((data) => <> 
                         {console.log(data)} 
                            <span className="info-box-text">Total<small className="pull-right info-box-number">{data.Total}</small></span>
                            <span className="info-box-text">EMAIL CONFIRM<small className="pull-right info-box-number">{data.Email_Confirm}</small></span>
                            <span className="info-box-text">ENROLLLED IN COURSES<small className="pull-right info-box-number">{data.Enrollled_In_Courses}</small></span>
                            <span className="info-box-text">ACTIVE {props.heading}<small className="pull-right info-box-number">{data.Active_student}</small></span>
                            </>
                        )}
                    </div>
                </div>
              </div>
          {/* </div>
      </div> */}
    </>
  )
}

export default DetailTable1
