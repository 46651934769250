import React,{useState,useEffect} from "react";
import {Link,useNavigate} from "react-router-dom";
import axios from 'axios';
import "./css_files/category_style.css"

const api = axios.create({
    //baseURL: '/facultymaster'
    baseURL: process.env.REACT_APP_API_URL,
})

const FacultyMaster = () => {
   
    const [FacultyData,setFacultyData] = new useState({
        Class: "",
        Code: "",
        Description: "",
        Date: new Date(),
        Status: true,
        Category:"",
    });
    const [data, setdata] = React.useState([]);
    const [catoptions, setcatoptions] = React.useState([]);
    const [clsoptions, setclsoptions] = React.useState([]);
    const [suboptions, setsuboptions] = React.useState([]);
    const [teacheroptions, setteacheroptions] = React.useState([]);

    useEffect( async () => {
        api.get('/subjectmaster').then(res =>{
            // res.data.map((item) =>(
            //     setcatoptions(...catoptions,[item.Category])
            // )) 
            const newArrayy = Object.values(res.data.map(obj => ({...obj.Category})).reduce((acc,cur)=>Object.assign(acc,{[cur._id]:cur}),{}));
            setcatoptions(newArrayy)
           setdata(res.data)
       
    });
    api.get('/userinfo/Teacher').then(res =>setteacheroptions(res.data));
    }, [])

    const inputEvents = (event) =>{
        // const name = event.target.name;
        // const value = event.target.value;
        const {name,value} = event.target;
        if(name==='Category'){
            var newArray = data.filter(function(item)
                            {
                             // debugger
                            return  item.Category._id==value;
                           // return conditional_statement;
                            });
                            // newArray.map((item) =>(
                            //     setclsoptions(...clsoptions,[item.Class])
                            // )) 
                            const newArrayy = Object.values(newArray.map(obj => ({...obj.Class})).reduce((acc,cur)=>Object.assign(acc,{[cur._id]:cur}),{}));
                            setclsoptions(newArrayy)
          
        }

        if(name==='Class'){
                var newArray = data.filter(function(item)
                            {
                             // debugger
                            return  item.Class._id==value;
                           // return conditional_statement;
                            });
                            const newArrayy = Object.values(newArray.map(obj => ({...obj})).reduce((acc,cur)=>Object.assign(acc,{[cur._id]:cur}),{}));
                            setsuboptions(newArrayy)
                            //debugger
                           // setsuboptions(newArrayy)
         // console.log(newArrayy)
        }
       
        
        // console.log(name+'-'+value);
        setFacultyData({...FacultyData, [name]:value});
       
    }

  

    const navigate = useNavigate();
    const onSubmits = (event) =>{
        event.preventDefault();
  
         // console.log(FacultyData);
          api.post('/facultymaster',  FacultyData )
          .then(res =>{ 
              if(res.status=='201')
              navigate('/facultymasterList');
              else
              alert(res.status)
         })
         .catch((err) => {
            console.log(err.response);
            alert(err.response.status);
          });
        
        ;
    
         // navigate('/boardmasterList');

    }
  
  

    return(
        <>
            {/* {alert(props.name)} */}
            <div className="container-fluid cat-display" style={{height:"568px"}}>
                <div className="col-md-6 cat-main">
                    <form control="" className="form-group customformGroup" onSubmit={onSubmits}>
                        <div className="col-sm-12 col-md-12">
                             <label htmlFor="Category">Category</label>
                            <select name="Category" value={FacultyData.Category} onChange={inputEvents} className="form-control  form-select">
                            <option value=''>Select Category</option>
                            {catoptions.map((option) => (
                               // console.log(option.Category)
                                 <option key={option._id} value={option._id}>{option.Code} | {option.Category}</option>
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-12 col-md-12">
                             <label htmlFor="Class">Class</label>
                            <select name="Class" value={FacultyData.Class} onChange={inputEvents} className="form-control  form-select">
                            <option value=''>Select Class</option>
                            {clsoptions.map((option) => (
                               // console.log(option.Class)
                                    // Object.values(option.Class).map((optionn,i) => (
                                    //    // console.log(optionn)
                                        <option key={option._id} value={option._id}>{option.Code} | {option.Class}</option>
                                    // ))
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-12 col-md-12">
                             <label htmlFor="Subject">Subject</label>
                            <select name="Subject" value={FacultyData.Subject} onChange={inputEvents} className="form-control  form-select">
                            <option value=''>Select Subject</option>
                            {suboptions.map((option) => (
                               // console.log(option.Subject)
                                 <option key={option._id} value={option._id}>{option.Code} | {option.Subject}</option>
                                    // Object.values(option.Class).map((optionn,i) => (
                                        
                                    //    console.log(optionn)
                                    // //    <option key={optionn._id} value={optionn._id}>{optionn.Subject}</option>
                                    // ))
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-12 col-md-12">
                             <label htmlFor="Teacher">Teacher</label>
                            <select name="Teacher" value={FacultyData.Teacher} onChange={inputEvents} className="form-control  form-select">
                            <option value=''>Select Teacher</option>
                            {teacheroptions.map((option) => (
                               // console.log(option.Subject)
                                 <option key={option._id} value={option._id}>{option.Name}</option>
                                  ))}
                            </select>
                        </div>
                       
                        <div className="col-sm-12 col-md-12">
                            <label htmlFor="Description">Description</label>
                            <input type="text" name="Description"  onChange={inputEvents} value={FacultyData.Description} className="form-control" placeholder="Enter Description Here"/>
                        </div>
                        {/* row */}
                        <div className=""> 
                            <hr />
                            <div className="col-sm-12 col-md-6 col-lg-6 pull-right">
                                <Link to="/subjectmasterList"  className="btn pull-right"> Go Back </Link>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 pull-right">
                                <input type="submit" value="Submit" className="btn pull-right"/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
export default FacultyMaster;