import React from "react";
import {Link} from "react-router-dom";
import ShowTable from './Table';


const CityMasterList= () => {

        return (
                <>
                   <div style={{textAlign:"right"}}><Link to="/citymaster"> Add City </Link></div>
                      <ShowTable api='citymaster' flag='city' Linkbtn={[{btnText:"",className:"fa fa-fw fa-edit text-green",flag:""}]} />
                     
                </>
      )
  }

export default CityMasterList;
